import React, { useState, useEffect } from "react"
import { Title, StepButton, ButtonGroup } from "./AuthStyling"
import { useMoralis } from "react-moralis"
import { Loading } from "notiflix"
import { Modal, Col, Row, Typography } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const ButtonType = ({ selectedRole, src, title, description, clickHandler, role }) => {
    return (
        <Col xs={24} md={12}>
            <div onClick={() => clickHandler(role)} style={{ position: "relative", backgroundImage: "url(" + src + ")", backgroundPosition: "Top left", backgroundRepeat: "no-repeat", backgroundSize: "cover", minHeight: "160px", borderRadius: "20px", backgroundColor: "#fff", border: selectedRole == role ? "3px solid red" : "none", margin: "8px" }}>
                <div style={{ backgroundColor: "#000", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, opacity: "0.5", borderRadius: "17px", zIndex: 1 }} />
                <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, padding: "20px", zIndex: 10 }}>
                    <Typography.Paragraph style={{ color: src != "" ? "#fff" : "#000", fontSize: "24px", fontWeight: "600", lineHeight: "32px" }}>
                        {title}
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ color: src != "" ? "#EAEAEA" : "#000", fontSize: "18px", fontWeight: "400", lineHeight: "22px" }}>
                        {description}
                    </Typography.Paragraph>
                </div>
            </div>
        </Col>
    )
}


const AccountType = ({ menuData = [], title, setAccountType, setStep }) => {
    const { user } = useMoralis()
    const [role, setRole] = useState("")

    useEffect(() => {
        if (user) {
            setRole(user?.attributes.role)
        }
    }, [user])

    const onClickAcceptHandler = async () => {
        Loading.standard();
        try {
            user.set("role", role)
            await user.save()
            Loading.remove();
            setAccountType(role)
            setStep(0)
        } catch (error) {
            Loading.remove();
            Modal.error({
                icon: <ExclamationCircleOutlined />,
                content: error.data ? error.data.message : error.message,
            })
        }
    }


    const onClickButtonHandler = async (value) => {
        setRole(value)
    }

    return (
        <>
            <div>
                <Title>{title}</Title>
                <Row>
                    <ButtonType selectedRole={role} src={"/images/authentication/hs_adm.png"} title={"HighSchool Admin"} description={"Manage your school's sports teams with our gaming platform."} clickHandler={onClickButtonHandler} role={"hs_adm"} />
                    <ButtonType selectedRole={role} src={"/images/authentication/sp_adm.png"} title={"SportClub Admin"} description={"Create NFT of your sportclub athlete’s"} clickHandler={onClickButtonHandler} role={"sc_adm"} />
                    <ButtonType selectedRole={role} src={"/images/authentication/hs_ath.png"} title={"HighSchool Athlete"} description={""} clickHandler={onClickButtonHandler} role={"hs_ath"} />
                    <ButtonType selectedRole={role} src={"/images/authentication/sp_ath.png"} title={"HighSchool Athlete"} description={""} clickHandler={onClickButtonHandler} role={"sc_ath"} />
                    <ButtonType selectedRole={role} src={"/images/authentication/indi.png"} title={"Individual Athlete"} description={""} clickHandler={onClickButtonHandler} role={"in_ath"} />
                    <ButtonType selectedRole={role} src={""} title={"Gamer / Sponsor"} description={""} clickHandler={onClickButtonHandler} role={"sponsor"} />
                    <ButtonType selectedRole={role} src={""} title={"YourLifeGames Admin"} description={""} clickHandler={onClickButtonHandler} role={"ylg_adm"} />
                    <ButtonType selectedRole={role} src={"/images/authentication/adver.png"} title={"Advertiser"} description={""} clickHandler={onClickButtonHandler} role={"advert"} />
                </Row>
            </div>
            <ButtonGroup>
                <StepButton onClick={onClickAcceptHandler} disabled={role == ""}>Accept</StepButton>
            </ButtonGroup>
        </>
    )
}

export default AccountType
