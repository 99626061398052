import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react"
import NFTGeometricCard from "components/NFTGeometricCard"
import {
  InfoContainer,
  InnerHeaderContainer,
  InnerHeaderLabel,
  BackButton,
  Heading,
  NftCardContainer,
  TabContainer,
  TabFilterBar,
} from "./styles/InfoElements"
import "./st.css"
import { OfferBox } from "./components"
import BoosterSlider from "./components/BoosterSlider"
import Buttons from "./components/Buttons"
import { CssDiv } from "components/CssStyledComponent/CssStyledComponent"
import { Alert, Button, Input, Modal, Typography, Spin } from "antd"
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { DappContext } from "context"
import { MarketPlaceFilters } from "components/Sidebar/MarketPlaceFilters"
import { Loading } from "notiflix"
import { useMoralis } from "react-moralis"
import { SubscribeBtn, SubscribeBtnContainer2, SubscribeText1, SubscribeText2 } from "components/Header/HeaderStyling"
import { useSwapRate } from "hooks/useSwapRate"
import { nftDetail } from "utils/function"

/** description of marketplace */
const Marketplace = ({ moralis }) => {
  const {
    flag_offerAuction,
    setOpenSidebar,
    setSidebarContent,
    onCloseSidebar,
    setOpenModal,
    modalContent,
    setModalContent,
  } = useContext(DappContext)
  const { isAuthenticated, user } = useMoralis()
  const [onceLoad, setOnceLoad] = useState(false)
  const [allNfts, setAllNfts] = useState([])
  const [nft1155s, set1155Nfts] = useState([])
  const [bundles, setBundles] = useState([])
  const [title, setTitle] = useState("")
  const [showNFTs, setShowNFTs] = useState([])
  const rate = useSwapRate()

  const joinNewsLetter = () => {
    if (!user?.attributes.emailVerified) {
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: "You have to verify email!",
      })
    } else {
      Modal.success({
        icon: <ExclamationCircleOutlined />,
        content: "You can join to news letter!",
      })
    }
  }

  const loadNFTs = useCallback(async () => {
    if (!moralis) return;
    Loading.standard()
    let result = await moralis?.fn.Cloud.run("FetchMarketed")
    const listedNFT = await Promise.all(
      result?.map(async (item) => nftDetail(moralis?.fn, item, rate, "market", null)),
    )
    const marketNFTs = listedNFT.filter((nft) => !nft.isERC721 && nft.type === "marketplace")
    set1155Nfts(marketNFTs);
    setAllNfts(listedNFT)
    Loading.remove()
  }, [moralis])

  //
  const loadBundles = useCallback(async () => {
    if (moralis) {
      moralis?.fn.Cloud.run("getBundles", { isFullBundle: true }).then(
        async (result) => {
          if (result && result.length > 0) {
            const value = await Promise.all(
              result.map(async (item) => {
                const attr = item.attributes
                const erc721Arr = await Promise.all(
                  attr.ERC721.map(async (id) => {
                    const meta = await moralis?.fn.Cloud.run("Get721TokenURI", { tokenId: id })
                    return meta.data.image
                  }),
                )
                const erc1155Arr = await Promise.all(
                  attr.ERC1155.map(async (id) => {
                    const meta = await moralis?.fn.Cloud.run("Get1155TokenURI", { tokenId: id })
                    return meta.data.image
                  }),
                )
                return {
                  discount: attr.discount,
                  endAt: attr.endAt,
                  name: attr.name,
                  fullPrice: attr.fullPrice,
                  images: erc1155Arr.concat(erc721Arr),
                  tokenData: JSON.stringify({
                    erc721: attr.ERC721,
                    erc1155: attr.ERC1155,
                  }),
                }
              }),
            )
            setBundles(value)
          }
        },
      )
    }
  }, [moralis])

  useEffect(() => {
    if (moralis && !onceLoad) {
      loadNFTs()
      loadBundles()
      setOnceLoad(true)
    }
  }, [
    moralis,
    onceLoad,
    isAuthenticated,
    loadNFTs,
    loadBundles,
  ])


  useEffect(() => {
    if (flag_offerAuction == "all") {
      setTitle("All")
      setShowNFTs(allNfts)
    } else if (flag_offerAuction == "market") {
      setTitle("Marketplace")
      const res = allNfts.filter((nft) => nft.type === "marketplace")
      setShowNFTs(res)
    } else if (flag_offerAuction == "offer") {
      setTitle("OFFICIAL OFFER")
      const res = allNfts.filter((nft) => nft.type === "offer")
      setShowNFTs(res)
    } else if (flag_offerAuction == "auction") {
      setTitle("AUCTION")
      const res = allNfts.filter((nft) => nft.type === "auction")
      setShowNFTs(res)
    }
  }, [flag_offerAuction, allNfts])

  const onClickFilters = (e) => {
    setSidebarContent(<MarketPlaceFilters closeSidebar={onCloseSidebar} />)
    setOpenSidebar(true)
  }

  return (
    <>
      <InfoContainer>
        <InnerHeaderContainer>
          <BackButton>Back</BackButton>
          <SubscribeBtnContainer2>
            <SubscribeBtn onClick={joinNewsLetter}>
              <SubscribeText1>Subscribe for newsletters</SubscribeText1>
              <SubscribeText2>Subscribe</SubscribeText2>
            </SubscribeBtn>
          </SubscribeBtnContainer2>
        </InnerHeaderContainer>
        <InnerHeaderLabel>MARKETPLACE</InnerHeaderLabel>
        <BoosterSlider nfts={nft1155s} />
        {bundles.length > 0 && <OfferBox result={bundles} />}
        <CssDiv pb={"50px"}>
          <Buttons />
          <TabContainer>
            <Typography.Title className="typo-title">
              <span className="typo-title">{title}</span>
            </Typography.Title>
            <TabFilterBar>
              <Input
                suffix={<SearchOutlined />}
                bordered={false}
                placeholder="Search"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  height: "37px",
                  marginRight: "20px",
                }}
              />
              <Button
                type="primary"
                ghost
                style={{ width: "90px", height: "37px", marginRight: "20px" }}
                onClick={onClickFilters}
              >
                FILTERS
              </Button>
            </TabFilterBar>
          </TabContainer>
          <NftCardContainer>
            {showNFTs.map((card, index) => (
              <NFTGeometricCard key={index} nft={card} />
            ))}
          </NftCardContainer>
        </CssDiv>
      </InfoContainer>
    </>
  )
}

export default Marketplace
