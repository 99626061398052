/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react"
import {
  BackBtn,
  MainContainer,
  MainInnerContainer,
  MakeAdminBtn,
  QuantityText,
  RightContainer,
  RightSideContainer,
  AdminToolbarContainer,
  SearchInput,
  Title,
  TopContainer,
} from "../styles/ManageAdminsStyling"
import { Select } from "antd"
import { useHistory } from "react-router"
import { AdminCard } from "./AdminCard"
import { AdminTable } from "./AdminTable"
import { useMedia } from "hooks/useMedia"
import { ManageAdminMobile } from "../MobileScreens/ManageAdminMobile"
import { Loading } from "notiflix"

export const ManageAdmins = ({ moralis }) => {
  const [onceLoad, setOnceLoad] = useState(false)
  const [adminUsers, setAdminUsers] = useState([])
  const [isSort, setSort] = useState(true)
  const history = useHistory()
  const isDesktop = useMedia("(min-width: 768px)")
  const isMobile = useMedia("(max-width: 768px)")

  const fetchAdmin = useCallback(async () => {
    Loading.standard()
    const admins = await moralis?.fn.Cloud.run("getManageAdmins")
    const filteredAdmin = admins.filter(
      (admin) => !admin.attributes.isSuperAdmin,
    )
    filteredAdmin.sort((a, b) => {
      return isSort
        ? b.attributes.createdAt - a.attributes.createdAt
        : a.attributes.createdAt - b.attributes.createdAt
    })
    Loading.remove()
    setAdminUsers(filteredAdmin)
  }, [moralis, isSort])

  useEffect(() => {
    if (moralis && !onceLoad) {
      fetchAdmin()
      setOnceLoad(true)
    }
  }, [moralis, onceLoad])

  useEffect(() => {
    if (moralis) fetchAdmin()
  }, [isSort])

  const handleBack = () => {
    history.push("/admin/super")
  }

  const handleChange = () => {
    setSort(!isSort)
  }

  return (
    <MainContainer>
      {isDesktop && (
        <>
          <BackBtn onClick={handleBack}>back</BackBtn>
          <TopContainer>
            <Title>Manage Admins</Title>
          </TopContainer>
          <AdminToolbarContainer>
            <QuantityText>{adminUsers.length} admins</QuantityText>
            <RightSideContainer>
              <Select defaultValue="FIRST NEW ONES" onChange={handleChange}>
                <Select.Option value="FIRST NEW ONES">
                  FIRST NEW ONES
                </Select.Option>
                <Select.Option value="FIRST OLD ONES">
                  FIRST OLD ONES
                </Select.Option>
              </Select>
            </RightSideContainer>
          </AdminToolbarContainer>
          {adminUsers.length > 0 && <AdminTable adminUsers={adminUsers} />}
          {/* <MainInnerContainer>
            {adminUsers.map((user, index) => (
              <AdminCard key={index} user={user.attributes} club={user.clubData} />
            ))}
          </MainInnerContainer> */}
        </>
      )}
      {isMobile && (
        <>
          <ManageAdminMobile adminUsers={adminUsers} handleChange={handleChange} />
        </>
      )}
    </MainContainer>
  )
}
