import React, { useContext, useEffect, useState } from "react"
import { FunctionBtn } from "./FunctionBtn"
import { FunctionsContainer } from "../styles/FunctionsStyling"
import { Contracts } from "components/Sidebar/Contracts"
import { DappContext } from "context"
import { SetStakeAmount } from "components/Sidebar/SetStakeAmount"
import { useHistory } from "react-router"
import { useMoralis } from "react-moralis"
import { superAdminList, scAdminList, indAdminList, ylgAdminList } from "utils/dummyData"
import { EditSchoolClub } from "screens/SchoolClubs/components/EditSchoolClub"

export const FunctionsList = ({ isSuperPage, address }) => {
  const { Moralis, user, isAuthenticated } = useMoralis()
  const [list, setList] = useState([])
  useEffect(() => {
    if (isAuthenticated && user?.attributes.isAdmin) {
      setList(
        user?.attributes.isSuperAdmin && isSuperPage
          ? superAdminList
          : user?.attributes.role == "in_ath" ? indAdminList
            : user?.attributes.role == "ylg_adm" ? ylgAdminList : scAdminList,
      )
    }
  }, [
    isAuthenticated,
    user?.attributes.isAdmin,
    user?.attributes.isSuperAdmin,
    isSuperPage,
  ])
  const { setOpenSidebar, setSidebarContent, onCloseSidebar } =
    useContext(DappContext)
  const history = useHistory()

  const handleClick = async (e) => {
    if (e.target.innerText === "MANAGE CONTRACTS") {
      setSidebarContent(<Contracts closeSidebar={onCloseSidebar} />)
      setOpenSidebar(true)
    }
    if (e.target.innerText === "SET STAKE VALUE") {
      setSidebarContent(
        <SetStakeAmount address={address} closeSidebar={onCloseSidebar} />,
      )
      setOpenSidebar(true)
    }
    if (e.target.innerText === "MANAGE SCHOOL & SPORT CLUBS") {
      history.push("/admin/super/manageSchoolClubs")
    }
    if (e.target.innerText === "MANAGE ADMINS") {
      history.push("/admin/super/manageAdmins")
    }
    if (e.target.innerText === "MANAGE ATHLETES") {
      history.push("/admin/manageAthletes")
    }
    if (e.target.innerText === "EDIT SCHOOL & SPORT CLUB") {
      let club = await Moralis.Cloud.run("getClub", { id: user?.attributes.club, })
      setSidebarContent(
        <EditSchoolClub club={club} closeSidebar={onCloseSidebar} />,
      )
      setOpenSidebar(true)
    }
    if (e.target.innerText === "AIRDROP") {
      history.push("/admin/airdrop")
    }
    if (e.target.innerText === "ALL NFT'S") {
      history.push("/admin/allnfts")
    }
  }

  return (
    <FunctionsContainer style={{ height: "auto" }}>
      {list.map((func) => (
        <FunctionBtn
          key={func.id}
          text={func.name}
          icon={func.icon}
          onClick={(e) => handleClick(e)}
        />
      ))}
    </FunctionsContainer>
  )
}
