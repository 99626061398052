import React, { useEffect, useState, useRef, useCallback } from "react"
import { useMoralis } from "react-moralis"
import {
  BalanceInnerContainer,
  BalanceValue,
  TokenImage,
} from "screens/Superadmin/styles/BalanceStyling"
import {
  BalanceContainer,
  CloseButton,
  CurrentAmountText,
  MainContainer,
  MinAmountInput,
  MinAmountInputLabel,
  SetSumBtn,
  SidebarContainer,
  SidebarTitle,
  TitleContainer,
} from "./styles/SidebarStyling"
import whiteLabel from "../../images/yourlife_back_white.png"
import { setSufficientAmount, getSufficientAmounts } from "utils/helpers/proxy"
import { Modal } from "antd"
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons"
import { Loading } from "notiflix"

export const SetStakeAmount = ({ closeSidebar }) => {
  const { Moralis } = useMoralis()
  const [suffAmount, setSuffAmount] = useState([0, 0, 0, 0, 0, 0, 0, 0])

  const fetchAmount = useCallback(async () => {
    Loading.standard()
    try {
      const result = await Moralis.executeFunction(getSufficientAmounts())
      if (result.length > 0) {
        let temp = []
        for (let i = 0; i < result.length; i++) {
          const element = result[i];
          const value = Moralis.Units.FromWei(element)
          temp.push(value)
        }
        setSuffAmount(temp)
      }
      Loading.remove()
    } catch (error) {
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }, [Moralis])

  const setSufficientValue = async () => {
    Loading.standard()
    if (suffAmount) {
      let temp = []
      try {
        for (let i = 0; i < suffAmount.length; i++) {
          const element = suffAmount[i];
          const value = Moralis.Units.Token(element, "18")
          temp.push(value)
        }
        const transaction = await Moralis.executeFunction(
          setSufficientAmount(temp),
        )
        await transaction.wait()
        fetchAmount()
      } catch (error) {
        Modal.error({
          icon: <ExclamationCircleOutlined />,
          content: error.data ? error.data.message : error.message,
        })
      }
      Loading.remove()
    } else {
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: "Please input the stake amount",
      })
    }
  }

  const updateSuffAmount = (index, newValue) => {
    setSuffAmount(prevNumbers => {
      const updatedNumbers = [...prevNumbers];
      updatedNumbers[index] = newValue;
      return updatedNumbers;
    });
  };

  useEffect(() => {
    fetchAmount()
  }, [Moralis, fetchAmount])

  return (
    <SidebarContainer>
      <MainContainer>
        <TitleContainer>
          <SidebarTitle>set stake value</SidebarTitle>
        </TitleContainer>
        <BalanceContainer>
          <BalanceInnerContainer>
            <TokenImage src={whiteLabel} />
            <BalanceValue>Stake amount</BalanceValue>
          </BalanceInnerContainer>
          {/* <CurrentAmountText>current amount</CurrentAmountText> */}
        </BalanceContainer>
        <MinAmountInputLabel>Enter the minimum amount for HighSchool Admin</MinAmountInputLabel>
        <MinAmountInput value={suffAmount[0]} onChange={(e) => updateSuffAmount(0, e.target.value)} />
        <MinAmountInputLabel>Enter the minimum amount for SportClub Admin</MinAmountInputLabel>
        <MinAmountInput value={suffAmount[1]} onChange={(e) => updateSuffAmount(1, e.target.value)} />
        <MinAmountInputLabel>Enter the minimum amount for Individual Athlete</MinAmountInputLabel>
        <MinAmountInput value={suffAmount[4]} onChange={(e) => updateSuffAmount(4, e.target.value)} />
        <MinAmountInputLabel>Enter the minimum amount for Advertiser</MinAmountInputLabel>
        <MinAmountInput value={suffAmount[5]} onChange={(e) => updateSuffAmount(5, e.target.value)} />
        <MinAmountInputLabel>Enter the minimum amount for YLG Admin</MinAmountInputLabel>
        <MinAmountInput value={suffAmount[7]} onChange={(e) => updateSuffAmount(7, e.target.value)} />
        <SetSumBtn onClick={setSufficientValue}>SET SUM</SetSumBtn>
      </MainContainer>
      <CloseButton onClick={closeSidebar}><CloseOutlined /></CloseButton>
    </SidebarContainer>
  )
}
