import madisen from "../images/marketplace/offerCards/players/Madisen_Manning_common.webp"
import {
  ContactsOutlined,
  ControlOutlined,
  BlockOutlined,
  GiftOutlined,
  SnippetsOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons"

// adds
import image_54 from "../images/marketplace/offerCards/adds/image_54.webp"
import image_61 from "../images/marketplace/offerCards/adds/image_61.webp"

//packs
import londonKnights from "../images/marketplace/offerCards/packs/london_knights.webp"
import elkhorn from "../images/marketplace/offerCards/packs/elkhorn.webp"

// Christian Andrade 1st set
import andradeLegendary from "../images/marketplace/offerCards/players/Christian_Andrade_legendary.webp"

// Christian Andrade 2nd set
import andradeRare2 from "../images/marketplace/offerCards/players/Christian_Andrade2_rare.webp"

// Denise Lango 1st set

// Denise Lango 2nd set
import deniseLegendary2 from "../images/marketplace/offerCards/players/Denise_Lango2_legendary.webp"
import madisenRare3 from "../images/marketplace/offerCards/players/Madisen_Manning3_RARE.webp"
import madisenUnique4 from "../images/marketplace/offerCards/players/Madisen_Manning2_UNIQUE.webp"

// Trainer 1st set
import trainer from "../images/marketplace/offerCards/trainer/trainer.webp"
import trainer2 from "../images/marketplace/offerCards/trainer/trainer-1.webp"

export const ClubTypes = [
  { id: 1, value: "school", label: "School" },
  { id: 2, value: "club", label: "Sport Club" },
]

export const sports = [
  { id: 1, value: "Soccer", },
  { id: 2, value: "Basketball", },
  { id: 3, value: "Football", },
  { id: 4, value: "Baseball", },
  { id: 5, value: "Softball", },
  { id: 6, value: "Women's Soccer", },
  { id: 7, value: "Women's Basketball", },
  { id: 8, value: "Women's Softball", }
]

export const creatSports = [
  "Soccer",
  "Basketball",
  "Football",
  "Baseball",
  "Softball",
  "Women's Soccer",
  "Women's Basketball",
  "Women's Softball",
]

export const maleSports = [
  { id: 1, value: "Soccer", },
  { id: 2, value: "Basketball", },
  { id: 3, value: "Football", },
  { id: 4, value: "Baseball", },
  { id: 5, value: "Softball", },
]
export const femaleSports = [
  { id: 1, value: "Women's Soccer", },
  { id: 2, value: "Women's Basketball", },
  { id: 3, value: "Women's Softball", }
]

export const NFTFunctionTypes = [
  { id: 1, value: "Normal", },
  { id: 2, value: "Lazy Mint", },
]

export const NFTTypes = [
  { id: 1, value: "Booster" },
  { id: 2, value: "Player" },
  // { id: 3, value: "Coach" },
]

export const BoosterTypes = [
  { id: 1, value: "Player" },
  { id: 2, value: "Contest Team Properties" },
  { id: 3, value: "Game Counter" },
  { id: 4, value: "Sponsor Counter" },
  { id: 5, value: "Advertiser Space" },
]

export const ImpressionPage = [
  { id: 1, value: 'Landing Page', router: '/' },
  { id: 2, value: 'Marketlist Page', router: '/nftMarket' },
  { id: 3, value: 'Tournament Page', router: '/tournaments' },
  { id: 4, value: 'Collection Page', router: '/collection' },
]

export const ImpressionPos = [
  { id: 1, value: 'Body Top' },
  { id: 2, value: 'Body Bottom' },
  { id: 3, value: 'Body Left' },
  { id: 4, value: 'Body Right' },
]

export const userRoles = [
  { id: 1, value: "hs_adm", title: "HighSchool Admin", desc: "Description HighSchool Admin" },
  { id: 2, value: "sc_adm", title: "SportClub Admin", desc: "Description SportClub Admin" },
  { id: 3, value: "hs_ath", title: "HighSchool Athlete", desc: "Description HighSchool Athlete" },
  { id: 4, value: "sc_ath", title: "SportClub Athlete", desc: "Description SportClub Athlete" },
  { id: 5, value: "in_ath", title: "Individual Athlete", desc: "Description Individual Athlete" },
  { id: 6, value: "advert", title: "Advertiser", desc: "Description Advertiser" },
  { id: 7, value: "sponsor", title: "Gamer/Sponsor", desc: "Description Gamer/Sponsor" },
  { id: 8, value: "ylg_adm", title: "YLG Admin", desc: "Description YLG Admin" },
]

export const creatNFTTabs = [
  { id: 1, value: "Booster", roles: ["ylg_adm"], },
  { id: 2, value: "Player", roles: ["hs_adm", "sc_adm", "in_ath", "ylg_adm"], },
  { id: 3, value: "Sponsorship", roles: ["hs_adm", "sc_adm", "in_ath", "ylg_adm"], },
];

export const gamePeriods = [
  { id: 1, value: "Daily", },
  { id: 2, value: "Weekly", },
  { id: 2, value: "Monthly", },
]

export const gameTypes = [
  { id: 1, value: "Contest", },
  { id: 2, value: "Tournament", },
]

export const dummyData = {
  userImage: madisen,
  userName: "Madisen Manning",
  tokenValue: "0.055",
  tokenValueUSD: "$6 564,23",
  role: "Super Admin",
}

export const nftCards = [
  {
    id: 1,
    imgSrc: andradeRare2,
    title: "Christian Andrade",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Soccer",
    brickColor: "#0D51FF",
    speed: "2",
    dexterity: "56",
    stamina: "22",
    dribbling: "2",
    finishing: "56",
  },
  {
    id: 2,
    imgSrc: madisenUnique4,
    title: "Madisen Manning",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Soccer",
    brickColor: "#B20CFF",
    speed: "2",
    dexterity: "56",
    stamina: "22",
    dribbling: "2",
    finishing: "56",
  },
  {
    id: 3,
    imgSrc: deniseLegendary2,
    title: "Denise Lango",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Soccer",
    brickColor: "#F7A300",
    speed: "2",
    dexterity: "56",
    stamina: "22",
    dribbling: "2",
    finishing: "56",
  },
  {
    id: 4,
    imgSrc: londonKnights,
    title: "Madisen Manning",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Booster",
    brickColor: "#3EC1C7",
  },
  {
    id: 5,
    imgSrc: trainer,
    title: "Dmitry trainer",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Trainer",
    brickColor: "#242424",
    speed: "2",
    dexterity: "56",
    stamina: "22",
    dribbling: "2",
    finishing: "56",
  },
  {
    id: 6,
    imgSrc: image_54,
    title: "+5 Speed",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Addiotionally",
    brickColor: "#49D405",
  },
  {
    id: 7,
    imgSrc: madisenRare3,
    title: "Madisen Manning",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Soccer",
    brickColor: "#0D51FF",
    speed: "2",
    dexterity: "56",
    stamina: "22",
    dribbling: "2",
    finishing: "56",
  },
  {
    id: 8,
    imgSrc: madisenUnique4,
    title: "Madisen Manning",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Soccer",
    brickColor: "#B20CFF",
    speed: "75",
    dexterity: "81",
    stamina: "64",
    dribbling: "75",
    finishing: "75",
  },
  {
    id: 9,
    imgSrc: andradeLegendary,
    title: "Christian Andrade",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Soccer",
    brickColor: "#F7A300",
    speed: "85",
    dexterity: "63",
    stamina: "55",
    dribbling: "75",
    finishing: "75",
  },
  {
    id: 10,
    imgSrc: elkhorn,
    title: "Elkhorn North",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Booster",
    brickColor: "#3EC1C7",
  },
  {
    id: 11,
    imgSrc: trainer2,
    title: "Dmitry trainer",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Trainer",
    brickColor: "#242424",
  },
  {
    id: 12,
    imgSrc: image_61,
    title: "White t-shirt Nike",
    price: "2341 YLT",
    priceUSD: "2 000",
    sport: "Trainer",
    brickColor: "#49D405",
  },
]

export const filtersOptions = [
  // {
  //   id: 1,
  //   name: "Price",
  //   options: [
  //     {
  //       id: 1,
  //       name: "Lowest",
  //     },
  //     {
  //       id: 2,
  //       name: "Highest",
  //     },
  //   ],
  // },
  {
    id: 2,
    name: "Rarity",
    options: [
      {
        id: 1,
        name: "Common",
      },
      {
        id: 2,
        name: "Unique",
      },
      {
        id: 3,
        name: "Rare",
      },
      // {
      //   id: 4,
      //   name: "Epic",
      // },
      {
        id: 5,
        name: "Legendary",
      },
    ],
  },
  {
    id: 3,
    name: "Category",
    options: [
      {
        id: 1,
        name: "Shirts",
      },
      {
        id: 2,
        name: "Pants",
      },
      {
        id: 3,
        name: "Shoes",
      },
      {
        id: 4,
        name: "Accessories",
      },
    ],
  },
  {
    id: 4,
    name: "Kind of Sport",
    options: [
      {
        id: 1,
        name: "Soccer",
      },
      {
        id: 2,
        name: "Basketball",
      },
      {
        id: 3,
        name: "Baseball",
      },
      {
        id: 4,
        name: "Hockey",
      },
      {
        id: 5,
        name: "American Football",
      },
    ],
  },
  {
    id: 5,
    name: "Properties",
    options: [
      {
        id: 0,
        name: "All",
      },
      {
        id: 1,
        name: "Speed",
      },
      {
        id: 2,
        name: "Energy",
      },
      {
        id: 3,
        name: "Power",
      },
      {
        id: 4,
        name: "Jumping",
      },
    ],
  },
  {
    id: 6,
    name: "Collections",
    options: [
      {
        id: 1,
        name: "Collection 1",
      },
      {
        id: 2,
        name: "Collection 2",
      },
      {
        id: 3,
        name: "Collection 3",
      },
    ],
  },
  {
    id: 7,
    name: "Chains",
    options: [
      {
        id: 1,
        name: "Binance smart chain",
      },
      {
        id: 2,
        name: "Polygon",
      },
      // {
      //   id: 3,
      //   name: "Chain 3",
      // },
    ],
  },
]

export const superAdminList = [
  {
    id: 1,
    name: "manage contracts",
    icon: <ContactsOutlined />,
  },
  {
    id: 2,
    name: "Manage School & Sport Clubs",
    icon: <BlockOutlined />,
  },
  {
    id: 3,
    name: "manage admins",
    icon: <UserSwitchOutlined />,
  },
  {
    id: 4,
    name: "set stake value",
    icon: <ControlOutlined />,
  },
  {
    id: 5,
    name: "all nft's",
    icon: <SnippetsOutlined />,
  },
  {
    id: 6,
    name: "airdrop",
    icon: <GiftOutlined />,
  },
]

export const ylgAdminList = [
  {
    id: 1,
    name: "manage contracts",
    icon: <ContactsOutlined />,
  },
  {
    id: 2,
    name: "Manage School & Sport Clubs",
    icon: <BlockOutlined />,
  },
  {
    id: 3,
    name: "manage admins",
    icon: <UserSwitchOutlined />,
  },
  {
    id: 4,
    name: "all nft's",
    icon: <SnippetsOutlined />,
  },
  {
    id: 5,
    name: "airdrop",
    icon: <GiftOutlined />,
  },
]

export const scAdminList = [
  {
    id: 1,
    name: "manage contracts",
    icon: <ContactsOutlined />,
  },
  {
    id: 2,
    name: "Edit School & Sport Club",
    icon: <BlockOutlined />,
  },
  {
    id: 3,
    name: "manage athletes",
    icon: <UserSwitchOutlined />,
  },
  {
    id: 4,
    name: "all nft's",
    icon: <SnippetsOutlined />,
  },
  // {
  //   id: 5,
  //   name: "airdrop",
  //   icon: <GiftOutlined />,
  // },
]

export const indAdminList = [
  {
    id: 1,
    name: "manage contracts",
    icon: <ContactsOutlined />,
  },
  {
    id: 2,
    name: "all nft's",
    icon: <SnippetsOutlined />,
  },
  // {
  //   id: 3,
  //   name: "airdrop",
  //   icon: <GiftOutlined />,
  // },
]