import React, { useState, useEffect } from "react"
import { Switch } from "antd"
import {
  CloseButton,
  ContractIcon,
  ContractInnerContainer,
  ContractListContainer,
  ContractListInnerContainer,
  ContractTitle,
  SumBtnContainer,
  SetSumBtn,
  SidebarContainer,
  SidebarTitle,
  TitleContainer,
  UserInfoContainer,
  UserName,
  UserProfileImage,
  MinAmountInput,
  MinAmountInputLabel,
} from "./styles/SidebarStyling"
import {
  CloseOutlined
} from "@ant-design/icons"
import profileImage from "../../images/avatar.png"
import {
  setComission,
  getComissionByAdmin,
  changePermissionsFunc,
  mintableAccounts,
  pausableAccounts,
  burnableAccounts,
  transferableAccounts,
} from "utils/helpers/proxy"
import { useMoralis } from "react-moralis"
import DeliveredProcedureOutlined from '../../images/DeliveredProcedureOutlined.svg'
import ExportOutlined from '../../images/ExportOutlined.svg'
import IssuesCloseOutlined from '../../images/IssuesCloseOutlined.svg'
import StopOutlined from '../../images/StopOutlined.svg'
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Loading } from "notiflix"

const contracts = [
  { id: 1, name: "access mint", icon: <img src={DeliveredProcedureOutlined} alt="DeliveredProcedureOutlined" /> },
  { id: 2, name: "access transfer", icon: <img src={ExportOutlined} alt="ExportOutlined" /> },
  { id: 3, name: "access pause", icon: <img src={IssuesCloseOutlined} alt="IssuesCloseOutlined" /> },
  { id: 4, name: "access burn", icon: <img src={StopOutlined} alt="StopOutlined" /> },
]

export const ContractsSwitch = ({ user, club, closeSidebar }) => {
  const { Moralis } = useMoralis()
  const [permission, setPermission] = useState([])
  const [oldPermission, setOldPermission] = useState([])
  const [superComission, setSuperComission] = useState(20)
  const [adminComission, setAdminComission] = useState(0)
  const [athleteComission, setAthleteComission] = useState(80)
  const [cando, setCando] = useState(false)
  const [cm, setCm] = useState([0, 0, 0])

  useEffect(() => {
    const fetchFn = async () => {
      let arr = []
      if (user) {
        Loading.standard()
        try {
          arr.push(await Moralis.executeFunction(mintableAccounts(user?.ethAddress)))
          arr.push(await Moralis.executeFunction(transferableAccounts(user?.ethAddress)))
          arr.push(await Moralis.executeFunction(pausableAccounts(user?.ethAddress)))
          arr.push(await Moralis.executeFunction(burnableAccounts(user?.ethAddress)))
          const comission = await Moralis.executeFunction(getComissionByAdmin(user?.ethAddress))
          if (comission.length > 0) {
            setSuperComission(comission[0] / 100)
            setAdminComission(comission[1] / 100)
            setAthleteComission(comission[2] / 100)
          } else {
            setSuperComission(20)
            setAdminComission(0)
            setAthleteComission(80)
          }
          Loading.remove()
          setPermission(arr)
          setOldPermission(arr)
        } catch (error) {
          Loading.remove()
          Modal.error({
            icon: <ExclamationCircleOutlined />,
            content: error.data ? error.data.message : error.message,
          })
        }
      }
    }
    fetchFn()
  }, [user, Moralis])

  const onChange = (checked, e) => {
    const index = Number(e.target.closest("button").getAttribute("index"))
    setPermission((select) =>
      select.map((value, i) => (i === index ? checked : value)),
    )
  }

  const submit = async () => {
    try {
      const permissionList = {
        accessMint: permission[0],
        accessTransfer: permission[1],
        accessPause: permission[2],
        accessBurn: permission[3],
      }
      Object.keys(permissionList).forEach((key, index) => {
        if (permissionList[key] === oldPermission[index]) {
          delete permissionList[key]
        }
      })
      Loading.standard()
      for (let funcName in permissionList) {
        const transaction = await Moralis.executeFunction(
          changePermissionsFunc(
            user?.ethAddress,
            funcName,
            permissionList[funcName],
          ),
        )
        await transaction.wait()
      }
      const transaction = await Moralis.executeFunction(
        setComission(user?.ethAddress, superComission * 100, user?.role == "in_ath" ? 0 : adminComission * 100, athleteComission * 100),
      )
      await transaction.wait()
      Loading.remove()
    } catch (e) {
      Loading.remove()
    }

  }

  useEffect(() => {
    if (cm[0] != superComission) {
      if (user?.role != "in_ath") {
        const r = (100 - superComission * 1) / 2;
        setAdminComission(r);
      } else {
        const r = 100 - superComission * 1
        setAthleteComission(r)
      }
    }
    if (cm[1] != adminComission) {
      const r = 100 - superComission * 1 - adminComission * 1
      setAthleteComission(r)
    }
    if (superComission * 1 + adminComission * 1 + athleteComission * 1 == 100) {
      setCando(true)
    } else {
      setCando(false)
    }
    setCm([superComission, adminComission, athleteComission])
  }, [superComission, adminComission, athleteComission])



  return (
    <SidebarContainer>
      <TitleContainer>
        <SidebarTitle>EDIT ADMIN</SidebarTitle>
      </TitleContainer>
      <ContractListContainer>
        <UserInfoContainer>
          <UserProfileImage src={user?.profile_picture || profileImage} />
          <UserName>{user?.nickname || "Hello"}<br />{club ? `(${club.name})` : ""}</UserName>
        </UserInfoContainer>
        {contracts.map((contract, index) => (
          <ContractInnerContainer key={contract.id}>
            <ContractListInnerContainer>
              <ContractIcon>{contract.icon}</ContractIcon>
              <ContractTitle>{contract.name}</ContractTitle>
            </ContractListInnerContainer>
            <Switch
              onChange={onChange}
              index={index}
              checked={permission[index]}
            />
          </ContractInnerContainer>
        ))}
      </ContractListContainer>
      <MinAmountInputLabel>Commission For YLG Team</MinAmountInputLabel>
      <MinAmountInput value={superComission} onChange={(e) => setSuperComission(e.target.value)} />
      {
        user?.role != "in_ath" &&
        <>
          <MinAmountInputLabel>Commission For School SportClub Admin</MinAmountInputLabel>
          <MinAmountInput value={adminComission} onChange={(e) => setAdminComission(e.target.value)} />
        </>
      }
      <MinAmountInputLabel>Commission For Athlete</MinAmountInputLabel>
      <MinAmountInput value={athleteComission} onChange={(e) => setAthleteComission(e.target.value)} />
      <SumBtnContainer>
        {
          cando ?
            <SetSumBtn onClick={submit}>SAVE</SetSumBtn> :
            <p style={{ marginTop: '20px' }}>Total sum of commissions should be 100(%)</p>
        }
      </SumBtnContainer>
      <CloseButton onClick={closeSidebar}><CloseOutlined /></CloseButton>
    </SidebarContainer>
  )
}
