import { useCallback, useEffect, useRef, useState, useContext } from "react"
import { useMoralis, useMoralisWeb3Api } from "react-moralis"
import { Loading } from "notiflix"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { Row, Col, Button, Input, Layout } from 'antd';
import "./style.css"


function ProfileShare({ moralis }) {
    const history = useHistory()
    const cameraInpRef = useRef(null)
    const { Moralis, user } = useMoralis()
    const Web3Api = useMoralisWeb3Api()
    const params = useParams()
    const [loaded, setLoaded] = useState(false)
    const [userData, setUserData] = useState({})
    const [groupData, setGroupData] = useState({})
    const [loadEnd, setLoadEnd] = useState(0)

    const readData = async () => {
        try {
            Loading.standard()
            const user = await moralis?.fn.Cloud.run("getUserById", {
                id: params.id,
            })
            if (user) { 
                setUserData(user?.attributes)
                if(user?.attributes.club){
                    const c = await Moralis.Cloud.run("getClub", { id: user?.attributes.club, }) 
                    setGroupData(c.attributes)
                }
                setLoadEnd(1)
            } else { 
                setLoadEnd(2)
            }
            Loading.remove()
        } catch (e) {
            Loading.remove()
            setLoadEnd(3)
        }
    }

    useEffect(() => {
        if (
            params.id != null &&
            params.id != undefined &&
            moralis != null &&
            loaded == false
        ) {
            setLoaded(true)
            readData()
        }
    }, [params.id, moralis])

    return (

        <Layout className="profile_layout team-detail">
            {
                loadEnd == 1 ?
                    <Row type="flex" gutter={[12, 12]} className="profile-sect-wrap">
                        <Col xs={24} md={8} lg={8} >
                            <div className="card-wrap flex-center">
                                <img className="profile-img" src={userData.profile_picture} />
                            </div>
                        </Col>
                        <Col xs={24} md={16} lg={16}>
                            <div className="card-wrap">
                                <p className="profile-info">Name : <span className="profile-info-data tx-capital">{userData.nickname}</span></p>
                                <p className="profile-info">Email : <span className="profile-info-data">{userData.email}</span></p>
                                <p className="profile-info">Age : <span className="profile-info-data">28</span></p>                                 
                                <p className="profile-info">Job : <span className="profile-info-data tx-capital">{groupData?.type} {userData.isAthlete ? "athlete" : "admin"}</span></p>
                                <p className="profile-info">Wallet : <a href={'https://testnet.bscscan.com/address/' + userData.ethAddress} target="_blank" rel="noreferrer" className="profile-info-data links" >{userData.ethAddress}</a></p>
                                <p className="profile-info">Bio : <span className="profile-info-data tx-capital">{userData.biography}</span></p>
                                <div className="flex-item-center">
                                    <div>
                                        <p className="profile-info">Team : <span className="profile-info-data tx-capital">{groupData?.name}</span></p>
                                        <p className="profile-info">Website : <a href={groupData?.link} target="_blank" rel="noreferrer" className="profile-info-data links">{groupData?.link}</a></p>
                                        <p className="profile-info">Type : <span className="profile-info-data tx-capital">{groupData?.type}</span></p>
                                    </div>
                                    <div>
                                        <img className="team-logo-sm" src={groupData?.icon} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> :
                    <Row style={{ minHeight: '78vh' }}>
                        <Col xs={24} md={24}>
                            {
                                loadEnd == 2 ?
                                    <h4>There is no such Athlete</h4> :
                                    loadEnd == 3 ? <h4>There is error to loading data</h4> : <></>
                            }
                        </Col>
                    </Row>
            }
        </Layout>
    )
}

export default ProfileShare
