import { ABI } from "../abi/ylvault.js"
const ENV_CONTRACT_ADDRESS = process.env.REACT_APP_YLVAULT_CONTRACT_ADDRESS



export const addNewSport = (category, playersNeeded) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "addNewSport",
    abi: ABI,
    params: {
      _category: category,
      _playersNeeded: playersNeeded,
    },
  }
}

export const setRevertNftToWalletCommision = (fee) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "setRevertNftToWalletCommision",
    abi: ABI,
    params: {
      _fee: fee,
    },
  }
}

export const depositeERC721toMainVault = (_tokenIds) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "depositeERC721toMainVault",
    abi: ABI,
    params: {
      _tokenIds
    },
  }
}

export const depositeERC1155toMainVault = (_tokenIds, _amounts) => { 
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "depositeERC1155toMainVault",
    abi: ABI,
    params: {
      _tokenIds,
      _amounts
    },
  }
}

export const depositeERC721toSubVault = (_tokenIds) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "depositeERC721toSubVault",
    abi: ABI,
    params: {
      _tokenIds,
    },
  }
}

export const depositeERC1155toSubVault = (_tokenId, _amount, _category) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "depositeERC1155toSubVault",
    abi: ABI,
    params: {
      _tokenId,
      _amount,
      _category
    },
  }
}

export const withdrawERC721toWallet = (_tokenId) => { 
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "withdrawERC721toWallet",
    abi: ABI,
    params: {
      _tokenId,
    },
  }
}

export const createAVault = (
  _gamer,
  _category,
  teamname,
  logo
) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "createAVault",
    abi: ABI,
    params: {
      _gamer,
      _category,
      teamname,
      logo,
    },
  }
}  

export const updateCounter = (walletAddress, category, amount) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "updateCounter",
    abi: ABI,
    params: {
      _gamer: walletAddress,
      _category: category,
      _amount: amount,
    },
  }
}

export const checkElegible = (walletAddress, category) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "checkElegible",
    abi: ABI,
    params: {
      _gamer: walletAddress,
      _category: category,
    },
  }
}

export const elegibleGamer = (walletAddress, category) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "elegibleGamer",
    abi: ABI,
    params: {
      walletAddress,
      category,
    },
  }
}

export const getSubvault = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getSubvault",
    abi: ABI,
    params: {
      _gamer: walletAddress,
    },
  }
}

export const nFTsCounter = (walletAddress, category) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "nFTsCounter",
    abi: ABI,
    params: {
      _address: walletAddress,
      _string: category,
    },
  }
}

export const playersNeeded = (category) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "playersNeeded",
    abi: ABI,
    params: {
      category,
    },
  }
}

export const vaultContract = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "vaultContract",
    abi: ABI,
    params: {
      walletAddress,
    },
  }
}
