import Carousel from "react-grid-carousel"
import { Button, Col, Input, Image, Row, Space, Tabs, Typography, Layout, Card, Drawer, Upload } from "antd"
import { PlusCircleOutlined, CloseOutlined, PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons"
import React, { useState, useEffect, useCallback } from "react"
import { Link } from "react-router-dom"
import { useMedia } from "hooks/useMedia"
import "./style.css"
import { BackButton } from "./components/basic"
import { useMoralisWeb3Api, useMoralis } from "react-moralis"
import axios from "axios"
import { play } from "utils/helpers/contest"
import {
  getSubvault,
  nFTsCounter,
  createAVault,
  withdrawERC721toWallet,
  depositeERC721toMainVault,
  depositeERC1155toMainVault
} from "utils/helpers/ylvault"
import { setApprovalForAll } from "utils/helpers/ylnft1155"
import { approve as approveYLT } from "utils/helpers/ylt"
import { tokenURI, approve } from "utils/helpers/ylnft721"
import { tokenURI as tokenUri } from "utils/helpers/ylnft1155"
import { Loading } from "notiflix"
import { useHistory } from "react-router"
import { sports } from "utils/dummyData"
import { RandomAvatar } from "react-random-avatars";
import { uuid } from 'uuidv4';

const TOKEN_ADDRESS = [
  process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS,
  process.env.REACT_APP_YLNFT1155_CONTRACT_ADDRESS,
]


const Vaultteam = ({ moralis }) => {
  let inputRef
  // var sports = ["Soccer", "Basketball", "Football", "Baseball", "Softball"]
  const [mintedNFT, setMintedNFT] = useState()
  const { user, isAuthenticated, Moralis, web3 } = useMoralis()
  const history = useHistory()
  const Web3Api = useMoralisWeb3Api()
  const isMobile = useMedia("(max-width: 640px)")
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [vault, setVault] = useState([])
  const [vault721, setVault721] = useState([])
  const [teamName, setTeamName] = useState("")
  const [logo, setLogo] = useState("")
  const [players, setPlayers] = useState([])
  const [sportList, setSportList] = useState([])
  const [sportType, setSportType] = useState("Soccer")
  const [erc721, setErc721] = useState([])
  const [erc1155, setErc1155] = useState([])
  const [teambooster, setTeambooster] = useState([])
  const [userRole, setUserRole] = useState("sponsor")
  const [teamIndex, setTeamIndex] = useState(0)
  const [modalIndex, setModalIndex] = useState(0)
  const [addlist, setAddlist] = useState([])
  const [removelist, setRemovelist] = useState([])
  const [merc721, setMerc721] = useState([])
  const [merc1155, setMerc1155] = useState([])
  const [addBstlist, setAddBstlist] = useState([])
  const [delBstlist, setDelBstlist] = useState([])
  const [avatarValue, setAvatarValue] = useState("16874475631780x0468a2a5c047d2e9682844a123f66c6ba8e169f1")
  const [rFlag, setRFlag] = useState(true)

  const showDrawer = (index) => {
    setModalIndex(index)
    setOpen(true)
  }

  const fileUpload = async (event) => {
    try {
      Loading.standard()
      const data = event
      console.log(">DDD", data)
      const image_ipfs = new Moralis.File(data.name, data)

      const img = await image_ipfs.saveIPFS()
      const image = "https://gateway.moralisipfs.com/ipfs/" + img._hash
      setLogo(image)
      Loading.remove()
    } catch (e) {
      console.log(e)
    }
  }



  const GenerateAvatar = async () => {
    try {
      const stamp = uuid()
      if (rFlag) {
        setAvatarValue(stamp);
        setRFlag(!rFlag)
      } else {
        Loading.standard()
        const canvas = document.querySelector("[data-testid='random-avatar']");
        var dataUrl = canvas.toDataURL();
        const image_ipfs = new Moralis.File(stamp, { base64: dataUrl })
        const img = await image_ipfs.saveIPFS()
        const image = "https://gateway.moralisipfs.com/ipfs/" + img._hash
        setRFlag(!rFlag)
        setLogo(image)
        Loading.remove()
      }
    } catch (e) {
      Loading.remove()
    }

  }

  const isBoostered = (subaddress) => {
    var boostered = false;
    for (var i = 0; i < teambooster.length; i++) {
      if (teambooster[i].vault === subaddress) {
        boostered = true;
      }
    }
    return boostered;
  }

  const readTeams = useCallback(async () => {
    try {
      Loading.standard()
      setLoading(true)
      const v = await moralis?.fn.Cloud.run("getTeamData", {
        wallet: user.attributes.ethAddress,
      })
      const bData = []
      v.data_booster.forEach(e => {
        const attr = e.attributes
        bData.push(attr)
      });
      setTeambooster(bData)

      const vData = []
      let ttt = sports
      v.data_team.forEach((e) => {
        const attr = e.attributes
        ttt = ttt.filter(sport => sport.value != attr.category)
        const t = {
          subaddress: attr.subaddress,
          teamname: attr.teamname,
          logo: attr.logo,
          vaultaddress: attr.address,
          category: attr.category,
          from: attr.from,
          gamer: attr.gamer,
          createdTime: attr.createdTime,
        }
        vData.push(t)
      })
      setSportList(ttt)
      ttt.length > 0 && setSportType(ttt[0].value)
      setVault(vData)


      const nfts = await moralis?.fn.Cloud.run("getNFTsFromMain", {
        gamer: user.attributes.ethAddress,
      })

      const mainNFT721 = nfts.data_nft721;
      const mainNFT1155 = nfts.data_nft1155;

      const nft721OfMain = []
      for (var j = 0; j < nfts.data_nft721.length; j++) {
        const attr = nfts.data_nft721[j].attributes
        const metaUri = await moralis?.fn.executeFunction(
          tokenURI(attr.tokenId),
        )
        const meta = await axios.get(metaUri)
        const n = {
          gamer: attr.gamerAddr,
          tokenId: attr.tokenId,
          meta: meta.data,
          price: nfts.sale_price[j]
        }
        nft721OfMain.push(n)
      }

      const nft1155OfMain = []
      for (var k = 0; k < nfts.data_nft1155.length; k++) {
        const attr = nfts.data_nft1155[k].attributes
        const metaUri = await moralis?.fn.executeFunction(
          tokenUri(attr.tokenId),
        )
        const meta = await axios.get(metaUri)

        const n = {
          id: attr.tokenId,
          imgSrc: meta.data.image,
          sport: meta.data.sport,
          title: meta.data.name,
          amount: attr.amount,
          meta: meta.data,
        }
        nft1155OfMain.push(n)
      }

      setMerc721(nft721OfMain)
      setMerc1155(nft1155OfMain)
      Loading.remove()
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Loading.remove()
      console.log(e)
    }
  }, [moralis, user])

  useEffect(() => {
    if (
      moralis != null &&
      user?.attributes.ethAddress != undefined &&
      loaded == false
    ) {
      setLoaded(true)
      readTeams()
    }
  }, [moralis, user?.attributes.ethAddress])

  const createTeam = async () => {
    try {
      Loading.standard()
      if (userRole == "sponsor") {
        const transaction = await Moralis.executeFunction(
          createAVault(
            user.attributes.ethAddress,
            sportType,
            teamName,
            logo,
          ),
        )
        const res = await transaction.wait()
      } else {
        const transaction = await Moralis.executeFunction(
          createAVault(
            user.attributes.ethAddress,
            'ads' + Date.now(),
            teamName,
            logo,
          ),
        )
        const res = await transaction.wait()
      }
      Loading.remove()
      window.location.reload()
    } catch (e) {
      Loading.remove()
      console.log(e)
    }
  }




  // read nfts from wallet
  const readNfts = async () => {
    try {
      if (isAuthenticated) {
        Loading.standard();
        const options = {
          chain: "bsc testnet",
          token_addresses: TOKEN_ADDRESS,
          address: user?.attributes.ethAddress,
        }

        Web3Api.account.getNFTs(options).then(async (nfts) => {
          nfts = nfts?.result.filter((nft) => nft.token_uri)
          const mintedNFT = await Promise.all(
            nfts.map(async (item) => {

              const metaTokenUri =
                item?.token_uri.substring(8, 12) == "ipfs"
                  ? item.token_uri.replace(/^.{28}/g, "https://gateway.moralisipfs.com",)
                  : item.token_uri
              const meta = await axios.get(metaTokenUri)
              const imgSrc = meta.data.image
              const price = "1 BNB"
              const dprice = "300"
              const nft = {
                id: item.token_id,
                imgSrc: imgSrc,
                sport: meta.data.sport,
                title: meta.data.name,
                price: price, //BNB
                priceUSD: dprice,
                brickColor: "blue",
                address: item.token_address,
                type: item.contract_type,
                meta: meta.data,
                amount: item.amount
              }
              return nft
            }),
          )
          var tmp721 = []
          var tmp1155 = []
          var bstList = []
          mintedNFT.forEach((n) => {
            if (n.type == "ERC721") {
              tmp721.push(n)
            } else {
              tmp1155.push(n)
              const bst = {
                id: n.id,
                amount: 0
              }
              bstList.push(bst)
            }
          })
          setErc721(tmp721)
          setErc1155(tmp1155)
          setAddBstlist(bstList)
        })
        Loading.remove()
      }
    } catch (e) {
      Loading.remove()
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      const role = user.attributes.role;
      setUserRole(role)
      readNfts();
    }
  }, [Web3Api.account, user?.attributes.ethAddress, isAuthenticated])

  const selectSportType = (e) => {
    setSportType(e.target.value)
  }

  const select2add = (i) => {
    if (addlist.includes(i)) {
      var d = addlist
      var index = d.indexOf(i)
      d.splice(index, 1)
      setAddlist([...d])
    } else {
      setAddlist([...addlist, i])
    }
  }

  const select2remove = (i) => {
    if (removelist.includes(i)) {
      var d = removelist
      var index = d.indexOf(i)
      d.splice(index, 1)
      setRemovelist([...d])
    } else {
      setRemovelist([...removelist, i])
    }
  }


  const addPlayer = async () => {
    if (addlist.length == 0) {
      return
    }
    var addPlayer = []
    addlist.forEach((i) => {
      addPlayer.push(erc721[i].id)
    })
    try {
      Loading.standard()
      for (var i = 0; i < addPlayer.length; i++) {
        const transaction = await Moralis.executeFunction(
          approve(process.env.REACT_APP_YLVAULT_CONTRACT_ADDRESS, addPlayer[i]),
        )
        await transaction.wait()
      }
      const transaction = await Moralis.executeFunction(
        depositeERC721toMainVault(
          addPlayer
        ),
      )
      await transaction.wait()
      Loading.remove()
      window.location.reload()
    } catch (e) {
      Loading.remove()
      console.log(e)
    }
  }


  const removePlayer = async (p) => {
    if (removelist.length == 0) {
      return
    }
    var removePlayer = []
    removelist.forEach((i) => {
      removePlayer.push(merc721[i].tokenId)
    })
    try {
      Loading.standard()
      for (var i = 0; i < removePlayer.length; i++) {
        var _price = ""
        merc721.forEach((erc) => {
          if (erc.tokenId == removePlayer[i]) {
            _price = erc.price
          }
        })
        const transaction = await Moralis.executeFunction(
          approveYLT(process.env.REACT_APP_YLVAULT_CONTRACT_ADDRESS, _price),
        )
        await transaction.wait()

        const transactions = await Moralis.executeFunction(
          withdrawERC721toWallet(
            removePlayer[i] * 1
          ),
        )
        await transactions.wait()
      }
      Loading.remove()
      window.location.reload()
    } catch (e) {
      Loading.remove()
    }
  }

  const boosterPlus = (item, mode) => {
    const id = item.id
    var bstList = addBstlist
    bstList.forEach((it, index) => {
      if (it.id == id) {
        if (mode == 0) {
          if (bstList[index].amount < item.amount) {
            bstList[index].amount++;
          }
        } else {
          if (bstList[index].amount > 0) {
            bstList[index].amount--;
          }
        }
      }
    })
    setAddBstlist([...bstList])
  }

  const addBooster = async () => {
    try {
      Loading.standard()


      var ids = [];
      var amounts = [];
      for (var i = 0; i < addBstlist.length; i++) {
        if (addBstlist[i].amount > 0) {
          ids.push(addBstlist[i].id * 1)
          amounts.push(addBstlist[i].amount)
        }
      }

      if (ids.length == 0) {
        return
      }

      const tr_approve = await Moralis.executeFunction(
        setApprovalForAll(
          process.env.REACT_APP_YLVAULT_CONTRACT_ADDRESS,
          true
        ),
      )
      await tr_approve.wait()

      const tr = await Moralis.executeFunction(
        depositeERC1155toMainVault(
          ids,
          amounts,
        ),
      )
      await tr.wait()
      Loading.remove()
      window.location.reload()
    } catch (e) {
      Loading.remove()
      console.log(e)
    }
  }

  return (
    <>
      <Layout className="layout">
        <Row justify="start" >
          <Col span={24}>
            <BackButton onClick={() => history.goBack()}>Back</BackButton>
            <input
              ref={(refParam) => (inputRef = refParam)}
              type="file"
              onChange={(e) => fileUpload(e.target.files[0])}
              style={{ display: "none" }}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="maring-top-20">
          <Col xs={24} md={12}>
            <h1 className="team-title"> {userRole == "sponsor" ? "my teams" : "my ads"}</h1>
          </Col>
          <Col xs={24} md={12} className="align-right">
            <Space wrap>
              <Button
                type="primary"
                className="primary-btn"
                block
                onClick={() => showDrawer(0)}
              >
                {userRole == "sponsor" ? "CREATE A TEAM" : "CREATE A ADS"}
              </Button>
              <Button type="primary" className="primary-btn"
                onClick={() => showDrawer(1)}
              >
                Apply Players
              </Button>
              <Button type="primary" className="primary-btn"
                onClick={() => showDrawer(2)}
              >
                Apply Boosters
              </Button>
            </Space>
          </Col>
        </Row>
        <Row className="maring-top-20">
          {loading == false && vault.length > 0 && vault.map((item, index) => {
            return (
              <Col
                key={index}
                xs={24}
                sm={12}
                md={8}
                lg={6}
                className="padding-10"
              >
                <Link to={userRole == "sponsor" ? "/teams/" + item.subaddress + "/" + item.teamname : "/ads/" + item.subaddress + "/" + item.teamname}>
                  <Card bordered={false} className="team-card">
                    <div className="booster-active-wrap">
                      {
                        isBoostered(item.subaddress) ?
                          <Typography className="booster-active">
                            booster activeated
                          </Typography> : <></>
                      }
                    </div>
                    {
                      userRole == "sponsor" ?
                        <img
                          className="team-logo"
                          src={item.logo}
                          alt="YTL_team_logo"
                        /> :
                        <img
                          className="ads-logo"
                          src={item.logo}
                          alt="YTL_team_logo"
                        />
                    }
                    <div className="card-title">{item.teamname}</div>
                  </Card>
                </Link>
              </Col>
            )
          })}
          <Col xs={24} sm={12} md={8} lg={6} className="padding-10">
            <Card
              bordered={true}
              className="team-card dotted-border"
              onClick={() => showDrawer(0)}
            >
              <div className="booster-active-wrap"></div>
              <PlusCircleOutlined className="team-add" />
              <div className="card-title">{userRole == "sponsor" ? "ADD A TEAM" : "ADD A ADS"}</div>
            </Card>
          </Col>
        </Row>
      </Layout>
      <Drawer
        placement={"right"}
        width={isMobile ? "90%" : "35%"}
        closable={false}
        // onClose={onClose}
        open={open}
        className="team-drawer"
      >
        {
          modalIndex == 0 ?
            <Row justify="space-between" align="middle">
              <Col xs={24} className="close-btn-wrap">
                <CloseOutlined
                  className="close-btn"
                  onClick={() => setOpen(false)}
                />
              </Col>
              <Col xs={24} md={24}>
                <h1 className="drawer-title">{userRole == "sponsor" ? "CREATE A TEAM" : "CREATE A ADS"}</h1>
              </Col>
              {sportList.length > 0 ? (
                <>
                  <Col xs={24} md={24}>
                    <Typography className="team-name">name</Typography>
                    <Typography className="enter-name">
                      Enter your {userRole == "sponsor" ? "team name" : "ads name"}
                    </Typography>
                    <Input
                      className="name-box"
                      placeholder="Name"
                      onChange={(e) => setTeamName(e.target.value)}
                    />
                  </Col>
                  {userRole == "sponsor" &&
                    <Col xs={24} md={24}>
                      <Typography className="enter-name maring-top-20">
                        Select sport type
                      </Typography>
                      <select
                        className="name-box sport-sel"
                        onChange={selectSportType}
                      >
                        {sportList?.map((item, index) => {
                          return <option value={item.value} key={index}>{item.value}</option>
                        })}
                      </select>
                    </Col>}
                  <Col xs={24} md={24}>
                    <Typography className="team-name">{userRole == "sponsor" ? "logo" : "Ads Image or Video"}</Typography>
                    <Row>
                      <Col xs={12} md={12}>
                        <Card bordered={true} className="logo-card">
                          {logo == "" ? (
                            <PlusCircleOutlined
                              className="logo-add"
                              onClick={() => inputRef.click()}
                            />
                          ) : (
                            <img
                              className="use-logo"
                              src={logo}
                              alt="YTL_team_logo"
                              onClick={() => inputRef.click()}
                            />
                          )}
                          <div className="add-logo">
                            {logo == "" ? "add" : "reset"}
                          </div>
                        </Card>
                      </Col>
                      <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                        <div>
                          {!rFlag && <RandomAvatar name={avatarValue} square={true} size={150} />}
                        </div>
                        <Button onClick={() => GenerateAvatar()}>{rFlag ? "Generate Random" : "Use This"}</Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={12} className="align-left maring-top-20">
                    <Button
                      type="primary"
                      block
                      className="team-create-btn"
                      onClick={() => createTeam()}
                    >
                      CREATE
                    </Button>
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={24} md={24}>
                    <Typography className="enter-name maring-top-20">
                      You have already created all teams for every sport.
                    </Typography>
                  </Col>
                </>
              )}
            </Row> :
            <Row justify="space-between" align="middle">
              <Col xs={24} className="close-btn-wrap">
                <CloseOutlined
                  className="close-btn"
                  onClick={() => setOpen(false)}
                />
              </Col>
              <Col xs={24} md={24}>
                <h1 className="drawer-title">{modalIndex == 1 ? "APPLY PLAYERS TO MAIN VAULT" : "APPLY BOOSTERS TO MAIN VAULT"}</h1>
              </Col>

              {
                modalIndex == 1 ?
                  <>
                    <Col xs={24} md={24}>
                      <Typography className="team-name">Players in your main vault</Typography>
                    </Col>
                    {merc721.length == 0 &&
                      <Col xs={24} md={24}>
                        <Typography className="enter-name maring-top-20">
                          There is no players applied to main vault.
                        </Typography>
                      </Col>
                    }
                    <Col xs={24} md={24} className="d-flex overflowX maring-top-10"  >
                      <Row className="logo-list-wrap">
                        {merc721.map((item, index) => {
                          return (
                            <Col key={index} xs={12} md={8}>
                              <Card bordered={true} className="logo-card">
                                <img
                                  className="add-player"
                                  src={item.meta.image}
                                  alt="YTL_team_logo"
                                />
                                <p>{Math.floor(Moralis.Units.FromWei(item.price))}</p>
                                <p className="player-title">{item.meta.name}</p>
                                <div className="sel-btn">
                                  <div
                                    className="opt-out"
                                    onClick={() => select2remove(index)}
                                  >
                                    {removelist.includes(index) && (
                                      <div className="opt-in"></div>
                                    )}
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          )
                        })}
                      </Row>
                    </Col>
                    {
                      merc721.length > 0 &&
                      <>
                        <Col xs={24} md={24}>
                          <Typography className="enter-name maring-top-20">
                            You can withdraw NFT721 to your wallet, but you should pay again.
                          </Typography>
                        </Col>
                        <Col xs={24} md={24} className="align-left maring-top-10">
                          <Row gutter={10}>
                            <Col xs={24} md={24}>
                              <Button
                                type="primary"
                                className="warn-btn"
                                block
                                onClick={() => removePlayer()}
                              >
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    }
                    <Col xs={24} md={24}>
                      <Typography className="team-name">Players in your wallet</Typography>
                    </Col>
                    {erc721.length == 0 &&
                      <Col xs={24} md={24}>
                        <Typography className="enter-name maring-top-20">
                          There is no players in your wallet.
                        </Typography>
                      </Col>
                    }
                    {
                      erc721.length > 0 &&
                      <>
                        <Col xs={24} md={24} className="d-flex overflowX maring-top-10"  >
                          <Row className="logo-list-wrap">

                            {erc721.map((item, index) => {
                              return (
                                <Col key={index} xs={12} md={8}>
                                  <Card bordered={true} className="logo-card">
                                    <img
                                      className="add-player"
                                      src={item.imgSrc}
                                      alt="YTL_team_logo"
                                    />
                                    <p className="player-title">{item.title}</p>
                                    <div className="sel-btn">
                                      <div
                                        className="opt-out"
                                        onClick={() => select2add(index)}
                                      >
                                        {addlist.includes(index) && (
                                          <div className="opt-in"></div>
                                        )}
                                      </div>
                                    </div>
                                  </Card>
                                </Col>
                              )
                            })}
                          </Row>
                        </Col>
                        <Col xs={24} md={24}>
                          <Typography className="enter-name maring-top-10">
                            You need to apply player(NFT721)s to main vault from your wallet for team.
                          </Typography>
                        </Col>
                        <Col xs={24} md={24} className="align-left maring-top-10">
                          <Row gutter={10}>
                            <Col xs={24} md={24}>
                              <Button
                                type="primary"
                                className="primary-btn"
                                block
                                onClick={() => addPlayer()}
                              >
                                Add
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    }
                  </>
                  :
                  <>
                    <Col xs={24} md={24}>
                      <Typography className="enter-name maring-top-20">
                        Boosters of your main vault
                      </Typography>
                    </Col>
                    <Col xs={24} md={24} className="d-flex overflowX maring-top-10"  >
                      <Row className="logo-list-wrap">
                        <Col xs={24} md={24} className="d-flex overflowX maring-top-10">
                          {merc1155.length > 0 && merc1155.map((item, index) => {
                            return (
                              <div key={index} className="trainer-item pos-rel">
                                <img
                                  className="booster-img "
                                  src={item?.imgSrc}
                                  alt="playerImage"
                                />
                                <div className="booster-info">
                                  {item.amount}
                                </div>
                                <div className="reward-btn-wrap">
                                  <Button type="primary" className="primary-btn" block>
                                    +{item.meta.boostedAmount} {item.meta.boostedCharact}
                                  </Button>
                                </div>
                              </div>
                            )
                          })}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} md={24}>
                      <Typography className="enter-name maring-top-20">
                        Boosters of your wallet
                      </Typography>
                    </Col>
                    <Col xs={24} md={24} className="d-flex overflowX maring-top-10"  >
                      <Row className="logo-list-wrap">
                        <Col xs={24} md={24} className="d-flex overflowX maring-top-10">
                          {erc1155.length > 0 && erc1155.map((item, index) => {
                            return (
                              <div key={index} className="trainer-item pos-rel">
                                <img
                                  className="booster-img "
                                  src={item?.imgSrc}
                                  alt="playerImage"
                                />
                                <div className="booster-info">
                                  {addBstlist[index]?.amount}
                                  /
                                  {item.amount}
                                </div>
                                <div className="booster-plus">
                                  <PlusSquareOutlined className="booster-btn"
                                    onClick={() => boosterPlus(item, 0)}
                                  />
                                  <MinusSquareOutlined className="booster-btn"
                                    onClick={() => boosterPlus(item, 1)}
                                  />
                                </div>
                                <div className="reward-btn-wrap">
                                  <Button type="primary" className="primary-btn" block>
                                    +{item.meta.boostedAmount} {item.meta.boostedCharact}
                                  </Button>
                                </div>
                              </div>
                            )
                          })}
                        </Col>
                        <Col xs={24} md={24}>
                          <Typography className="enter-name maring-top-20">
                            You need to apply booster to main vault for applying sport team.
                          </Typography>
                        </Col>
                        <Col xs={24} md={24} className="align-left maring-top-10">
                          <Row gutter={10}>
                            <Col xs={24} md={24}>
                              <Button
                                type="primary"
                                className="primary-btn"
                                block
                                onClick={() => addBooster()}
                              >
                                Apply to main vault
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </>

              }



            </Row>
        }


      </Drawer>
    </>
  )
}

export default Vaultteam
