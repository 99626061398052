import React, { useEffect, useState } from "react"
import { useMoralis } from "react-moralis"
import { useLocation } from "react-router"
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css';
import "./style.css";

import hAd01 from "images/ad/hAd01.jpg"
import hAd02 from "images/ad/hAd02.jpg"
import vAD01 from "images/ad/vAD01.jpg"
import vAD02 from "images/ad/vAD02.jpg"

export const ADComponent = ({ pose, allADs }) => {
  const { Moralis } = useMoralis()
  const [ads, setAds] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname
    if (allADs && allADs.length > 0) {
      // const adNFts = allADs.filter((item) => item.impPos == pose)
      const adNFts = allADs.filter((item) => item.impPos == pose && item.impRouter == path)
      setAds(adNFts)
    }
  }, [location.pathname, allADs]);

  return (
    <div style={{ padding: "10px" }}>
      {
        (pose == "Body Left" || pose == "Body Right") ?

          <Swiper
            centeredSlides={false}
            direction={"vertical"}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop
          >
            {
              ads.map((ad, key) =>
                <SwiperSlide key={key}>
                  <img src={ad.imgSrc} style={{ width: "150px", objectFit: "contain" }} />
                </SwiperSlide>
              )
            }
          </Swiper>
          :
          <Swiper
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop
          >
            {
              ads.map((ad, index) =>
                <SwiperSlide key={index} style={{ listStyle: "none", display: 'flex', alignItems: 'center', justifyContent: "center", height: "100px" }}>
                  <img src={ad.imgSrc} style={{ width: "100%", height: "100px", objectFit: "cover" }} />
                </SwiperSlide>
              )
            }
          </Swiper>
      }
    </div>
  )
}
