import { DappContext } from "context"
import { useCallback, useContext, useEffect, useState } from "react"
import { useMoralis } from "react-moralis"
import { useHistory } from "react-router"
import { BackButton } from "screens/Tournaments/components/basic"
import { Button, Col, Input, Image, Row, Space, Tabs, Typography, Layout, Modal, Card, Drawer, Upload } from "antd"
import { tokenURI } from "utils/helpers/ylnft721"
import { Loading } from "notiflix"
import axios from "axios"
import "./styles/tournament.css"
import { createMatchID, play } from "utils/helpers/contest"

const ManageTournament = ({ moralis }) => {
    const { user } = useMoralis()
    const history = useHistory()
    const [load, setLoad] = useState(false)
    const [teams, setTeams] = useState([])
    const [players, setPlayers] = useState([])
    const [playerCnt, setPlayerCnt] = useState([])
    const [tournaments, setTournaments] = useState([])
    const [tournamentIndex, setTournamentIndex] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const { Moralis } = useMoralis()
    const [match, setMatch] = useState([0, 1])

    const [matchBackImg, setMatchBackImg] = useState("/match.png")
    const [matchPrice, setMatchPrice] = useState(0)
    const [tcategory, setTcategory] = useState("Soccer")
    const [tid, setTid] = useState(0)
    const [matched, setMatched] = useState({ status: false, data: '0' })

    const [matchedPlayer, setMatchedPlayer] = useState([])


    const startMatch = async () => {
        try {
            Loading.standard()
            const team1 = teams[match[0]];
            const team2 = teams[match[1]];
            if (matched.status) {
                //play
                console.log(">>play")
                console.log(">>>", team1, team2)
            } else {



                const player1 = team1.gamer;
                const player2 = team2.gamer;
                if (getMatchBadge(player1) == "" && getMatchBadge(player2) == "") {
                    var ps = [player1, player2]
                    ps = ps.sort();
                    console.log(">>>>>T", tid)
                    const transaction = await Moralis.executeFunction(
                        createMatchID(
                            ps[0],
                            ps[1],
                            tcategory,
                            tid
                        ),
                    )
                    const res = await transaction.wait()
                    readMatchId(player1, player2, tcategory, tid)
                } else {
                    alert("Already matched")
                }

            }
            Loading.remove()
        } catch (e) {
            console.log(">>>>E", e)
            Loading.remove()
        }
    }



    const readMatchId = async (player1, player2, category, tid) => {
        var ps = [player1, player2]
        ps = ps.sort();
        const v = await moralis?.fn.Cloud.run("getMatchData", { player1: ps[0], player2: ps[1], category, tid })
        setMatched({ ...v })


    }

    useEffect(() => {
        if (teams.length > 1) {
            readMatchId(teams[match[0]]?.gamer, teams[match[1]]?.gamer, tcategory, tid)
        }
    }, [match, teams])

    const readData = useCallback(async () => {
        try {
            Loading.standard()
            setLoaded(true)
            const v = await moralis?.fn.Cloud.run("getTournaments")

            const tn = v.data_tournament;
            const _tournament = [];
            for (var i = 0; i < tn.length; i++) {
                const _t = tn[i].attributes;
                const meta = await axios.get(_t.uri)
                const t_one = {
                    address: _t.address,
                    maxplayer: _t.maxPlayers,
                    fee: _t.tournamentFee,
                    id: _t.tournamentID,
                    category: _t.category,
                    updatedAt: _t.updatedAt,
                    uri: _t.uri,
                    paidFee: _t?.paidFee == undefined ? 0 : _t?.paidFee,
                    teamCount: _t?.teamCount == undefined ? 0 : _t?.teamCount,
                    created: _t.creationTime,
                    meta: meta.data
                }
                _tournament.push(t_one)
            }
            console.log(">>>>>LOG", _tournament)
            setTournaments(_tournament)
            if (_tournament.length > 0) {
                selectTournament(_tournament[0], 0)
            }
            Loading.remove()
        } catch (e) {
            Loading.remove()
            console.log(e)
        }
    }, [moralis])

    useEffect(() => {
        if (
            moralis != null &&
            user?.attributes.ethAddress != undefined &&
            loaded == false
        ) {
            setLoaded(true)
            readData()
        }
    }, [moralis, user?.attributes.ethAddress])

    useEffect(() => {
        if (user && (!user.attributes.isSuperAdmin || !user.attributes.isAdmin)) {
            Modal.error({
                title: 'Error message',
                content: "Only administrators can access this page.",
                onOk() {
                    return window.location.replace("/nftMarket")
                },
            });
        }
    }, [user])

    const selectTournament = async (t, index) => {
        try {
            Loading.standard()
            setTournamentIndex(index)
            const id = t.id
            const category = t.category
            const img = t.meta.image
            setTid(t.id)
            setTcategory(t.category)
            setMatchBackImg(img)
            setMatchPrice(Moralis.Units.FromWei(t.paidFee))
            const v = await moralis?.fn.Cloud.run("getTournamentTeamList", { id, category })
            var vData = [];
            v.subVault.forEach((e) => {
                const attr = e.attributes
                const t = {
                    subaddress: attr.subaddress,
                    teamname: attr.teamname,
                    logo: attr.logo,
                    vaultaddress: attr.address,
                    category: attr.category,
                    from: attr.from,
                    gamer: attr.gamer,
                    createdTime: attr.createdTime,
                    property: attr.property
                }
                vData.push(t)
            })
            setTeams(vData)
            if (vData.length > 0) {
                selectTeam(vData[0].subaddress, 0)
            }

            var mp = []
            v.match_list.forEach((e, idx) => {
                const attr = e.attributes
                const p1 = {
                    matchID: attr.matchID,
                    player: attr.player1,
                    index: idx
                }
                const p2 = {
                    matchID: attr.matchID,
                    player: attr.player2,
                    index: idx
                }
                mp.push(p1)
                mp.push(p2)
            })
            setMatchedPlayer(mp)

            Loading.remove()
            console.log(">>Vdata", vData)
        } catch (e) {
            Loading.remove()
        }
    }

    const selectTeam = async (vault, index) => {
        try {
            matchFor(index)
            Loading.standard()
            const v = await moralis?.fn.Cloud.run("getOneTeamData", {
                vault: vault,
            })
            const pData = []
            var power = 0
            var speed = 0
            var energy = 0
            for (var i = 0; i < v.data_players.length; i++) {
                const attr = v.data_players[i].attributes
                const metaUri = await moralis?.fn.executeFunction(
                    tokenURI(attr.tokenId),
                )
                const meta = await axios.get(metaUri)
                const n = {
                    vaultaddress: attr.address,
                    subaddress: attr.vault,
                    from: attr.from,
                    gamer: attr.gamer,
                    tokenId: attr.tokenId,
                    depositTime: attr.depositTime,
                    meta: meta.data,
                }
                power = power + meta.data.personal.power * 1
                speed = speed + meta.data.personal.speed * 1
                energy = energy + meta.data.personal.energy * 1
                pData.push(n)
            }
            setPlayers(pData)
            var tmp = []
            for (var j = 0; j < 5; j++) {
                if (pData.length > j) {
                    tmp.push(true)
                } else {
                    tmp.push(false)
                }
            }
            setPlayerCnt(tmp)
            console.log(">>>Pdata", pData)
            Loading.remove()
            setLoad(true)
        } catch (e) {
            Loading.remove()
        }
    }

    const matchFor = (index) => {
        var mt = match;
        mt[0] = mt[1];
        mt[1] = index;

        if (mt[0] != index) {
            setMatch([...mt])
        }
    }

    const getMatchBadge = (player) => {
        var mid = "";
        matchedPlayer.forEach((p) => {
            if (p.player == player) {
                mid = p.matchID
            }
        })
        return mid;
    }

    return (
        <Layout className="layout">
            <Row justify="start" >
                <Col span={24}>
                    <BackButton onClick={() => history.goBack()}>Back</BackButton>
                </Col>
            </Row>
            {
                load &&
                <Row justify="start" gutter={16}>
                    <Col xs={24} sm={3} md={3} className="team-list">
                        {
                            teams.map((t, index) => {
                                return (
                                    <>

                                        <div key={index} className="team-wrap" onClick={() => selectTeam(t.subaddress, index)} style={{position:'relative'}}>
                                            <img className={match.includes(index) ? index == match[1] ? "team team-selected-2" : "team team-selected" : "team"} src={t.logo} alt="" />
                                            <div className="match-badge">
                                                {getMatchBadge(t.gamer)}
                                            </div>
                                        </div>
                                    </>

                                )
                            })
                        }
                    </Col>
                    <Col xs={24} sm={17} md={17} className="match-wrap" style={{ background: `url(` + matchBackImg + `)` }}>
                        <div className="match-btn" onClick={() => startMatch()}>{matched.status ? "START PLAY" : "START MATCH"} </div>
                        <div className="match-result-cup">
                            <img className="cup" src="/cup.png" alt="cup" />
                            <p className="cup-amount">{matchPrice}</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={4} md={4}>
                        <div className="team-data">
                            <div className="team-eligble">
                                {
                                    playerCnt.map((p, index) => {
                                        return (
                                            p ? <div key={index} className="one-dot-active"></div> : <div key={index} className="one-dot-deactive"></div>
                                        )
                                    })
                                }
                                <div className="team-count">{players.length}/{playerCnt.length}</div>
                            </div>
                            <div className="team-player">
                                {
                                    players.length > 0 ? players.map((p, index) => {
                                        return (
                                            <div key={index}>
                                                <img className="avatar-tournament" src={p.meta.image} alt="" />
                                                <div className="avatar-name">{p.meta.name}</div>
                                            </div>
                                        )
                                    }) : <p>No players</p>
                                }
                            </div>

                        </div>
                    </Col>
                </Row>
            }

            <Row justify="start" gutter={16}>
                {
                    tournaments.map((t, index) => {
                        return (
                            <Col key={index} xs={24} sm={24} md={24}>
                                <div className={tournamentIndex == index ? "tournament-items active-item" : "tournament-items"} onClick={() => selectTournament(t, index)}>
                                    <img className="tournament-icon" src={t.meta.image} alt="" />
                                    <div>
                                        <div>Title: {t.meta.name}</div>
                                        <div>Description: {t.meta.desc}</div>
                                    </div>
                                    <div>
                                        <div>Places: {t.teamCount} / {t.maxplayer} </div>
                                        <div>Pool Prizes: {Moralis.Units.FromWei(t.paidFee)} YLT</div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </Layout>
    )
}

export default ManageTournament
