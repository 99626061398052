import { DappContext } from "context"
import React, { useContext, useEffect, useState } from "react"
import {
  OfficialOfferAuctionButtonsContainer,
} from "../styles/ButtonsStyling"
import { Radio } from 'antd';

const Buttons = () => {
  const { flag_offerAuction, setFlagOfferAuction } = useContext(DappContext)

  const onChange = (e) => {
    setFlagOfferAuction(e.target.value)
  };

  return (
    <OfficialOfferAuctionButtonsContainer>
      <Radio.Group onChange={onChange} defaultValue={flag_offerAuction} buttonStyle="solid"  size="large" style={{width:"100%", display:"flex", height:"100%"}}>
        <Radio.Button value="all" style={{fontSize:"20px",width:"25%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>All</Radio.Button>
        <Radio.Button value="market" style={{fontSize:"20px",width:"25%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>Marketplace</Radio.Button>
        <Radio.Button value="offer" style={{fontSize:"20px",width:"25%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>Offer</Radio.Button>
        <Radio.Button value="auction" style={{fontSize:"20px",width:"25%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>Auction</Radio.Button>
      </Radio.Group>
    </OfficialOfferAuctionButtonsContainer>
  )
}

export default Buttons
