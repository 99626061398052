
import { tokenURI } from "utils/helpers/ylnft721"
import { tokenURI as tokenURI1155 } from "utils/helpers/ylnft1155"

// number format
export const formatNumber = (number) => {
  const unitWords = ["", "k", "m", "b"]
  const splitUnit = 1000
  let splitCount = 0

  while (number >= splitUnit && splitCount < unitWords.length - 1) {
    number /= splitUnit
    splitCount++
  }
  const roundedNumber = Math.round(number * 100) / 100
  const resultString = String(roundedNumber) + unitWords[splitCount]

  return resultString
}

// Returns a random date (with a time of midnight) between start and end dates
export const getRandomDate = (start, end) => {
  let date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

  return date.toString()
}

export const nftDetail = async (moralis, nft, rate, page = "market", address) => {
  const isERC721 = nft.className === "NFT721"
  const tokenId = nft?.attributes.tokenId.toString()
  const meta = await moralis.Cloud.run("fetchNFTMetadata", { url: nft?.attributes.token_uri })
  const imgSrc = meta.data.image
  const price = moralis.Units.FromWei(nft?.attributes.price.toString())
  const dprice = moralis.Units.FromWei(nft?.attributes.price.toString()) / rate
  const type = nft?.attributes.type
  let action = []
  let status = ""
  let itemId = ""
  let brickColor = "#3985f5"
  if (type == "auction") {
    brickColor = "#C9C9C9"
  } else if (type == "offer") {
    brickColor = "#0D51FF"
  } else if (type == "bundle") {
    brickColor = "#B20DFF"
  } else if (type == "marketplace") {
    brickColor = "#F7A300"
  }
  if (page == "market") {
    if (type == "auction") {
      status = "Auction"
      itemId = nft?.attributes.itemId
    } else if (type == "offer") {
      status = "Offer"
    } else if (type == "bundle") {
      status = "Bundle"
      action = ["", "REMOVE"]
    } else if (type == "marketplace") {
      status = "MARKETPLACE"
      itemId = nft?.attributes.itemId
    }
  } else {
    if (type == "minted") {
      status = "NOT LISTED"
      action = ["SELL", "ADD IN", "BURN"]
    } else if (type == "auction") {
      status = "Auction"
      action = isERC721 || (!nft?.attributes.bidder || nft?.attributes.bidder != address) ? ["EDIT", "REMOVE"] : ["", "FINISH"]
      itemId = nft?.attributes.itemId
    } else if (type == "offer") {
      status = "Offer"
      action = ["", "REMOVE"]
    } else if (type == "bundle") {
      status = "Bundle"
      action = ["", "REMOVE"]
    } else if (type == "marketplace") {
      status = "MARKETPLACE"
      action = ["EDIT", nft?.attributes.market_state == "0" ? "PAUSE" : "UNPAUSE"]
      itemId = nft?.attributes.itemId
    }
  }
  return {
    id: tokenId,
    itemId,
    imgSrc: imgSrc,
    title: meta.data.name,
    price: price, //BNB
    priceUSD: dprice,
    brickColor: brickColor,
    address: isERC721 ? process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS : process.env.REACT_APP_YLNFT1155_CONTRACT_ADDRESS,
    status,
    type,
    action,
    isERC721,
    owner: nft?.attributes.owner,
    sport: meta.data.sport,
    date: nft?.updatedAt,
    amount: isERC721 ? 1 : nft?.attributes.amount,
    meta: meta.data,
    personal: meta.data.personal
      ? {
        speed: meta.data.personal.speed,
        dexterity: meta.data.personal.energy,
        stamina: meta.data.personal.luck,
        dribbling: meta.data.personal.power,
        finishing: meta.data.personal.wizzardy,
      }
      : null,
  }
}

export const vaultNftDetail = async (moralis, nft) => {
  const isERC721 = nft.className === "MainVaultNFT721"
  const attr = nft.attributes
  const metaUri = await moralis.executeFunction(isERC721 ? tokenURI(attr.tokenId) : tokenURI1155(attr.tokenId))
  const meta = await moralis.Cloud.run("fetchNFTMetadata", { url: metaUri })
  return {
    gamer: attr.gamerAddr,
    owner: attr.gamerAddr,
    id: attr.tokenId,
    imgSrc: meta.data.image,
    sport: meta.data.sport,
    title: meta.data.name,
    brickColor: "#3985f5",
    amount: meta.data.amount,
    address: isERC721 ? process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS : process.env.REACT_APP_YLNFT1155_CONTRACT_ADDRESS,
    personal: meta.data.personal,
    isERC721: isERC721,
    meta: meta.data,
  }
}

export const walletNFTDetail = async (moralis, nft, rate) => {
  const metaTokenUri =
    nft?.token_uri?.substring(8, 12) == "ipfs"
      ? nft.token_uri.replace(
        /^.{28}/g,
        "https://gateway.moralisipfs.com",
      )
      : nft.token_uri
  const meta = await moralis.Cloud.run("fetchNFTMetadata", { url: metaTokenUri, })
  const imgSrc = meta.data.image
  const isERC721 = nft.contract_type === "ERC721"
  const data = { tokenId: nft.token_id, isERC721 }
  const isOfferCard = await moralis.Cloud.run("isOfferNFT", data)
  const status = isOfferCard ? "Offer" : "NOT LISTED"
  const type = nft.type
  const action = isOfferCard ? ["SELL", "REMOVE"] : ["SELL", "ADD IN OFFER"]
  let brickColor = "#3985f5"
  if (type == "auction") {
    brickColor = "#C9C9C9"
  } else if (type == "offer") {
    brickColor = "#0D51FF"
  } else if (type == "bundle") {
    brickColor = "#B20DFF"
  } else if (type == "marketplace") {
    brickColor = "#F7A300"
  }
  return {
    id: nft.token_id,
    imgSrc: imgSrc,
    title: meta.data.name,
    brickColor: brickColor,
    address: nft.token_address,
    status,
    action,
    isERC721,
    owner: nft.owner_of,
    sport: meta.data.sport,
    date: nft.last_metadata_sync,
    amount: nft.amount,
    meta: meta.data,
    personal: meta.data.personal
      ? {
        speed: meta.data.personal.speed,
        dexterity: meta.data.personal.energy,
        stamina: meta.data.personal.luck,
        dribbling: meta.data.personal.power,
        finishing: meta.data.personal.wizzardy,
      }
      : null,
  }
}

export const simpleNFTDetail = async (moralis, nft) => {
  const isERC721 = nft.className === "NFT721"
  const tokenId = nft?.attributes.tokenId.toString()
  const meta = await moralis.Cloud.run("fetchNFTMetadata", { url: nft?.attributes.token_uri })
  const imgSrc = meta.data.image
  return {
    id: tokenId,
    imgSrc: imgSrc,
    title: meta?.data.name,
    brickColor: "#3985f5",
    address: isERC721 ? process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS : process.env.REACT_APP_YLNFT1155_CONTRACT_ADDRESS,
    isERC721,
    owner: nft?.attributes.owner,
    sport: meta?.data.sport,
    meta: meta.data,
  }
}

export const walletSimpleNFTDetail = async (moralis, nft) => {
  const metaTokenUri =
    nft?.token_uri?.substring(8, 12) == "ipfs"
      ? nft.token_uri.replace(
        /^.{28}/g,
        "https://gateway.moralisipfs.com",
      )
      : nft.token_uri
  const meta = await moralis.Cloud.run("fetchNFTMetadata", {
    url: metaTokenUri,
  })
  const imgSrc = meta?.data.image
  const isERC721 = nft.contract_type === "ERC721"
  return {
    id: nft.token_id,
    imgSrc: imgSrc,
    title: meta?.data.name,
    brickColor: "#3985f5",
    address: isERC721 ? process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS : process.env.REACT_APP_YLNFT1155_CONTRACT_ADDRESS,
    isERC721,
    owner: nft.owner_of,
    sport: meta?.data.sport,
    meta: meta.data,
  }
}