import React, { useContext, useState, useEffect, useCallback } from "react"
import { NftCard } from "screens/Admin/NftCard"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import {
  Button,
  ButtonContainer,
  FilterButton,
  Input,
  MainContainer,
  MainTitle,
  NftContainer,
  SearchButton,
  StatContainer,
  StatCounter,
  TopContainer,
  TopInnerContainer,
  TopLeftContainer,
  TopRightContainer,
  BackBtn,
} from "./styles/AllNftStyling"
import { Select } from "antd"
import { DappContext } from "context"
import { useHistory } from "react-router"
import { CreateOffer } from "components/Sidebar/CreateOffer"
import { CreateBundle } from "components/Sidebar/CreateBundle"
import { AllNftFilter } from "components/Sidebar/AllNftFilter"
import { useMoralis } from "react-moralis"
import { Loading } from "notiflix"
import { isMintableAccount, mintableAccounts, } from "utils/helpers/proxy"
import { useSwapRate } from "hooks/useSwapRate"
import { nftDetail } from "utils/function"

export const AllNft = ({ moralis }) => {
  const { user } = useMoralis()
  const history = useHistory()
  const [isLoading, setLoading] = useState(true)
  const [filterSelected, setFilterSelected] = useState("all")
  const [onceLoad, setOnceLoad] = useState(true)
  const [active, setActive] = useState("1")
  const [allNfts, setAllNfts] = useState([])
  const [searchNfts, setSearchNfts] = useState([])
  const [number, setNumber] = useState(0)
  const [isSort, setSort] = useState(true)
  const [searchKey, setSearchKey] = useState("")
  const [filterAction, setFilterAction] = useState(false)
  const { onCloseSidebar, setSidebarContent, setOpenSidebar } = useContext(DappContext)
  const rate = useSwapRate()

  const loadNFTs = async () => {
    const type = "admin"
    const address = user?.attributes.ethAddress
    const result = await moralis?.fn.Cloud.run("FetchNFTs", { type, address, })
    const listedNFT = await Promise.all(
      result?.map(async (item) => nftDetail(moralis?.fn, item, rate, "allNFTs", address)),
    )
    return listedNFT
  }

  const loadloadInitNFTsNFTs = useCallback(async () => {
    if (moralis) {
      setLoading(true)
      Loading.standard()
      let result = await loadNFTs()
      result.sort((a, b) => {
        return isSort
          ? new Date(b.date) - new Date(a.date)
          : new Date(a.date) - new Date(b.date)
      })
      setAllNfts(result)
      setNumber(result.length)
      setSearchNfts(result)
      setLoading(false)
      Loading.remove()
    }
  }, [loadNFTs])

  const loadFilterNFTs = useCallback(async () => {
    if (moralis) {
      setLoading(true)
      Loading.standard()
      let result
      if (filterSelected == "all") {
        result = allNfts
      } else {
        result = allNfts.filter((nft) => nft.type == filterSelected)
      }
      if (searchKey !== "")
        result = allNfts.filter((nft) => {
          const word = nft.title.toLowerCase()
          return word.search(searchKey.toLowerCase()) !== -1
        })
      result.sort((a, b) => {
        return isSort
          ? new Date(b.date) - new Date(a.date)
          : new Date(a.date) - new Date(b.date)
      })
      setNumber(result.length)
      setSearchNfts(result)
      setLoading(false)
      Loading.remove()
    }
  }, [allNfts, filterSelected, isSort, searchKey])

  useEffect(() => {
    if (moralis && !onceLoad && filterAction) {
      loadFilterNFTs()
      setFilterAction(false)
    }
  }, [loadFilterNFTs, onceLoad, filterAction])

  useEffect(() => {
    if (moralis && onceLoad) {
      loadloadInitNFTsNFTs()
      setOnceLoad(false)
    }
  }, [moralis, onceLoad, loadloadInitNFTsNFTs])

  const handleButtonFilter = (e) => {
    if (isLoading) {
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: "Loading...",
      })
    } else {
      setFilterSelected(e.target.value)
      setActive(e.target.id)
      setFilterAction(true)
    }
  }

  const handleOfferClick = () => {
    setSidebarContent(
      <CreateOffer moralis={moralis} closeSidebar={onCloseSidebar} />,
    )
    setOpenSidebar(true)
  }

  const handleBundleClick = () => {
    setSidebarContent(
      <CreateBundle moralis={moralis} closeSidebar={onCloseSidebar} />,
    )
    setOpenSidebar(true)
  }

  const handleFilter = (event) => {
    setSidebarContent(<AllNftFilter closeSidebar={onCloseSidebar} />)
    setOpenSidebar(true)
  }

  const handleSortChange = () => {
    setSort(!isSort)
    setFilterAction(true)
  }

  const handleSearchChange = (e) => {
    if (e.keyCode === 13 || e.target.value === "") {
      setSearchKey(e.target.value)
      setFilterAction(true)
    }
  }

  const toCreateNFTFunc = async (type) => {
    Loading.standard()
    try {
      const isMintable = await moralis?.fn.executeFunction(isMintableAccount(user?.attributes.ethAddress))
      const mintable = await moralis?.fn.executeFunction(mintableAccounts(user?.attributes.ethAddress))
      if (isMintable || user?.attributes.isSuperAdmin) {
        history.push({ pathname: "/admin/createNFT", state: { type } })
        Loading.remove()
      } else {
        Loading.remove()
        Modal.error({
          icon: <ExclamationCircleOutlined />,
          content: mintable
            ? `You should deposit some YLT to create NFT!`
            : "Super Admin should give you mint permission!",
        })
      }
    } catch (error) {
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }

  return (
    <MainContainer>
      <BackBtn
        onClick={() => {
          history.push("/admin")
        }}
      >
        back
      </BackBtn>
      <TopContainer>
        <MainTitle>all nft</MainTitle>
        <ButtonContainer>
          <Button onClick={() => toCreateNFTFunc("Player")}>create game nft</Button>
          <Button onClick={() => toCreateNFTFunc("Sponsorship")}>create Sponsorship nft</Button>
          {
            user?.attributes.role == "ylg_adm" && <>
              <Button onClick={handleBundleClick}>create bundle</Button>
              <Button onClick={handleOfferClick}>create offer</Button>
            </>
          }
        </ButtonContainer>
      </TopContainer>
      <TopInnerContainer>
        <TopLeftContainer>
          <FilterButton
            id="1"
            active={active}
            onClick={handleButtonFilter}
            value="all"
            disabled={isLoading}
          >
            all
          </FilterButton>
          <FilterButton
            id="2"
            active={active}
            onClick={handleButtonFilter}
            value="minted"
            disabled={isLoading}
          >
            minted
          </FilterButton>
          <FilterButton
            id="3"
            active={active}
            onClick={handleButtonFilter}
            value="auction"
            disabled={isLoading}
          >
            auction
          </FilterButton>
          <FilterButton
            id="4"
            active={active}
            onClick={handleButtonFilter}
            value="marketplace"
            disabled={isLoading}
          >
            marketplace
          </FilterButton>
          <FilterButton
            id="5"
            active={active}
            onClick={handleButtonFilter}
            value="offer"
            disabled={isLoading}
          >
            offer
          </FilterButton>
          <FilterButton
            id="6"
            active={active}
            onClick={handleButtonFilter}
            value="bundle"
            disabled={isLoading}
          >
            bundle
          </FilterButton>
        </TopLeftContainer>
        <TopRightContainer>
          <Input onKeyUp={handleSearchChange} placeholder="Search" />
          <SearchButton onClick={handleFilter}>filters</SearchButton>
        </TopRightContainer>
      </TopInnerContainer>
      <StatContainer>
        <StatCounter>{number} NFT</StatCounter>
        <Select
          defaultValue="FILTER NEW ONES"
          disabled={isLoading}
          onChange={handleSortChange}
        >
          <Select.Option value="filter new ones">FILTER NEW ONES</Select.Option>
          <Select.Option value="filter old ones">FILTER OLD ONES</Select.Option>
        </Select>
      </StatContainer>
      <NftContainer>
        {searchNfts.map((card, index) => (
          <NftCard
            key={index}
            nft={card}
            moralis={moralis}
          />
        ))}
      </NftContainer>

    </MainContainer>
  )
}