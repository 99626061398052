
import React, { useState, useEffect } from "react"
import { OuterContainer, Title, StepButton, ProfileBox, DropDownContainer, DropFileStyle, AttachContainer, AsideContainer, AsideImageContainer, ButtonGroup } from "./CreateSchoolClubStyling"
import { useDropzone } from "react-dropzone"
import attachSvg from "images/attach.svg"
import InputComponent from "screens/AuthFlow/components/InputComponent"
import SelectBox from "components/SelectBox"
import { useMoralis } from "react-moralis"
import { Loading } from "notiflix"
import { Modal, Checkbox } from "antd"
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons"
import { sports } from "utils/dummyData"
import { CloseButton, SidebarContainer, } from "components/Sidebar/styles/SidebarStyling"
import { TitleField, SelectBoxDropdown, } from "screens/Admin/styles/CreateNFTStyles"

const CheckboxGroup = Checkbox.Group;
const Types = [
  { id: 1, value: "school", label: "School" },
  { id: 2, value: "club", label: "Sport Club" },
]

export const EditSchoolClub = ({ club, closeSidebar }) => {
  const { Moralis, user } = useMoralis()
  const [type, setType] = useState("")
  const [files, setFiles] = useState([])
  const [clubFields, setClubFields] = useState({})

  useEffect(() => {
    if (club) {
      const sel = Types.filter(c => c.value == club.attributes.type)
      setType(sel[0]?.label)
      setFiles([{ name: club.attributes.name, preview: club.attributes.icon }])
      setClubFields({
        id: club.id,
        name: club.attributes.name,
        link: club.attributes.link,
        type: club.attributes.type,
        sport: club.attributes.sport,
        description: club.attributes.description,
        icon: club.attributes.icon,
      })
    }
  }, [club])

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
    },
  })

  const thumbs = files.map((file) => (
    <AsideImageContainer key={file.name}>
      <img
        src={file.preview}
        onLoad={() => {
          URL.revokeObjectURL(file.preview)
        }}
      />
    </AsideImageContainer>
  ))

  const onAcceptHanlder = async () => {
    Loading.standard();
    try {
      if (club.attributes.name != clubFields.name) {
        const checkName = await Moralis.Cloud.run("checkClubName", { name: clubFields.name })
        if (checkName) {
          Loading.remove()
          Modal.error({
            icon: <ExclamationCircleOutlined />,
            content: "This club name is already exist!",
          })
          return
        }
      }
      let request = clubFields
      if (files[0].preview != club?.attributes.icon) {
        const data = files[0];
        const file = new Moralis.File(data.name, data);
        await file.saveIPFS();
        request["icon"] = file._ipfs;
      }
      await Moralis.Cloud.run("updateClub", request)
      location.reload()
    } catch (error) {
      Loading.remove();
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }

  return (
    <SidebarContainer>
      <OuterContainer>
        <div>
          <Title>Edit New School & Sport Clubs</Title>
          <>
            <TitleField>{"Type"}</TitleField>
            <SelectBoxDropdown>
              <SelectBox
                options={Types.map(item => item.label)}
                selectedOption={type}
                onChange={(value) => {
                  const sel = Types.filter(c => c.label == value)
                  setType(value)
                  setClubFields({ ...clubFields, type: sel[0]?.value })
                }}
                placeholder="Type"
              />
            </SelectBoxDropdown>
          </>
          <InputComponent
            title="Enter name" placeholder="Name" name="Name" type="text" value={clubFields.name}
            onChange={(e) => setClubFields({ ...clubFields, name: e.target.value })}
          />
          <>
            <TitleField>{"Select kind of sport"}</TitleField>
            <SelectBoxDropdown>
              <CheckboxGroup options={sports.map(item => item.value)} value={clubFields.sport}
                onChange={(value) => setClubFields({ ...clubFields, sport: value })}
              />
            </SelectBoxDropdown>
          </>
          <InputComponent
            title="Website" placeholder="Website" name="link" type="text" value={clubFields.link}
            onChange={(e) => setClubFields({ ...clubFields, link: e.target.value })}
          />
          <InputComponent
            title="Enter description" placeholder="Description" name="description" type="textArea" value={clubFields.description}
            onChange={(e) => setClubFields({ ...clubFields, description: e.target.value })}
          />
          <ProfileBox>
            <h1>Enter your HighSchool or Club logo</h1>
            <p>The file must be PNG, and no more than 3 MB</p>
          </ProfileBox>
          <DropDownContainer>
            <DropFileStyle {...getRootProps()}>
              <input
                type="url"
                placeholder="Enter media to upload to IPFS"
                {...getInputProps()}
              />
              <p>Drop files here</p>
              <AttachContainer>
                <img src={attachSvg} alt="attach" />
                <span>Attach file</span>
              </AttachContainer>
              <AsideContainer>
                {thumbs}
              </AsideContainer>
            </DropFileStyle>
          </DropDownContainer>
        </div>
        <ButtonGroup>
          <StepButton name="back" onClick={() => closeSidebar()}>Back</StepButton>
          <StepButton onClick={onAcceptHanlder}>Update</StepButton>
        </ButtonGroup>
      </OuterContainer>
      <CloseButton onClick={closeSidebar}><CloseOutlined /></CloseButton>
    </SidebarContainer>
  )
}
