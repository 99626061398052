import React, { useState, useEffect } from "react"
import { Title, StepButton, ClubContainer, ClubItem, ButtonGroup } from "./AuthStyling"
import { SelectBoxDropdown, } from "screens/Admin/styles/CreateNFTStyles"
import SelectBox from "components/SelectBox"
import InputComponent from "./InputComponent"
import { useMoralis } from "react-moralis"
import { Loading } from "notiflix"
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { toast } from "react-hot-toast"

const Club = ({ clubList, title, onClickHandler, onBackHandler }) => {
    const { Moralis, user } = useMoralis()
    const [club, setClub] = useState("")
    const [clubs, setClubs] = useState([])
    const [email, setEmail] = useState("")
    const [existAdmin, setExistAdmin] = useState(false)

    useEffect(() => {
        if (user) {
            const res = clubList.filter(item => item.id == user?.attributes.club)[0]?.attributes.name
            let results = clubList
            if (user?.attributes.role == "hs_adm" || user?.attributes.role == "hs_ath") {
                results = clubList.filter(item => item.attributes.type == "school" || item.attributes.type == "all")
            } else if (user?.attributes.role == "sc_adm" || user?.attributes.role == "sc_ath") {
                results = clubList.filter(item => item.attributes.type == "club" || item.attributes.type == "all")
            }
            setClub(res)
            setClubs(results)
        }
    }, [user, clubList])

    const onAcceptHanlder = async () => {
        const res = clubList.filter(item => item.attributes.name == club)[0]?.id
        if (res != 0) {
            Loading.standard();
            try {
                user.set("club", res);
                await user.save();
                Loading.remove();
                onClickHandler();
            } catch (error) {
                Loading.remove();
                Modal.error({
                    icon: <ExclamationCircleOutlined />,
                    content: error.data ? error.data.message : error.message,
                })
            }
        } else {
            Loading.standard()
            try {
                await Moralis.Cloud.run("forClubRequest", {
                    address: user?.attributes.ethAddress, email: email
                })
                Loading.remove()
                toast.success("Your request has been successfully sent.")
                window.location.replace("/nftMarket")
            } catch (error) {
                Loading.remove()
                toast.error("Failed to send the request.")
            }
        }
    }

    const onSelectClubHandler = async (value) => {
        const clubId = clubList.filter(item => item.attributes.name == value)[0]?.id
        setClub(value)
        if (clubId != 0 && (user?.attributes.role == "hs_adm" || user?.attributes.role == "sc_adm")) {
            Loading.standard()
            try {
                const checkClubAdmin = await Moralis.Cloud.run("checkClubAdmin", { club: clubId })
                if (checkClubAdmin) {
                    setExistAdmin(true)
                    toast.error("There is already an admin in this club")
                } else {
                    setExistAdmin(false)
                }
            } catch (error) {
                console.log(error)
            }
            Loading.remove()
        }
    }

    return (
        <>
            <div>
                <Title>{title}</Title>
                <SelectBoxDropdown>
                    <SelectBox
                        options={clubs?.map(item => item?.attributes.name)}
                        selectedOption={club}
                        onChange={(value) => onSelectClubHandler(value)}
                        placeholder="Type"
                    />
                </SelectBoxDropdown>
                {clubs.filter(item => item.attributes.name == club)[0]?.id == 0 &&
                    <InputComponent title="Email" placeholder="Email" name="email" type="text" onChange={(e) => setEmail(e.target.value)} />
                }
                <ClubContainer>
                    <img src={clubs.filter(item => item.attributes.name == club)[0]?.attributes.icon} alt="icon" />
                    <p>{clubs.filter(item => item.attributes.name == club)[0]?.attributes.description}</p>
                </ClubContainer>
            </div>
            <ButtonGroup>
                <StepButton name="back" onClick={onBackHandler}>Back</StepButton>
                {!existAdmin && <StepButton onClick={onAcceptHanlder}>{clubs.filter(item => item.attributes.name == club)[0]?.id == 0 ? "Complete" : "Next"}</StepButton>}
            </ButtonGroup>
        </>
    )
}

export default Club
