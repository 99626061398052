import Carousel from "react-grid-carousel"
import {
    Button,
    Col,
    Input,
    Image,
    Row,
    Space,
    Tabs,
    Typography,
    Layout,
    Card,
    Drawer,
    Upload,
} from "antd"
import { PlusCircleOutlined, CloseOutlined, PlusSquareOutlined, MinusSquareOutlined, ClockCircleOutlined, PushpinOutlined } from "@ant-design/icons"

import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { useMedia } from "hooks/useMedia"
import "./style.css"
import { BackButton, ArrowBtn } from "./components/basic"
import { PlayerCard, PlayerStats, PlayerStat } from "./components/playcard"
import {
    CssDivs,
    CssDiv,
} from "components/CssStyledComponent/CssStyledComponent"
import Item from "antd/lib/list/Item"
import { SearchOutlined } from "@ant-design/icons"
import { tokenURI, updateTokenURI } from "utils/helpers/ylnft721"
import { tokenURI as tokenURI1155, burnBatch, setApprovalForAll } from "utils/helpers/ylnft1155"
import axios from "axios"
import { Loading } from "notiflix"
import { revertNftFromVaultToWalletERC721, revertNftFromVaultToWalletERC1155 } from "utils/helpers/subvault"
import { useMoralisWeb3Api, useMoralis } from "react-moralis"
import { depositeERC1155toSubVault } from "utils/helpers/ylvault"
import { approve } from "utils/helpers/ylnft721"
import { approve as approveYLT } from "utils/helpers/ylt"
import { userRoles } from "utils/dummyData"

const priceList = [
    {
        id: "gold",
        name: "#1",
        price: "100000",
        bgImg: "url(/price_bg_1.png)",
    },
    {
        id: "silver",
        name: "#2",
        price: "50000",
        bgImg: "url(/price_bg_2.png)",
    },
    {
        id: "copper",
        name: "#3",
        price: "15000",
        bgImg: "url(/price_bg_3.png)",
    },
]

const tabList = [
    {
        label: "12 Hours",
        price: 15000,
    },
    {
        label: "24 Hours",
        price: 30000,
    },
    {
        label: "3 DAYS",
        price: 45000,
    },
    {
        label: "WEEK",
        price: 90000,
    },
]

const TOKEN_ADDRESS = [
    process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS,
    process.env.REACT_APP_YLNFT1155_CONTRACT_ADDRESS,
]

const AdsDetail = ({ moralis }) => {
    let inputRef
    const history = useHistory()
    const location = useLocation()
    const Web3Api = useMoralisWeb3Api()
    const { user, isAuthenticated, Moralis, web3 } = useMoralis()
    const isMobile = useMedia("(max-width: 640px)")
    const params = useParams()
    const [open, setOpen] = useState(false)
    const [notifyOpen, setNotifyOpen] = useState("")
    const [loaded, setLoaded] = useState(false)
    const [type, setType] = useState("baseball")
    const [teamPower, setTeamPower] = useState({
        power: 0,
        speed: 0,
        energy: 0,
        luck: 50,
        wizzardy: 50,
    })
    const [players, setPlayers] = useState([])
    const [teamInfo, setTeamInfo] = useState({
        logo: "/images/vaultteam/YLT_teamlogo.png",
        subaddress: "",
        address: "",
        category: "",
        gamer: "",
        from: "",
        createdAt: "",
        teamname: "",
    })

    const [sideContents, setSideContents] = useState("get reward")
    const [modalInfo, setModalInfo] = useState(null)
    const [delIndex, setDelIndex] = useState(0)
    const [addlist, setAddlist] = useState([])
    const [erc721, setErc721] = useState([])
    const [addBstlist, setAddBstlist] = useState([])
    const [delBstlist, setDelBstlist] = useState([])
    const [erc1155, setErc1155] = useState([])
    const [teamBooster, setTeamBooster] = useState([])

    const openModal = (a, info) => {
        setModalInfo(info)
        setSideContents(a)
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    const readTeams = useCallback(
        async (vault) => {
            Loading.standard()
            try {
                const v = await moralis?.fn.Cloud.run("getOneTeamData", {
                    vault: vault,
                })
                var tb = [];
                var bstList = []
                for (var k = 0; k < v.data_booster.length; k++) {
                    const attr = v.data_booster[k].attributes
                    const metaUri = await moralis?.fn.executeFunction(
                        tokenURI1155(attr.nftID),
                    )
                    const res = await axios.get(metaUri)
                    const meta = res.data
                    const n = {
                        vaultaddress: attr.vault,
                        subaddress: attr.vault,
                        gamer: attr.gamer,
                        tokenId: attr.nftID,
                        meta: meta,
                        amount: attr.amount
                    }
                    if (n.amount > 0) {
                        tb.push(n)
                        const bst = {
                            id: attr.nftID,
                            amount: 0,
                            address: attr.vault
                        }
                        bstList.push(bst)
                    }
                }
                setDelBstlist(bstList)
                setTeamBooster(tb)

                const attr = v.data_team.attributes
                const t = {
                    logo: attr.logo,
                    subaddress: attr.subaddress,
                    address: attr.address,
                    category: attr.category,
                    gamer: attr.gamer,
                    from: attr.from,
                    createdAt: attr.createdAt,
                    teamname: attr.teamname,
                }
                setTeamInfo(t)
                readNfts(attr.category)
                Loading.remove()
            } catch (e) {
                console.log(e)
                Loading.remove()
            }
        },
        [moralis],
    )

    useEffect(() => {
        if (params.type != null && params.type != undefined) {
            setType(params.type)
        }
        if (
            params.address != null &&
            params.address != undefined &&
            moralis != null &&
            loaded == false
        ) {
            setLoaded(true)
            readTeams(params.address)
        }
    }, [params.type, moralis])

    const readNfts = async (category) => {
        if (isAuthenticated) {
            const options = {
                chain: "bsc testnet",
                token_addresses: TOKEN_ADDRESS,
                address: user?.attributes.ethAddress,
            }
            Web3Api.account.getNFTs(options).then(async (nfts) => {
                nfts = nfts?.result.filter((nft) => nft.token_uri)
                const mintedNFT = await Promise.all(
                    nfts.map(async (item) => {
                        const metaTokenUri =
                            item?.token_uri.substring(8, 12) == "ipfs"
                                ? item.token_uri.replace(/^.{28}/g, "https://gateway.moralisipfs.com",)
                                : item.token_uri
                        const meta = await axios.get(metaTokenUri)
                        const imgSrc = meta.data.image

                        const price = "1 BNB"
                        const dprice = "300"
                        const nft = {
                            id: item.token_id,
                            imgSrc: imgSrc,
                            sport: meta.data.sport,
                            title: meta.data.name,
                            price: price, //BNB
                            priceUSD: dprice,
                            brickColor: "blue",
                            address: item.token_address,
                            type: item.contract_type,
                            meta: meta.data,
                            amount: item.amount
                        }
                        return nft
                    }),
                )
                var tmp1155 = []
                var bstList = []
                mintedNFT.forEach((n) => {
                    if (n.type != "ERC721") {
                        if (n.meta.boosterType == "Advertiser Space") {
                            tmp1155.push(n)
                            const bst = {
                                id: n.id,
                                amount: 0
                            }
                            bstList.push(bst)
                        }
                    }
                }) 
               
                setErc1155(tmp1155)
                setAddBstlist(bstList)

            })
        }
    }

    const onTabChange = (key) => {
        console.log(key)
    }

    const fileUpload = async (event) => {
        const data = event
        const image_ipfs = new Moralis.File(data.name, data)
        const img = await image_ipfs.saveIPFS()
        const image = "https://gateway.moralisipfs.com/ipfs/" + img._hash
    }

    const removeItem = (e, index) => {
        if (e != undefined) {
            setDelIndex(index)
            openModal("remove a player", e)
        }
    }
    const openinfo = (e) => {
        if (e != undefined) {
            openModal("playerinfo", e)
        }
    }

    const boosterPlus = (item, mode) => {
        const id = item.id
        var bstList = addBstlist
        bstList.forEach((it, index) => {
            if (it.id == id) {
                if (mode == 0) {
                    if (bstList[index].amount < item.amount) {
                        bstList[index].amount++;
                    }
                } else {
                    if (bstList[index].amount > 0) {
                        bstList[index].amount--;
                    }
                }
            }
        })
        setAddBstlist([...bstList])
    }

    const boosterMinus = (item, mode) => {
        const id = item.tokenId
        var bstList = delBstlist
        bstList.forEach((it, index) => {
            if (it.id == id) {
                if (mode == 0) {
                    if (bstList[index].amount < item.amount) {
                        bstList[index].amount++;
                    }
                } else {
                    if (bstList[index].amount > 0) {
                        bstList[index].amount--;
                    }
                }
            }
        })
        setDelBstlist([...bstList])
    }

    const addBooster = async () => {

        try {
            Loading.standard()
            const tr_approve = await Moralis.executeFunction(
                setApprovalForAll(
                    process.env.REACT_APP_YLVAULT_CONTRACT_ADDRESS,
                    true
                ),
            )
            await tr_approve.wait()

            for (var i = 0; i < addBstlist.length; i++) {
                if (addBstlist[i].amount > 0) {
                    const tr = await Moralis.executeFunction(
                        depositeERC1155toSubVault(
                            addBstlist[i].id,
                            addBstlist[i].amount,
                            teamInfo.category
                        ),
                    )
                    await tr.wait()
                }
            }
            Loading.remove()
            window.location.reload()
        } catch (e) {
            Loading.remove()
            console.log(e)
        }
    }

    const beautyTime = (s) => {
        return s.substr(0, 10)
    }



    return (
        <>
            <Layout className="layout team-detail">
                <Row justify="start">
                    <Col span={24}>
                        <BackButton onClick={() => history.goBack()}>Previous</BackButton>
                        <input
                            ref={(refParam) => (inputRef = refParam)}
                            type="file"
                            onChange={(e) => fileUpload(e.target.files[0])}
                            style={{ display: "none" }}
                        />
                    </Col>
                </Row>
                <Row justify="start" align="middle" className="maring-top-20">
                    <Col xs={24} md={24} lg={24} className="align-center">
                        <Row type="flex" align="middle" justify="center">
                            <img
                                className="team-detail-logo margin-right-10 ads-logo"
                                src={teamInfo.logo}
                                alt="YTL_team_logo"
                            />
                            <h1 className="team-title">{type}</h1>
                        </Row>
                    </Col>
                </Row>
                <Row type="flex" className="maring-top-20">
                    <Col sm={24} md={24} lg={24} className="d-flex overflowX">
                        <div className="player-card-wrap-white">
                            <p className="wrap-title">{teamInfo.category}</p>
                            <div className="d-flex">
                                {teamBooster.length > 0 && teamBooster.map((item, index) => {
                                    return (
                                        <div key={index} className="trainer-item-b pos-rel ads-item-wrap">
                                            <img
                                                className="booster-img "
                                                src={item.meta.image}
                                                alt="playerImage"
                                            />
                                            <div className="reward-btn-wrap ads-info">
                                                <p><PushpinOutlined className="margin-right-5" />{item.meta.impPage} / {item.meta.impPos}</p>
                                                <p><ClockCircleOutlined className="margin-right-5" />{beautyTime(item.meta.impStart)} ~ {beautyTime(item.meta.impEnd)}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="h-tap">
                                    <Card
                                        bordered={true}
                                        className="add-card"
                                        onClick={() => openModal("booster", "")}
                                    >
                                        <PlusCircleOutlined className="logo-add" />
                                        <div className="add-logo maring-top-10">add booster</div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Layout>

            <Drawer
                placement={"right"}
                width={isMobile ? "90%" : "35%"}
                closable={false}
                // onClose={onClose}
                open={open}
                className="team-drawer"
            >
                <Row justify="center" align="middle">
                    <Col xs={24} className="close-btn-wrap">
                        <CloseOutlined
                            className="close-btn"
                            onClick={() => setOpen(false)}
                        />
                    </Col>
                    {notifyOpen == "" ? (
                        <Col xs={24} md={24}>
                            {/* <h1 className="drawer-title">{sideContents}</h1> */}
                        </Col>
                    ) : (
                        <></>
                    )}

                    {sideContents == "booster" && (
                        <>
                            <Col xs={24} md={24}>
                                <h1 className="drawer-title">Your own boosters</h1>
                            </Col>
                            <Col xs={24} md={24}>
                                <Typography className="team-name">Boosters applied for Ads</Typography>
                            </Col>
                            <Col xs={24} md={24} className="d-flex overflowX maring-top-10">
                                {teamBooster.length > 0 && teamBooster.map((item, index) => {
                                    return (
                                        <div key={index} className="trainer-item-b pos-rel">
                                            <img
                                                className="booster-img "
                                                src={item.meta.image}
                                                alt="playerImage"
                                            />
                                            <div className="booster-info">
                                                {item.amount}
                                            </div>
                                            <div className="reward-btn-wrap ads-info">
                                                <p><PushpinOutlined className="margin-right-5" />{item.meta.impPage} / {item.meta.impPos}</p>
                                                <p><ClockCircleOutlined className="margin-right-5" />{beautyTime(item.meta.impStart)} ~ {beautyTime(item.meta.impEnd)}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Col>
                            <Col xs={24} md={24} className="align-left maring-top-10">
                                <Typography className="">Boosters will be burnd or not effect for Ads display if the lifetime is endded.</Typography>
                            </Col>

                            <Col xs={24} md={24} className="maring-top-10">
                                <Typography className="team-name">Boosters in your wallet</Typography>
                            </Col>
                            <Col xs={24} md={24} className="d-flex overflowX maring-top-10">
                                {erc1155.length > 0 && erc1155.map((item, index) => {
                                    return (
                                        <div key={index} className="trainer-item-b pos-rel">
                                            <img
                                                className="booster-img "
                                                src={item.imgSrc}
                                                alt="playerImage"
                                            />
                                            <div className="booster-info">
                                                {addBstlist[index].amount}
                                                /
                                                {item.amount}
                                            </div>
                                            <div className="booster-plus">
                                                <PlusSquareOutlined className="booster-btn"
                                                    onClick={() => boosterPlus(item, 0)}
                                                />
                                                <MinusSquareOutlined className="booster-btn"
                                                    onClick={() => boosterPlus(item, 1)}
                                                />
                                            </div>
                                            <div className="reward-btn-wrap ads-info">
                                                <p><PushpinOutlined className="margin-right-5" />{item.meta.impPage} / {item.meta.impPos}</p>
                                                <p><ClockCircleOutlined className="margin-right-5" />{beautyTime(item.meta.impStart)} ~ {beautyTime(item.meta.impEnd)}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Col>
                            <Col xs={24} md={24} className="align-left maring-top-10">
                                <Typography className="">If you apply boosters for Ads, those NFT1155s will go to your Ads from your wallet and can display your Ads based on NFTs property</Typography>
                            </Col>
                            <Col xs={24} md={24} className="align-left maring-top-10">
                                <Row gutter={10}>
                                    <Col xs={24} sm={24} md={24}>
                                        <Button type="primary" className="primary-btn" block
                                            onClick={() => addBooster()}
                                        >
                                            Apply boosters for this Ads
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}

                </Row>
            </Drawer>
        </>
    )
}

export default AdsDetail
