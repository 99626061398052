import { ABI } from "../abi/contest.js"
const ENV_CONTRACT_ADDRESS = process.env.REACT_APP_YLCONTEST_CONTRACT_ADDRESS


export const activeMatch = (_category, subvault) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "activeMatch",
    abi: ABI,
    params: { 
      _category: _category,
      subvault: subvault,
    },
  }
}

export const createMatchID = (_player1, _player2, _category, tournamentID) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "createMatchID",
    abi: ABI,
    params: {
      _player1: _player1,
      _player2: _player2,
      _category: _category,
      tournamentID,
    },
  }
}

export const play = (
  wallet1,
  score1,
  wallet2,
  score2,
  matchid,
  category,
  tournanmentID,
) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "play",
    abi: ABI,
    params: {
      _player1: wallet1,
      _score1: score1,
      _player2: wallet2,
      _score2: score2,
      _matchID: matchid,
      _category: category,
      _tournamentID: tournanmentID,
    },
  }
}

export const createTournament = (_category, _maxPlayers, _tournamentFee, _uri) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "createTournament",
    abi: ABI,
    params: {
      _category,
      _maxPlayers,
      _tournamentFee,
      _uri,
    },
  }
}

export const payTournamentFee = (_tournamentID) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "payTournamentFee",
    abi: ABI,
    params: {
      _tournamentID,
    },
  }
}



export const setMinStakedPlay = (_amount) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "setMinStakedPlay",
    abi: ABI,
    params: {
      _amount,
    },
  }
}


export const isElegible = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "isElegible",
    abi: ABI,
    params: {
      _player: walletAddress,
    },
  }
}

export const getTournamentFee = (tournanmentID) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getTournamentFee",
    abi: ABI,
    params: {
      _tournanmentID: tournanmentID,
    },
  }
}

export const getMatch = (category, matchId) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getMatch",
    abi: ABI,
    params: {
      _category: category,
      _matchId: matchId,
    },
  }
}

export const getPlayerRecord = (walletAddress, decision) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getPlayerRecord",
    abi: ABI,
    params: {
      _player: walletAddress,
      _decision: decision,
    },
  }
}

export const getTournament = (_tournamentID) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getTournament",
    abi: ABI,
    params: {
      _tournamentID
    },
  }
}

export const getRound = (_tournamentID, _player) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getRound",
    abi: ABI,
    params: {
      _tournamentID,
      _player,
    },
  }
}

export const roundPlayed = (_tournamentID, _round, _player) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "roundPlayed",
    abi: ABI,
    params: {
      _tournamentID,
      _round,
      _player,
    },
  }
}




// export const setTournamentFee = (tournanmentID, fee) => {
//   return {
//     contractAddress: ENV_CONTRACT_ADDRESS,
//     functionName: "setTournamentFee",
//     abi: ABI,
//     params: {
//       _tournanmentID: tournanmentID,
//       _fee: fee,
//     },
//   }
// }

export const withdrawFunds = (walletAddress, amount) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "withdrawFunds",
    abi: ABI,
    params: {
      _to: walletAddress,
      _amount: amount,
    },
  }
}






