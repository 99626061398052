import { SellNFT } from "components/Sidebar/SellNFT"
import { EditNFT } from "components/Sidebar/EditNFT"
import { AddIn } from "components/Sidebar/AddIn"
import { DappContext } from "context"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  ActionBtn,
  ButtonContainer,
  ColorBrick,
  ColorBrickContainer,
  GeometricCard,
  Graphics,
  IconImg,
  MainCardContainer,
  NameBlock,
  PlayerImage,
  PlayerName,
  PlayerStats,
  StatItem,
  TextBrick,
  TextBrickContainer,
  TopHeaderText,
  USDValue,
  ValueWrapper,
  WeightText,
} from "./styles/NftCardStyling"
import { adminPauseToggle } from "utils/helpers/marketplace"
import { finishAuction } from "utils/helpers/marketplace1155"
import { burnNFT721 } from "utils/helpers/ylnft721"
import { burnBatch } from "utils/helpers/ylnft1155"
import { useMoralis } from "react-moralis"
import { Loading } from "notiflix"
import { Modal, Tag } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

export const PlayerStat = ({ iconType, weight }) => {
  return (
    <StatItem>
      <IconImg
        src={require(`../../images/account/collection/${iconType}.svg`).default}
      />
      <WeightText>{weight}</WeightText>
    </StatItem>
  )
}

export const NftCard = ({ nft, moralis, }) => {
  const { user } = useMoralis()
  const [isSell, setIsSell] = useState(false)
  const [isOffer, setIsOffer] = useState(false)
  const { onCloseSidebar, setSidebarContent, setOpenSidebar } = useContext(DappContext)

  const handleSell = () => {
    const options = nft
    setSidebarContent(
      <SellNFT options={options} closeSidebar={onCloseSidebar} />,
    )
    setOpenSidebar(true)
  }

  const handleEdit = () => {
    const options = nft
    setSidebarContent(
      <EditNFT options={options} closeSidebar={onCloseSidebar} />,
    )
    setOpenSidebar(true)
  }

  const handleClickAddIn = (event) => {
    const options = nft
    setSidebarContent(
      <AddIn options={options} moralis={moralis} closeSidebar={onCloseSidebar} />,
    )
    setOpenSidebar(true)
  }

  const handleBurn = async (event) => {
    Loading.standard()
    const ethAddress = user?.attributes.ethAddress
    try {
      let transaction
      if (nft.isERC721) {
        transaction = await moralis?.fn.executeFunction(burnNFT721(nft.id))
        await transaction.wait()
      } else {
        transaction = await moralis?.fn.executeFunction(burnBatch(ethAddress, nft.id, nft.amount))
        await transaction.wait()
      }
      location.reload()
    } catch (error) {
      console.log(error)
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }

  const handleRemoveFromOffer = () => {
    const value = confirm("Are you really remove item from Offer Card?")
    if (value) {
      moralis?.fn.Cloud.run("removeItemFromOffers", { isERC721: nft.isERC721, id: nft.id, amount: nft.amount }).then(
        () => {
          location.reload()
        },
      )
    }
  }

  const handleRemoveFromBundle = () => {
    const value = confirm("Are you really remove item from Bundle Card?")
    if (value) {
      moralis?.fn.Cloud.run("removeItemFromBundles", { isERC721: nft.isERC721, id: nft.id, amount: nft.amount }).then(
        () => {
          location.reload()
        },
      )
    }
  }

  const handlePause = async () => {
    Loading.standard()
    try {
      // await moralis?.fn.executeFunction(adminPauseToggle(nft.itemId, true))
      const type = nft.isERC721 ? "NFT721" : "NFT1155"
      const itemId = nft.itemId
      const status = true
      await moralis?.fn.Cloud.run("HandlePauseToggle", { type, itemId, status })
      location.reload()
    } catch (error) {
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
    Loading.remove()
  }

  const handleFinish = async () => {
    Loading.standard()
    try {
      await moralis?.fn.executeFunction(finishAuction(nft.itemId))
      location.reload()
    } catch (error) {
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
    Loading.remove()
  }

  const handleUnPause = async () => {
    Loading.standard()
    try {
      // await moralis?.fn.executeFunction(adminPauseToggle(nft.itemId, false))
      const type = nft.isERC721 ? "NFT721" : "NFT1155"
      const itemId = nft.itemId
      const status = false
      await moralis?.fn.Cloud.run("HandlePauseToggle", { type, itemId, status })
      location.reload()
    } catch (error) {
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
    Loading.remove()
  }

  useEffect(() => {
    if (nft.status) {
      setIsSell(nft.status !== "NOT LISTED")
      setIsOffer(nft.status === "Offer")
    }
  }, [nft.status])

  return (
    <MainCardContainer>
      <GeometricCard>
        <TopHeaderText isSell={isSell}>{nft.status}</TopHeaderText>
        <Graphics>
          {nft.personal !== null && (
            <>
              <PlayerStats>
                <PlayerStat
                  iconType="Run"
                  weight={nft.personal.speed}
                />
                <PlayerStat
                  iconType="Wheel"
                  weight={nft.personal.dexterity}
                />
                <PlayerStat
                  iconType="Stamina"
                  weight={nft.personal.stamina}
                />
              </PlayerStats>
            </>
          )}
          <Link to={`/admin/mintedNfts/${nft.owner}/${nft.address}/${nft.id}`}>
            <PlayerImage src={nft.imgSrc} alt="playerImage" />
          </Link>
          {nft.personal !== null && (
            <>
              <PlayerStats>
                <PlayerStat
                  iconType="Run"
                  weight={nft.personal.dribbling}
                />
                <PlayerStat
                  iconType="Wheel"
                  weight={nft.personal.finishing}
                />
              </PlayerStats>
            </>
          )}
        </Graphics>
        <NameBlock>
          <PlayerName>{nft.title}</PlayerName>
          {nft.status == "Auction" && (
            <>
              <ValueWrapper>
                {`INSTANT BUYOUT - $ ${nft.priceUSD}`}&nbsp;
                <USDValue>{`(${nft.price} YLT)`}</USDValue>
              </ValueWrapper>
              <ValueWrapper>
                {`CURRENT BID - $ ${nft.priceUSD}`}&nbsp;
                <USDValue>{`(${nft.price} YLT)`}</USDValue>
              </ValueWrapper>
            </>
          )}
          {nft.status != "Auction" && (
            <>
              <ValueWrapper>
                {`PRICE: $ ${nft.priceUSD}`}&nbsp;
                <USDValue>{` (${nft.price} YLT)`}</USDValue>
              </ValueWrapper>
              {!nft.isERC721 &&
                <ValueWrapper>
                  {`TOTAL PRICE: $ ${nft.priceUSD * nft.amount}`}&nbsp;
                  <USDValue>{` (${nft.price * nft.amount} YLT)`}</USDValue>
                </ValueWrapper>
              }
            </>
          )}
          {!nft.isERC721 &&
            <ValueWrapper>
              {`NFT QTY: ${nft.amount}`}
            </ValueWrapper>
          }
          {nft.meta.boostedCharact &&
            <ValueWrapper style={{ flexWrap: "wrap" }}>
              {(nft.meta.boostedCharact == "All" || nft.meta.boostedCharact == "Speed") &&
                <Tag color="processing" style={{ margin: "5px" }}>{`Speed: +${nft.meta.boostedAmount}%`}</Tag>}
              {(nft.meta.boostedCharact == "All" || nft.meta.boostedCharact == "Energy") &&
                <Tag color="red" style={{ margin: "5px" }}>{`Energy: +${nft.meta.boostedAmount}%`}</Tag>}
              {(nft.meta.boostedCharact == "All" || nft.meta.boostedCharact == "Power") &&
                <Tag color="gold" style={{ margin: "5px" }}>{`Power: +${nft.meta.boostedAmount}%`}</Tag>}
              {(nft.meta.boostedCharact == "All" || nft.meta.boostedCharact == "Jumping") &&
                <Tag color="purple" style={{ margin: "5px" }}>{`Jumping: +${nft.meta.boostedAmount}%`}</Tag>}
            </ValueWrapper>
          }
        </NameBlock>
        <ColorBrickContainer>
          <ColorBrick color={nft.brickColor} />
        </ColorBrickContainer>
        <TextBrickContainer>
          <TextBrick>{nft.sport}</TextBrick>
        </TextBrickContainer>
        {nft.action[0] !== "" ?
          <>
            <ButtonContainer isSell={isSell}>
              {
                nft.action.map((action) => {
                  return (
                    <ActionBtn
                      onClick={
                        action == "EDIT"
                          ? handleEdit
                          : action == "SELL"
                            ? handleSell
                            : action == "ADD IN"
                              ? handleClickAddIn
                              : action == "BURN"
                                ? handleBurn
                                : action == "PAUSE"
                                  ? handlePause
                                  : action == "UNPAUSE" && handleUnPause

                      }
                      action={action}
                    >
                      {action}
                    </ActionBtn>
                  )
                })
              }
            </ButtonContainer>
          </> :
          <>
            <ButtonContainer>
              <ActionBtn isOffer onClick={nft.action[1] == "REMOVE" ? nft.type == "offer" ? handleRemoveFromOffer : handleRemoveFromBundle : handleFinish} action={nft.action[1]}>
                {nft.action[1]}
              </ActionBtn>
            </ButtonContainer>
          </>
        }
      </GeometricCard>
    </MainCardContainer >
  )
}
