import React, { useEffect, useState, useRef, useCallback } from "react"
import { FriendRow } from "./FriendRow"
import {
  AccountAchievementStyling,
  AllAchievements,
  Badge,
  BadgeIcon,
  BadgeText,
  BgBadge,
  FriendsContainer,
  FriendsTitle,
} from "./styles/AccountAchievementStyling"
import { useMoralis } from "react-moralis"
import { Loading } from "notiflix"

function AccountAchievements() {
  const { Moralis, user } = useMoralis()
  const [onceLoad, setOnceLoad] = useState(false)
  const [athletes, setAthletes] = useState([])

  const fetchAthlete = useCallback(async () => {
    if (user) {
      Loading.standard()
      const data = await Moralis.Cloud.run("getAthlete", { club: user.attributes.club, role: user.attributes.role, type: "manage" })
      let arr = data.filter((elem) => elem.attributes.ethAddress && user.attributes.ethAddress !== elem.attributes.ethAddress)
      Loading.remove()
      setAthletes(arr)
    }
  }, [user])

  useEffect(() => {
    if (Moralis && !onceLoad) {
      fetchAthlete()
      setOnceLoad(true)
    }
  }, [Moralis, onceLoad])

  return (
    <AccountAchievementStyling>
      {/* Badge */}
      <Badge>
        <BadgeIcon
          src={require("../../../images/account/best_forward.png").default}
          alt="badgeIcon"
        />
        <BadgeText>best forward</BadgeText>
      </Badge>

      {/* Achievements */}
      <AllAchievements>All Achievements</AllAchievements>

      {/* Friends */}
      <FriendsContainer>
        <FriendsTitle>friends</FriendsTitle>
        <hr color="#646464" />
        {
          athletes.map((athlete, index) => {
            return <FriendRow key={index} user={athlete} icons />
          })

        }
      </FriendsContainer>
    </AccountAchievementStyling>
  )
}

export default AccountAchievements
