import { Row, Col, Avatar, Divider, Typography, Modal, Spin } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import React, { useEffect, useState, useCallback } from "react"
import {
  BalanceButton,
  BalanceContainer,
  BalanceInCont,
  BalanceInnerContainer,
  WidthSpaceBetween,
  BalanceTitle,
  BalanceValue,
  TokenImage,
} from "../styles/BalanceStyling"
import whiteLabel from "images/yourlife_back_white.png"
import { useMoralis, useMoralisWeb3Api } from "react-moralis"
import { getStakedAmount, getEscrowAmount, withdrawEscrow } from "utils/helpers/proxy"
import { getBalanceOf } from "utils/helpers/ylt"
import { useSwapRate } from "hooks/useSwapRate"
import { Loading } from "notiflix"

export const BalanceInfo = ({
  suffAmount,
  isdeposit,
  address,
  isSuperPage,
}) => {
  const [stackedYLTBalance, setStakedYLTBalance] = useState(null)
  const [comissonBalance, setComissionBalance] = useState(null)
  const [yltBalance, setYLTBalance] = useState(0)
  const { Moralis, isWeb3Enabled, isAuthenticated, user } = useMoralis()
  const Web3Api = useMoralisWeb3Api()
  const rate = useSwapRate()

  const memoizedCallback = useCallback(async () => {
    if (user) {
      const bal = await Web3Api.account.getTokenBalances({
        address: user?.attributes.ethAddress,
        chain: "bsc testnet",
        token_addresses: [process.env.REACT_APP_YLT_CONTRACT_ADDRESS],
      })
      if (bal[0]?.balance) {
        setYLTBalance(Math.floor(Moralis.Units.FromWei(bal[0].balance)))
      }
    }
  }, [Web3Api.account, user, Moralis.Units])

  useEffect(() => {
    memoizedCallback()
  }, [memoizedCallback])

  const getStakedYLTBalance = useCallback(
    async (address) => {
      if (address) {
        if (!(isWeb3Enabled && isAuthenticated)) {
          await Moralis.enableWeb3()
        }
        let value
        try {
          value = !isSuperPage
            ? await Moralis.executeFunction(
              getStakedAmount(
                address,
                process.env.REACT_APP_YLT_CONTRACT_ADDRESS,
              ),
            )
            : await Moralis.executeFunction(
              getBalanceOf(process.env.REACT_APP_YLPROXY_CONTRACT_ADDRESS),
            )
          const com = await Moralis.executeFunction(getEscrowAmount(address))
          if (com) {
            setComissionBalance(Math.floor(Moralis.Units.FromWei(com)))
          }
        } catch (error) {
          Modal.error({
            icon: <ExclamationCircleOutlined />,
            // content: error.data ? error.data.message : error.message,
            content: "eerr",
          })
        }
        if (value) {
          setStakedYLTBalance(Math.floor(Moralis.Units.FromWei(value)))
        }
      }
    },
    [Moralis, isWeb3Enabled, isAuthenticated, isSuperPage],
  )

  const handleWithdraw = async () => {
    Loading.standard()
    try {
      const amount = Moralis.Units.Token(comissonBalance, "18")
      const transaction = await Moralis.executeFunction(withdrawEscrow(amount))
      await transaction.wait()
      location.reload()
    } catch (error) {
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
    Loading.remove()
  }

  useEffect(() => {
    getStakedYLTBalance(address)
    memoizedCallback()
  }, [isdeposit, address, getStakedYLTBalance, memoizedCallback])

  return (
    <BalanceContainer>
      <BalanceInCont>
        <Row gutter={[24, 16]}>
          <Col md={24} xl={12} xxl={8}>
            <BalanceTitle>(Proxy)</BalanceTitle>
            <BalanceInnerContainer>
              <TokenImage src={whiteLabel} />
              <BalanceValue>
                {stackedYLTBalance != null ? stackedYLTBalance : <Spin />}
              </BalanceValue>
            </BalanceInnerContainer>
          </Col>
          <Col md={24} xl={12} xxl={8}>
            <BalanceTitle>(Escrow)</BalanceTitle>
            <BalanceInnerContainer>
              <TokenImage src={whiteLabel} />
              <BalanceValue>
                {comissonBalance != null ? comissonBalance : <Spin />}
              </BalanceValue>
            </BalanceInnerContainer>
          </Col>
          <Col md={24} xl={24} xxl={8}>
            <BalanceTitle>(Wallet)</BalanceTitle>
            <BalanceInnerContainer>
              <TokenImage src={whiteLabel} />
              <BalanceValue>{yltBalance ? yltBalance : <Spin />}</BalanceValue>
            </BalanceInnerContainer>
          </Col>
          {!isSuperPage && <>
            <Divider
              type="horizontal"
              style={{
                opacity: 0.2,
                borderTopColor: "#fff",
                margin: "auto",
                width: "85%",
                minWidth: "85%",
                marginTop: "15px",
              }}
            />
            <Col xl={24} xxl={12}>
              <WidthSpaceBetween>
                <Typography.Text style={{ color: "white", opacity: "0.5" }}>
                  MININUM LIMIT
                </Typography.Text>
                <Typography.Text style={{ color: "white", fontSize: "19px" }}>
                  <Avatar
                    src={whiteLabel}
                    style={{ width: "1.5rem", height: "1.5rem" }}
                  />
                  {suffAmount}
                </Typography.Text>
              </WidthSpaceBetween>
            </Col>
            <Col xl={24} xxl={12} style={{ display: "flex" }}>
              <Divider
                type="vertical"
                style={{
                  borderLeftColor: "white",
                  height: "2rem",
                  opacity: 0.2,
                  margin: "auto",
                }}
              />
              <BalanceButton href="https://swapstaging.yourlifegames.com" target="_blank">
                Swap
              </BalanceButton>
            </Col>
          </>
          }
          {comissonBalance != null && comissonBalance > 0 && <BalanceButton onClick={handleWithdraw}>Withdraw Escrow</BalanceButton>}
        </Row>
      </BalanceInCont>
    </BalanceContainer>
  )
}