import React, { useState, useEffect, useCallback } from "react"
import { Table, Tag } from 'antd';
import { ToastContainer } from "react-toastify"
import { truncate } from "utils/formatAddress"
import { InfoContainer, Heading, RowFItemSub2, } from "./InfoElements"
import { tokenURI as getTotkenURI721 } from "utils/helpers/ylnft721"
import { tokenURI as getTotkenURI1155 } from "utils/helpers/ylnft1155"
import { useMoralis } from "react-moralis"
import { Loading } from "notiflix"
const { Column, ColumnGroup } = Table;

const Transfers = ({ moralis }) => {
  const { user } = useMoralis()
  const [onceLoad, setOnceload] = useState(false)
  const [transferData, setTranferData] = useState([])

  const fetchTransferData = useCallback(async () => {
    Loading.standard();
    const result = await moralis?.fn.Cloud.run("fetchTransfer", { address: user?.attributes.ethAddress })
    const { data_721, data_1155 } = result
    let result_721, result_1155 = []
    if (data_721) {
      result_721 = await Promise.all(
        data_721.map(async (item) => {
          try {
            const token_uri = await moralis?.fn.executeFunction(getTotkenURI721(item.attributes.tokenId),)
            const meta = await moralis?.fn.Cloud.run("fetchNFTMetadata", { url: token_uri, })
            return {
              isERC721: true,
              src: meta.data.image,
              name: meta.data.name,
              status: item.attributes.confirmed,
              meta: meta.data,
              price: 1,
              amount: 1,
              from: truncate(item.attributes.from),
              to: truncate(item.attributes.to),
              createdAt: item.attributes.createdAt,
              updatedAt: item.attributes.updatedAt,
            }
          } catch (error) {
            console.log(error)
          }
        }),
      )
    }
    if (data_1155) {
      result_1155 = await Promise.all(
        data_1155.map(async (item) => {
          try {
            const token_uri = await moralis?.fn.executeFunction(getTotkenURI1155(item.attributes.uid),)
            const meta = await moralis?.fn.Cloud.run("fetchNFTMetadata", { url: token_uri, })
            return {
              isERC721: false,
              src: meta.data.image,
              name: meta.data.name,
              status: item.attributes.confirmed,
              meta: meta.data,
              price: 1,
              amount: 1,
              from: truncate(item.attributes.from),
              to: truncate(item.attributes.to),
              createdAt: item.attributes.createdAt,
              updatedAt: item.attributes.updatedAt,
            }
          } catch (error) {
            console.log(error)
          }
        }),
      )
    }
    Loading.remove();
    setTranferData(result_1155.concat(result_721))
  }, [moralis])

  useEffect(() => {
    if (moralis && !onceLoad && user) {
      fetchTransferData()
      setOnceload(true)
    }
  }, [moralis, fetchTransferData, onceLoad, user])

  return (
    <>
      <InfoContainer>
        <Heading>TRANSFERS</Heading>
        <Table dataSource={transferData} scroll={{ x: 1300, }} >
          <Column title="ID" key="id" render={(_, record, index) => { ++index; return index; }} />
          <Column title="Image" key="icon" render={(_, record) => (<img src={record.src} style={{ height: "50px", marginRight: "10px" }} />)} />
          <Column title="Name" key="name" sorter={(a, b) => a.name.localeCompare(b.name)}
            render={(_, record) => (
              <div
                style={{
                  marginLeft: "1%",
                  alignSelf: "center",
                }}
              >
                <RowFItemSub2>{record.name}</RowFItemSub2>
                <Tag color={"processing"}> {record.meta.sport}</Tag>
              </div>)}
          />
          <Column title="NFT Type" key="type" render={(_, record) => {
            let nftType = record.isERC721
            let color = nftType ? 'volcano' : 'green';
            return <Tag color={color}>{nftType ? "ERC721" : "ERC1155"}</Tag>
          }}
            sorter={(a, b) => a.type.localeCompare(b.type)} />
          <Column title="From" key="from" render={(_, record) => (<p>{record.from}</p>)} />
          <Column title="To" key="to" render={(_, record) => (<p>{record.to}</p>)} />
          <Column title="QTY" key="qty" render={(_, record) => (<p>{record.amount}</p>)} />
          <Column title="CreateAt" key="createAt" sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
            render={(_, record) => {
              const startDate = new Date(record.createdAt)
              var res = startDate.toISOString().slice(0, 16).replace(/-/g, "-");
              return <p>{res}</p>
            }} />
          <Column title="UpdateAt" key="updateAt" sorter={(a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)}
            render={(_, record) => {
              const startDate = new Date(record.updatedAt)
              var res = startDate.toISOString().slice(0, 16).replace(/-/g, "-");
              return <p>{res}</p>
            }} />
        </Table>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{ backgroundColor: "#191919" }}
        />
      </InfoContainer>
    </>
  )
}

export default Transfers
