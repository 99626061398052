/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react"
import {
  BackBtn,
  MainContainer,
  MainInnerContainer,
  MakeAdminBtn,
  QuantityText,
  RightContainer,
  AdminToolbarContainer,
  SearchInput,
  Title,
  TopContainer,
} from "screens/Superadmin/styles/ManageAdminsStyling"
import { useMoralis } from "react-moralis"
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router"
import { AthleteCard } from "./components/AthleteCard"
import { Loading } from "notiflix"
import { userRoles } from "utils/dummyData"
import { setRoleByUser, setGroupAssign } from "utils/helpers/proxy"

export const ManageAthletes = ({ moralis }) => {
  const addressInput = useRef()
  const { user } = useMoralis()
  const [onceLoad, setOnceLoad] = useState(false)
  const [athletes, setAthletes] = useState([])
  const [isSort, setSort] = useState(true)
  const history = useHistory()

  const fetchAthlete = useCallback(async () => {
    if (user) {
      Loading.standard()
      const res = await moralis?.fn.Cloud.run("getAthlete", { club: user?.attributes.club, role: user?.attributes.role, type: "manage" })
      const filteredAthlete = res.filter((user) => !user.attributes.isSuperAdmin,)
      filteredAthlete.sort((a, b) => {
        return isSort
          ? b.attributes.createdAt - a.attributes.createdAt
          : a.attributes.createdAt - b.attributes.createdAt
      })
      Loading.remove()
      setAthletes(filteredAthlete)
    }
  }, [moralis, user, isSort])

  useEffect(() => {
    if (moralis && !onceLoad) {
      fetchAthlete()
      setOnceLoad(true)
    }
  }, [moralis, onceLoad])

  useEffect(() => {
    if (moralis) fetchAthlete()
  }, [isSort])

  const handleBack = () => {
    history.push("/admin")
  }

  const makeAthlete = async (e) => {
    const address = addressInput.current.value.toLowerCase()
    if (address === null) {
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: "Please type wallet address of user!",
      })
    } else {
      Loading.standard()
      try {
        const params = { ethAddress: address }
        const user = await moralis?.fn.Cloud.run("getUserByAddress", params)
        const roleIndex = userRoles.map(item => item.value).indexOf(user?.attributes.role) + 1;
        let transaction = await moralis?.fn.executeFunction(setRoleByUser(address, roleIndex))
        await transaction.wait()
        transaction = await moralis?.fn.executeFunction(setGroupAssign(address))
        await transaction.wait()
        const isSuccess = await moralis?.fn.Cloud.run("setAthlete", params)
        Loading.remove()
        if (isSuccess) { fetchAthlete() }
      } catch (error) {
        Loading.remove()
        Modal.error({
          icon: <ExclamationCircleOutlined />,
          content: error.data ? error.data.message : error.message,
        })
      }
    }
  }

  return (
    <MainContainer>
      <BackBtn onClick={handleBack}>back</BackBtn>
      <TopContainer>
        <Title>Manage Athletes</Title>
        {/* <RightContainer>
          <SearchInput
            placeholder="Find a notice"
            ref={addressInput}
          />
          <MakeAdminBtn onClick={makeAthlete}>make athlete</MakeAdminBtn>
        </RightContainer> */}
      </TopContainer>
      <AdminToolbarContainer>
        <QuantityText>{athletes.length} athletes</QuantityText>
      </AdminToolbarContainer>
      <MainInnerContainer>
        {athletes.map((user, index) => (
          <AthleteCard key={index} user={user.attributes} />
        ))}
      </MainInnerContainer>
    </MainContainer>
  )
}
