import { useEffect, useState } from "react"
import { useMoralis } from "react-moralis"

export const useSwapRate = () => {
  const [rate, setRate] = useState(100)
  const { Moralis, isInitialized } = useMoralis()

  useEffect(() => {
    if (isInitialized) {
      const handleStatusChange = async () => {
        const value = await Moralis.Cloud.run("getStripeRate");
        setRate(value)
      }
      handleStatusChange();
    }
  }, [isInitialized])
  return rate
}
