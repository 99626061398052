import React, { useState, useEffect, useCallback } from "react"
import {
  ActionsContainer,
  AdminAddress,
  AdminName,
  SettingCardContainer,
  InnerContainer,
} from "../styles/ManageAdminsStyling"
import { SelectBoxDropdown, } from "screens/Admin/styles/CreateNFTStyles"
import { useMoralis } from "react-moralis"
import { Divider } from "antd"
import { Button, Modal, Input } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { setCategoryAmount as setCA721, getCategoryAmount as getCA721 } from "utils/helpers/ylnft721"
import { setCategoryAmount as setCA1155, getCategoryAmount as getCA1155 } from "utils/helpers/ylnft1155"
import { Loading } from "notiflix"
import { sports, creatNFTTabs } from "utils/dummyData"
import SelectBox from "components/SelectBox"

export const SettingCard = ({ type, moralis }) => {
  const [amount, setAmount] = useState(0)
  const [sport, setSport] = useState("Soccer")
  const [sportList, setSportList] = useState(sports)

  const memoizedCallback = useCallback(async () => {
    if (moralis) {
      if (type == "Booster") {
        const res = await moralis?.fn.executeFunction(getCA1155(sport, type))
        setAmount(res ?? 0)
      } else {
        const res = await moralis?.fn.executeFunction(getCA721(sport, type))
        setAmount(res ?? 0)
      }
    }
  }, [sport, type, moralis])

  useEffect(() => {
    if (type == "Booster") {
      const s = sports.concat([{ id: 10, value: "Game" }, { id: 11, value: "Sponsor" }, { id: 12, value: "Advertiser" }])
      setSportList(s)
    } else {
      setSportList(sports)
    }
  }, [type])

  useEffect(() => {

    memoizedCallback()
  }, [memoizedCallback, moralis])

  const handleClick = async () => {
    Loading.standard()
    let transaction
    try {
      if (type == "Booster") {
        transaction = await moralis?.fn.executeFunction(setCA1155(sport, type, amount),)
      } else {
        transaction = await moralis?.fn.executeFunction(setCA721(sport, type, amount),)
      }
      await transaction.wait(3)
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: "Updated successfully",
      })
    } catch (error) {
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }

  return (
    <SettingCardContainer>
      <InnerContainer>
        <AdminName>{type}</AdminName>
        <AdminAddress>{sport}</AdminAddress>
        <SelectBoxDropdown>
          <SelectBox
            options={sportList.map(item => item.value)}
            selectedOption={sport}
            onChange={(value) => setSport(value)}
            placeholder="Select the Sport type"
          />
        </SelectBoxDropdown>
        <Input
          placeholder="Please enter the purchase quantity"
          style={{
            borderRadius: "8px",
            opacity: "0.5",
            backgroundColor: "#ffffff",
            height: "40px",
            marginTop: "20px"
          }}
          bordered={false}
          value={amount}
          onChange={(e) => { setAmount(e.target.value) }}
        />
      </InnerContainer>
      <Divider />
      <ActionsContainer>
        <Button block type="primary" size="large" onClick={handleClick}>Ok</Button>
      </ActionsContainer>
    </SettingCardContainer>
  )
}
