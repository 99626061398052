import React, { useContext } from "react"
import { SettingContainer, SettingGroupContainer, } from "../styles/ManageAdminsStyling"
import { SettingCard } from "./SettingCard"
import { Col, Row, Collapse } from "antd"
import { sports, creatNFTTabs } from "utils/dummyData"
const { Panel } = Collapse;

export const SuperSetting = ({ moralis }) => {
  const handleClick = () => {

  }

  const handleDemoteClick = () => {

  }

  return (
    <SettingContainer>
      <Collapse accordion defaultActiveKey={[0]}>
        <Panel header={"NFT QTY Setting"} key={0}>
          <SettingGroupContainer>
            <Row gutter={[24, 16]} style={{ width: "100%" }}>
              {creatNFTTabs.map((type, index) => {
                return (
                  <Col xs={24} lg={8} key={index}>
                    <SettingCard type={type.value} moralis={moralis} />
                  </Col>
                )
              })}
            </Row>
          </SettingGroupContainer>
        </Panel>
      </Collapse>
    </SettingContainer>
  )
}
