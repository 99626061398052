import { DappContext } from "context"
import { useCallback, useContext, useEffect, useState } from "react"
import { useMoralis } from "react-moralis"
import { Link } from "react-router-dom"
import { Modal } from 'antd';

import {
  MainContainer,
  MainHeading,
  CenterSide,
  TabContainer,
  TabButton,
  FormContainer,
} from "./styles/CreateNFTStyles"
import { creatNFTTabs } from "utils/dummyData"
import { FormTournament } from "./components/FromTournament";

const CreateTournament = () => {
  const { user } = useMoralis()

  useEffect(() => {
    if (user && (!user.attributes.isSuperAdmin || !user.attributes.isAdmin)) {
      Modal.error({
        title: 'Error message',
        content: "Only administrators can access this page.",
        onOk() {
          return window.location.replace("/nftMarket")
        },
      });
    }
  }, [user])

  return (
    <MainContainer>
      <CenterSide>
        <Link to={user?.attributes.isSuperAdmin ? "/admin/super" : "/admin"} style={{ color: "#6A6A69", fontSize: "16px" }} >
          &#8592; Back
        </Link>
        <MainHeading>CREATING TOURNAMENTS</MainHeading>
        <FormContainer>
          <FormTournament />
        </FormContainer>
      </CenterSide>
    </MainContainer>
  )
}

export default CreateTournament
