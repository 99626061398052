import Carousel from "react-grid-carousel"
import { Button, Col, Input, Image, Row, Space, Tabs, Typography, Layout, Card, Drawer, Upload } from "antd"
import React, { useState, useEffect, useCallback } from "react"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { useMoralis } from "react-moralis"
const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotallySecretKey');


const EmailVerify = ({ moralis }) => {

    const params = useParams() 
    const history = useHistory()

    const verifyEmail = async (id) => { 
        const res = await moralis?.fn.Cloud.run("verifyEmail", { id: id })  
        if (res) {
            history.push("/")
        }
    }

    useEffect(() => {
        if (moralis) {
            if (params.id != null && params.id != undefined) {
                const id = cryptr.decrypt(params.id);
                verifyEmail(id)
            }
        }
    }, [moralis, params.id])   

    return (
        <Layout className="layout">
            <Row justify="space-between" align="middle" className="maring-top-20">
                <Col xs={24} md={12}>
                    You are verified, please login...
                </Col>
            </Row>
        </Layout>
    )
}

export default EmailVerify
