/* eslint-disable prettier/prettier */
import React, { useContext, useState, useEffect, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { Loading } from "notiflix"
import { useHistory } from "react-router"
import "../styles/formNftCard.css"
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
  DropDownContainer,
  DropFileStyle,
  AttachContainer,
  FormStyling,
  SubmitBtn,
  TitleField,
  NetworkCheckGroup,
  SelectBoxDropdown,
  AsideContainer,
  AsideImageContainer,
} from "../styles/CreateNFTStyles"
import attachSvg from "../../../images/attach.svg"
import { creatSports, NFTFunctionTypes, ImpressionPage, ImpressionPos } from "utils/dummyData"
import { filtersOptions, BoosterTypes } from "utils/dummyData"
import { countries } from "../countries"
import { lazyMintNft, createToken } from "utils/helpers/ylnft721"
import { lazy1155MintNft, create1155Token } from "utils/helpers/ylnft1155"
import { useMoralis } from "react-moralis"
import { Input, DatePicker, Typography, Row, Col, Card } from "antd"
import CheckBox from "components/Checkbox"
import SelectBox from "components/SelectBox"
import InputComponent from "screens/AuthFlow/components/InputComponent"

const CardComponent = ({ title, name, value, onChange }) => {
  return (
    <Col xs={24} sm={12} md={8} xl={6}>
      <Card
        title={title}
        bordered={false}
        bodyStyle={{
          backgroundColor: "white",
          padding: "8px 0 8px 30px",
          border: "1px solid #61616A",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          color: "#242424",
        }}
        headStyle={{
          backgroundColor: "#61616A",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          border: "1px solid #61616A",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          color: "rgba(255, 255, 255)",
        }}
        style={{
          color: "#242424",
          backgroundColor: "#e7e9ed",
        }}
        hoverable
      >
        <Input
          name={name}
          className="custom-input"
          allowClear
          bordered={false}
          placeholder={"---"}
          type="number"
          value={value}
          onChange={onChange}
        />
      </Card>
    </Col>
  )
}

export const FormNFTCard = ({ propNftType, moralis }) => {
  const history = useHistory()
  const { Moralis, user } = useMoralis()
  const [sportList, setSportList] = useState(creatSports)
  const [files, setFiles] = useState([])
  const [allClubs, setAllClubs] = useState([])
  const [athletes, setAthletes] = useState([])
  const [loaded, setLoaded] = useState(false);
  const [playerCnt, setPlayerCnt] = useState(1);
  const [remainCnt, setRemainCnt] = useState(1);
  const [selectedFields, setSelectedFields] = useState({
    boosterType: "Player",
    network: "Binance",
    mintType: null,
    sport: null,
    nftDuration: null,
    boostedCharact: null,
    boostedAmount: null,
    amount: "1",
    nftName: null,
    nftDesc: null,
    athleteBirthday: null,
    athleteCountry: null,
    athleteRegion: null,
    athleteTeam: null,
    biography: null,
    privacy: null,
    power: "",
    energy: "",
    luck: "",
    speed: "",
    wizzardy: "",
    impression: 0,
    impStart: null,
    impEnd: null,
    impPage: "Landing Page",
    impRouter: "",
    impPos: "Body Top",
    teamname: "FK_team",
    teamid: "",
    athletname: "FK_athlete",
    athletid: process.env.REACT_APP_SUPER_ADMIN_WALLET_ADDRESS,
  })

  const loadloadInitClubs = useCallback(async () => {
    if (moralis) {
      Loading.standard()
      const result = await moralis?.fn.Cloud.run("getAllClubs")
      result.sort((a, b) => { new Date(b.date) - new Date(a.date) })
      const club_tmp = []
      const individual = { id: 0, name: "Individual Athletes", icon: "", type: "Individual", link: "" }
      club_tmp.push(individual)
      result.forEach((c, index) => {
        const v = c.attributes;
        const club = {
          id: result[index].id,
          name: v.name,
          icon: v.icon,
          type: v.type,
          link: v.link
        }
        club_tmp.push(club)
      })
      setAllClubs(club_tmp)
      Loading.remove()
    }
  }, [moralis])

  const loadOneTeamData = async (cid) => {
    Loading.standard()
    let data
    if (cid == 0) {
      data = await Moralis.Cloud.run("getIndividual")
    } else {
      data = await Moralis.Cloud.run("getAthleteListFCN", { club: cid, type: propNftType })
    }
    const p = []
    data.forEach((d) => {
      const u = {
        name: d.attributes.nickname,
        address: d.attributes.ethAddress,
        sports: d.attributes.sports
      }
      p.push(u)
    })
    setAthletes(p)
    Loading.remove()
  }



  useEffect(async () => {
    if (moralis && allClubs.length > 0 && user && user?.attributes.role != "ylg_adm") {
      if (user.attributes.role == "in_ath") {
        setSelectedFields({ ...selectedFields, teamname: "Individual", teamid: "", athletname: user.attributes.nickname, athletid: user.attributes.ethAddress })
        setSportList(user.attributes.sports)
        const data = await moralis?.fn.Cloud.run("get721CounterCurrentOne", { address: user.attributes.ethAddress })
        setPlayerCnt(data.playerCnt)
        setRemainCnt(data.currentCnt)
        if (data.playerCnt == 0 && propNftType == "Player") {
          alert("You can't create more Game NFT for this player")
        }
        if (data.currentCnt == 0 && propNftType == "Sponsorship") {
          alert("Need to buy Sponsor Counter booster, can't create more NFT for this player")
        }
      } else {
        const clubId = user.attributes.club
        const sel = allClubs.filter(c => c.id == clubId)
        setSelectedFields({ ...selectedFields, teamname: sel[0].name, teamid: clubId })
        loadOneTeamData(clubId)
      }
    }
  }, [moralis, user, allClubs, propNftType])


  useEffect(() => {
    if (moralis && loaded == false) {
      setLoaded(true)
      loadloadInitClubs()
    }
  }, [moralis])

  const onSubmit = async (e) => {
    e.preventDefault()
    const {
      boosterType,
      boosterFor,
      network,
      mintType,
      sport,
      nftDuration,
      boostedCharact,
      boostedAmount,
      amount,
      nftName,
      nftDesc,
      athleteBirthday,
      athleteCountry,
      athleteRegion,
      athleteTeam,
      biography,
      privacy,
      power,
      energy,
      luck,
      speed,
      wizzardy,
      impression,
      impStart,
      impEnd,
      impPage,
      impRouter,
      impPos,
      athletname,
      athletid,
      teamname,
      teamid,
    } = selectedFields
    Loading.standard()

    try {
      const data = files[0]
      const image_ipfs = new Moralis.File(data.name, data)
      const img = await image_ipfs.saveIPFS()
      const image = "https://gateway.moralisipfs.com/ipfs/" + img._hash
      const personal =
        propNftType !== "Booster"
          ? {
            power,
            energy,
            luck,
            speed,
            wizzardy,
            athleteBirthday,
            athleteCountry,
            athleteRegion,
            athleteTeam,
            biography,
            privacy,
          }
          : null

      const options = JSON.stringify({
        name: nftName,
        description: nftDesc,
        type: propNftType,
        sport,
        image,
        nftDuration,
        boosterType,
        boostedCharact,
        boostedAmount,
        amount,
        personal,
        impStart,
        impEnd,
        impPage,
        impRouter,
        impPos,
        impression,
        athletname,
        athletid,
        teamname,
        teamid,
        date: Date.now(),
      })

      const file = new Moralis.File("file.json", {
        base64: btoa(options),
      })
      const response = await file.saveIPFS()
      const metadata_hash = "https://gateway.moralisipfs.com/ipfs/" + response._hash
      let transaction
      if (propNftType !== "Booster") {
        if (mintType == "Normal") {
          transaction = await Moralis.executeFunction(
            createToken(metadata_hash, sport, propNftType, athletid),
          )
        } else {
          transaction = await Moralis.executeFunction(
            lazyMintNft(metadata_hash, sport, propNftType, athletid),
          )
        }
        await Moralis.Cloud.run("update721Counter", { address: athletid, team: teamid, mode: 1 })
      } else {
        if (mintType == "Normal") {
          transaction = await Moralis.executeFunction(
            create1155Token(metadata_hash, sport, propNftType, amount, boosterFor),
          )
        } else {
          transaction = await Moralis.executeFunction(
            lazy1155MintNft(metadata_hash, sport, propNftType, amount, boosterFor),
          )
        }
        // if(boosterType == "Sponsor Counter"){
        //   await Moralis.Cloud.run("increase721CounterMax", { address: athletid })
        // }
      }
      await transaction.wait(3)
      history.push("/admin/allnfts")
    } catch (error) {
      console.log(error)
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
    Loading.remove()
  }

  const thumbs = files.map((file) => (
    <AsideImageContainer key={file.name}>
      <img
        src={file.preview}
        onLoad={() => {
          URL.revokeObjectURL(file.preview)
        }}
      />
    </AsideImageContainer>
  ))

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
    },
  })

  const getBoosterId = (value) => {
    var id = ""
    BoosterTypes.forEach((b) => {
      if (b.value == value) {
        id = b.id
      }
    })
    return id
  }

  return (
    <FormStyling id="createNFTForm" onSubmit={onSubmit}>
      {propNftType === "Booster" &&
        <>
          <TitleField>{"booster for"}</TitleField>
          <SelectBoxDropdown>
            <SelectBox
              options={BoosterTypes.map(item => item.value)}
              selectedOption={selectedFields.boosterType}
              onChange={(value) => {
                var boosterId = getBoosterId(value)
                if (boosterId == 1 || boosterId == 2) {
                  setSelectedFields({ ...selectedFields, boosterType: value, boosterFor: getBoosterId(value) })
                } else {
                  let sport = "Sponsor"
                  if (boosterId == 3) {
                    sport = "Game"
                  } else if (boosterId == 4) {
                    sport = "Sponsor"
                  } else if (boosterId == 5) {
                    sport = "Advertiser"
                  }
                  setSelectedFields({ ...selectedFields, boosterType: value, boosterFor: getBoosterId(value), sport: sport })
                }
              }}
              placeholder="booster for"
            />
          </SelectBoxDropdown>
        </>
      }

      <TitleField>Select Network</TitleField>
      <NetworkCheckGroup>
        {
          ['Binance', 'Polygon'].map((item, key) => (
            <CheckBox
              value={selectedFields.network == item}
              onClickHandler={() => {
                setSelectedFields({ ...selectedFields, network: item });
              }}
              label={item}
              key={key}
            />
          ))
        }
      </NetworkCheckGroup>

      {/* Mint Type */}
      <TitleField>{"Type of mint"}</TitleField>
      <SelectBoxDropdown>
        <SelectBox
          options={NFTFunctionTypes.map(item => item.value)}
          selectedOption={selectedFields.mintType}
          onChange={(value) => setSelectedFields({ ...selectedFields, mintType: value })}
          placeholder="Select the mint type"
        />
      </SelectBoxDropdown>

      {
        (propNftType == "Player" || propNftType == "Sponsorship" || selectedFields.boosterType == "Sponsor Counter") && (user?.attributes.role == "hs_adm" || user?.attributes.role == "sc_adm") &&
        <>
          <TitleField>{"Select one player"}</TitleField>
          <SelectBoxDropdown>
            <SelectBox
              options={athletes.map((item) => item.name)}
              selectedOption={selectedFields.athletname}
              onChange={async (value) => {
                const sel = athletes.filter(c => c.name == value)
                setSelectedFields({ ...selectedFields, athletname: value, athletid: sel[0].address })
                const data = await Moralis.Cloud.run("get721CounterCurrentOne", { address: sel[0].address })
                console.log(sel)
                setSportList(sel[0].sports)
                setPlayerCnt(data.playerCnt)
                setRemainCnt(data.currentCnt)
                if (data.playerCnt == 0 && propNftType == "Player") {
                  alert("You can't create more Game NFT for this player")
                }
                if (data.currentCnt == 0 && propNftType == "Sponsorship") {
                  alert("Need to buy Sponsor Counter booster, can't create more NFT for this player")
                }
              }}
              placeholder="Select athlete's country"
            />
          </SelectBoxDropdown>
        </>
      }

      {/* kind of sport */}
      {(propNftType == "Player" || propNftType == "Sponsorship" || selectedFields.boosterType == "Player" || selectedFields.boosterType == "Contest Team Properties") &&
        <>
          <TitleField>{"Select kind of sport"}</TitleField>
          <SelectBoxDropdown>
            <SelectBox
              options={sportList.map(item => item)}
              selectedOption={selectedFields.sport}
              onChange={(value) => setSelectedFields({ ...selectedFields, sport: value })}
              placeholder="Select kind of sport"
            />
          </SelectBoxDropdown>
        </>
      }

      {propNftType === "Booster" &&
        <InputComponent
          title="Qty of NFT" placeholder="Qty of NFT" name="amount" type="number"
          onChange={(e) => setSelectedFields({ ...selectedFields, amount: e.target.value })}
        />
      }

      <InputComponent
        title="NFT name" placeholder="NFT Name" name="nftName" type="text"
        onChange={(e) => setSelectedFields({ ...selectedFields, nftName: e.target.value })}
      />

      {propNftType === "Booster" && (selectedFields.boosterType == "Player" || selectedFields.boosterType == "Contest Team Properties") &&
        <>
          <InputComponent
            title="Set duration time" placeholder="Set duration time" name="nftDuration" type="number"
            onChange={(e) => setSelectedFields({ ...selectedFields, nftDuration: e.target.value })}
          />

          <TitleField>{"choose boosted characteristic"}</TitleField>
          <SelectBoxDropdown>
            <SelectBox
              options={filtersOptions[3].options.map(item => item.name)}
              selectedOption={selectedFields.boostedCharact}
              onChange={(value) => setSelectedFields({ ...selectedFields, boostedCharact: value })}
              placeholder="Choose boosted characteristic"
            />
          </SelectBoxDropdown>

          <InputComponent
            title="set the boosted amount" placeholder="Boosted amount" name="boostedAmount" type="number"
            onChange={(e) => setSelectedFields({ ...selectedFields, boostedAmount: e.target.value })}
          />
        </>
      }

      {propNftType === "Booster" && selectedFields.boosterType == "Advertiser Space" &&
        <>
          <TitleField>Enter Ad start time</TitleField>
          <DatePicker
            className="select-field date"
            placeholder="Enter Ad start time"
            name="impStart"
            format="DD/MM/YYYY"
            style={{ color: "#fff" }}
            required
            onChange={(value) =>
              setSelectedFields({ ...selectedFields, impStart: value })
            }
          />

          <TitleField>Enter Ad end time</TitleField>
          <DatePicker
            className="select-field date"
            placeholder="Enter Ad end time"
            name="impEnd"
            format="DD/MM/YYYY"
            style={{ color: "#fff" }}
            required
            onChange={(value) =>
              setSelectedFields({ ...selectedFields, impEnd: value })
            }
          />

          <InputComponent
            title="Impressions" placeholder="Impression Number" name="impression" type="number"
            onChange={(e) => setSelectedFields({ ...selectedFields, impression: e.target.value })}
          />

          <TitleField>{"Select the page to display"}</TitleField>
          <SelectBoxDropdown>
            <SelectBox
              options={ImpressionPage.map(item => item.value)}
              selectedOption={selectedFields.impPage}
              onChange={(value) => {
                const sel = ImpressionPage.filter(c => c.value == value)
                setSelectedFields({ ...selectedFields, impPage: value, impRouter: sel[0].router })
              }}
              placeholder="Choose boosted characteristic"
            />
          </SelectBoxDropdown>

          <TitleField>{"Select the position to display"}</TitleField>
          <SelectBoxDropdown>
            <SelectBox
              options={ImpressionPos.map(item => item.value)}
              selectedOption={selectedFields.impPos}
              onChange={(value) => setSelectedFields({ ...selectedFields, impPos: value })}
              placeholder="Choose boosted characteristic"
            />
          </SelectBoxDropdown>
        </>
      }


      {propNftType !== "Booster" &&
        <InputComponent
          title="NFT description" placeholder="Description" name="nftDesc" type="textArea"
          onChange={(e) => setSelectedFields({ ...selectedFields, nftDesc: e.target.value })}
        />
      }

      <>
        <TitleField>
          Enter media to upload on IPFS <br />
          <span>The file must be PNG, and no more than 3 MB</span>
        </TitleField>
        <DropDownContainer>
          <DropFileStyle {...getRootProps()}>
            <input
              type="url"
              placeholder="Enter media to upload to IPFS"
              {...getInputProps()}
            />
            <p>Drop files here</p>
            <AttachContainer>
              <img src={attachSvg} alt="attach" />
              <span>Attach file</span>
            </AttachContainer>
            <AsideContainer>
              {thumbs}
            </AsideContainer>
          </DropFileStyle>
        </DropDownContainer>
      </>

      {propNftType !== "Booster" && (
        <>
          <Typography.Title style={{ marginTop: "50px", marginBottom: "15px", fontSize: "24px" }}>
            Enter characteristics
          </Typography.Title>
          <Row gutter={[16, 24]}>
            <CardComponent title="Power" name="power" value={selectedFields.power}
              onChange={(e) => setSelectedFields((prev) => ({ ...prev, power: e.target.value, }))} />
            <CardComponent title="Energy" name="energy" value={selectedFields.energy}
              onChange={(e) => setSelectedFields((prev) => ({ ...prev, energy: e.target.value, }))} />
            <CardComponent title="Luck" name="luck" value={selectedFields.luck}
              onChange={(e) => setSelectedFields((prev) => ({ ...prev, luck: e.target.value, }))} />
            <CardComponent title="Speed" name="speed" value={selectedFields.speed}
              onChange={(e) => setSelectedFields((prev) => ({ ...prev, speed: e.target.value, }))} />
            <CardComponent title="Wizzardy" name="wizzardy" value={selectedFields.wizzardy}
              onChange={(e) => setSelectedFields((prev) => ({ ...prev, wizzardy: e.target.value, }))} />
          </Row>

          <>
            <TitleField>Enter athlete's birthday</TitleField>
            <DatePicker
              className="select-field date"
              placeholder="Enter your birthday"
              name="athleteBirthday"
              format="DD/MM/YYYY"
              style={{ color: "#fff" }}
              required
              onChange={(value) =>
                setSelectedFields({ ...selectedFields, athleteBirthday: value })
              }
            />
          </>
          {/* Select athlete's country */}
          <TitleField>{"Select athlete's country"}</TitleField>
          <SelectBoxDropdown>
            <SelectBox
              options={countries.map(item => item.value)}
              selectedOption={selectedFields.athleteCountry}
              onChange={(value) => setSelectedFields({ ...selectedFields, athleteCountry: value })}
              placeholder="Select athlete's country"
            />
          </SelectBoxDropdown>

          <InputComponent
            title="Type athlete's region" placeholder="Select your region" name="athleteRegion" type="text"
            onChange={(e) => setSelectedFields({ ...selectedFields, athleteRegion: e.target.value, })}
          />

          <InputComponent
            title="Player biography" placeholder="Text..." name="biography" type="textArea"
            onChange={(e) => setSelectedFields({ ...selectedFields, biography: e.target.value })}
          />
          <InputComponent
            title="Privacy Policy" placeholder="Text..." name="privacy" type="textArea"
            onChange={(e) => setSelectedFields({ ...selectedFields, privacy: e.target.value })}
          />
        </>
      )}
      {
        ((playerCnt > 0 && propNftType == "Player") || (remainCnt > 0 && propNftType == "Sponsorship") || propNftType == "Booster") && <SubmitBtn type="submit" placeholder="MINT" value="MINT" />
      }
    </FormStyling>
  )
}
