import React, { useContext } from "react"
import { ContractsSwitch } from "components/Sidebar/ContractsSwitch"
import { DemoteAdmin } from "components/Sidebar/DemoteAdmin"
import { Space, Table, Tag, Button, Modal } from 'antd';
import { DappContext } from "context"
import { userRoles } from "utils/dummyData"
import { ClubImage, AdminAvatarImage, ClubContainer, } from "../styles/ManageAdminsStyling"
import profileImage from "images/avatar.png"
import { setRoleByAdmin } from "utils/helpers/proxy"
import { Loading } from "notiflix"
import { useMoralis } from "react-moralis"
import { truncate } from "utils/formatAddress"
import { ExclamationCircleOutlined } from "@ant-design/icons"
const { Column, ColumnGroup } = Table;

export const AdminTable = ({ adminUsers }) => {
  const { Moralis } = useMoralis()
  const { onCloseSidebar, setSidebarContent, setOpenSidebar } = useContext(DappContext)

  const handleClick = (user, club) => {
    setSidebarContent(<ContractsSwitch user={user} club={club} closeSidebar={onCloseSidebar} />,)
    setOpenSidebar(true)
  }

  const handleDemoteClick = (user, club) => {
    setSidebarContent(<DemoteAdmin user={user} club={club} closeSidebar={onCloseSidebar} />)
    setOpenSidebar(true)
  }

  const handleApproveClick = async (user) => {
    const address = user.ethAddress
    if (address === null) {
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: "Please type wallet address of user!",
      })
    } else {
      Loading.standard()
      try {
        const params = { ethAddress: address }
        const roleIndex = userRoles.map(item => item.value).indexOf(user.role) + 1;
        const transaction = await Moralis.executeFunction(setRoleByAdmin(address, roleIndex))
        await transaction.wait()
        const isSuccess = await Moralis.Cloud.run("setAdmin", params)
        Loading.remove()
        Modal.success({
          icon: <ExclamationCircleOutlined />,
          content: "Set athlete successfully",
        })
        location.reload()
      } catch (error) {
        Loading.remove()
        Modal.error({
          icon: <ExclamationCircleOutlined />,
          content: error.data ? error.data.message : error.message,
        })
      }
    }
  }

  const handleRejectClick = async (user) => {
    const params = { ethAddress: user.ethAddress }
    Loading.standard()
    try {
      const isSuccess = await Moralis.Cloud.run("unsetAdmin", params)
      Loading.remove()
      Modal.success({
        icon: <ExclamationCircleOutlined />,
        content: "Reject athlete successfully",
      })
      location.reload()
    } catch (error) {
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }

  return (
    <Table dataSource={adminUsers} scroll={{ x: 1300, }} >
      <Column title="ID" key="id" render={(_, record, index) => { ++index; return index; }} />
      <Column title="Avatar" key="avatar" render={(_, record) => (<AdminAvatarImage src={record.attributes.profile_picture || profileImage} />)} />
      <Column title="Name" key="name" render={(_, record) => (<p>{record.attributes.nickname}</p>)}
        sorter={(a, b) => a.attributes.nickname.localeCompare(b.attributes.nickname)} />
      <Column title="Role" key="role" sorter={(a, b) => a.attributes.role.localeCompare(b.attributes.role)}
        render={(_, record) => {
          let role = record.attributes.role
          let color = role === 'hs_adm' ? 'volcano' : role === 'sc_adm' ? 'geekblue' : 'green';
          return <Tag color={color}>{userRoles.filter(item => item.value == record.attributes.role)[0]?.title}</Tag>
        }} />
      <Column title="Wallet" key="wallet" render={(_, record) => (<a href={`https://testnet.bscscan.com/address/${record.attributes.ethAddress}`} target="_blank" rel="noreferrer">{truncate(record.attributes.ethAddress)}</a>)} />
      <Column title="School & Sport Club" key="sc_name" render={(_, record) => (
        record.clubData && <ClubContainer>
          <ClubImage src={record.clubData.icon} />
          <p>{record.clubData.name}</p>
        </ClubContainer>
      )} />
      <Column title="Sport type" key="sport" render={(_, record) => {
        let role = record.attributes.role
        let sports = role != "in_ath" ? record.clubData?.sport : record.attributes.sports
        return sports && sports.map(item => {
          return <Tag color={"processing"} key={item}> {item}</Tag>
        })
      }} />
      <Column title="Stake amount" key="stake"
        sorter={(a, b) => a.attributes.stakeAmount ?? 0 * 1 - b.attributes.stakeAmount ?? 0 * 1}
        render={(_, record) => (<p>{record.attributes.stakeAmount != undefined ? Moralis.Units.FromWei(record.attributes.stakeAmount.toString()) : "0"}</p>)} />
      <Column title="CreateAt" key="createAt" sorter={(a, b) => new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt)}
        render={(_, record) => {
          const startDate = new Date(record.attributes.createdAt)
          var res = startDate.toISOString().slice(0, 16).replace(/-/g, "-");
          return <p>{res}</p>
        }} />
      <Column title="UpdateAt" key="updateAt" sorter={(a, b) => new Date(a.attributes.updatedAt) - new Date(b.attributes.updatedAt)}
        render={(_, record) => {
          const startDate = new Date(record.attributes.updatedAt)
          var res = startDate.toISOString().slice(0, 16).replace(/-/g, "-");
          return <p>{res}</p>
        }} />
      <Column
        title="Action"
        key="action"
        render={(_, record) => (
          record.attributes.authStep == 100 ?
            <Space size="middle">
              <Button type="primary" danger onClick={() => handleDemoteClick(record.attributes, record.clubData)}>DEMOTE</Button>
              <Button type="primary" onClick={() => handleClick(record.attributes, record.clubData)}>EDIT</Button>
            </Space>
            :
            <Space size="middle">
              <Button type="primary" danger onClick={() => handleRejectClick(record.attributes)}>Reject</Button>
              <Button type="primary" onClick={() => handleApproveClick(record.attributes)}>Approve</Button>
            </Space>
        )}
      />
    </Table>
  )
};