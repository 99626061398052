import React from "react"
import { Title, StepButton, DepositBox, ResultImage, ButtonGroup } from "./AuthStyling"
import { Spin } from "antd"
import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons"

const DepositResult = ({ title, result = "", desc = "", type, step, onClickHandler, onBackHandler }) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    return (
        <>
            <Title>{title}</Title>
            {step == 50 ?
                <DepositBox>
                    <Spin indicator={antIcon} />
                    {type == "admin" ?
                        <p>Waiting request admin access from Super Admin</p>
                        :
                        <p>Waiting request athlete access from school & sports club admin</p>
                    }
                </DepositBox>
                :
                <DepositBox>
                    <ResultImage style={{ backgroundColor: "#90E040" }}>
                        <CheckOutlined style={{ backgroundColor: "#90E040", borderRadius: "50%" }} />
                    </ResultImage>
                    <p>{result}</p>
                    <p>{desc}</p>
                </DepositBox>
            }
            <ButtonGroup>
                <StepButton name="back" onClick={onBackHandler} disabled={step == 50}>Back</StepButton>
                <StepButton onClick={onClickHandler} disabled={step == 50}>{step == 50 ? "waiting" : type == "admin" || type == "ath" ? "Send Request Access" : "Finish"}</StepButton>
            </ButtonGroup>
        </>
    )
}

export default DepositResult
