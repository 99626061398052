import React, { useState, useCallback, useEffect } from "react"
import { Title, StepButton, ProfileBox, DropDownContainer, DropFileStyle, AttachContainer, AsideContainer, AsideImageContainer, ButtonGroup } from "./AuthStyling"
import { useDropzone } from "react-dropzone"
import attachSvg from "images/attach.svg"
import InputComponent from "./InputComponent"
import { useMoralis } from "react-moralis"
import { Loading } from "notiflix"
import { creatSports, maleSports, femaleSports } from "utils/dummyData"
import { Modal, Checkbox, Radio } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { TitleField, SelectBoxDropdown, } from "screens/Admin/styles/CreateNFTStyles"

const options = [
    { label: 'Male', value: true },
    { label: 'Female', value: false },
]

const CheckboxGroup = Checkbox.Group;
const Profile = ({ type = "rPicture", title, bio = true, accountType, onClickHandler, onBackHandler }) => {
    const { Moralis, user } = useMoralis()

    const [selectedFields, setSelectedFields] = useState({
        realName: user?.attributes.nickname ?? "",
        isMale: user?.attributes.isMale ?? false,
        biography: user?.attributes.biography ?? "",
        sports: user?.attributes.sports ?? [],
        bName: user?.attributes.bName ?? "",
        cName: user?.attributes.cName ?? "",
        cEmail: user?.attributes.cEmail ?? "",
        cPhone: user?.attributes.cPhone ?? "",
        bAddress: user?.attributes.bAddress ?? "",
        bType: user?.attributes.bType ?? ""
    })

    const [files, setFiles] = useState([])
    const [logos, setLogos] = useState([])

    //   const memoizedCallback = useCallback(async () => {
    //     if (user) {
    //       Loading.standard()
    //       if (["hs_ath", "sc_ath"].includes(user?.attributes.role) && user?.attributes.club) {
    //         let res = await Moralis.Cloud.run("getClub", { id: user?.attributes.club, })
    //         setClubData(res)
    //       }else{

    //       }
    //       Loading.remove()
    //     }
    //   }, [user])

    //   useEffect(() => {
    //     memoizedCallback()
    //   }, [memoizedCallback])

    const { getRootProps: getRootfileProps, getInputProps: getInputfileProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            )
        },
    })

    const { getRootProps: getRootLogoProps, getInputProps: getInputLogoProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setLogos(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
        },
    });

    const logoThumbs = logos.map((file) => (
        <AsideImageContainer key={file.name}>
            <img
                src={file.preview}
                onLoad={() => {
                    URL.revokeObjectURL(file.preview)
                }}
            />
        </AsideImageContainer>
    ))

    const thumbs = files.map((file) => (
        <AsideImageContainer key={file.name}>
            <img
                src={file.preview}
                onLoad={() => {
                    URL.revokeObjectURL(file.preview)
                }}
            />
        </AsideImageContainer>
    ))

    const onAcceptHanlder = async () => {
        Loading.standard();
        try {
            const { realName, isMale, biography, sports, bName, cName, cEmail, cPhone, bAddress, bType } = selectedFields
            const data = files[0];
            const file = new Moralis.File(data.name, data);
            await file.saveIPFS();

            user.set("nickname", realName);
            user.set("biography", biography);
            user.set("isMale", isMale);
            user.set("sports", sports);
            if (accountType == "advert") {
                const data = logos[0];
                const logo = new Moralis.File(data.name, data);
                await logo.saveIPFS();
                user.set("bLogo", logo._ipfs);
                user.set("bName", bName);
                user.set("cName", cName);
                user.set("cEmail", cEmail);
                user.set("cPhone", cPhone);
                user.set("bAddress", bAddress);
                user.set("bType", bType);
            }
            user.set("profile_picture", file._ipfs);
            await user.save();
            Loading.remove();
            onClickHandler();
        } catch (error) {
            Loading.remove();
            Modal.error({
                icon: <ExclamationCircleOutlined />,
                content: error.data ? error.data.message : error.message,
            })
        }
    }

    return (
        <>
            <div>
                <Title>{title}</Title>
                <InputComponent
                    title={type == "fPicture" ? "Enter name" : "Enter real name"} placeholder="Name" name="realName" type="text"
                    value={selectedFields.realName}
                    onChange={(e) => setSelectedFields({ ...selectedFields, realName: e.target.value })}
                />
                {
                    bio &&
                    <InputComponent
                        title="Enter biography" placeholder="Biography" name="biography" type="textArea"
                        value={selectedFields.biography}
                        onChange={(e) => setSelectedFields({ ...selectedFields, realName: e.target.value })}
                    />
                }
                {accountType == "advert" &&
                    <>
                        <InputComponent
                            title="Business Name" placeholder="Business Name" name="bName" type="text"
                            value={selectedFields.bName}
                            onChange={(e) => setSelectedFields({ ...selectedFields, bName: e.target.value })}
                        />
                        <InputComponent
                            title="Contact Name" placeholder="Contact Name" name="cName" type="text"
                            value={selectedFields.cName}
                            onChange={(e) => setSelectedFields({ ...selectedFields, cName: e.target.value })}
                        />
                        <InputComponent
                            title="Contact email" placeholder="Contact email" name="cEmail" type="text"
                            value={selectedFields.cEmail}
                            onChange={(e) => setSelectedFields({ ...selectedFields, cEmail: e.target.value })}
                        />
                        <InputComponent
                            title="Contact phone" placeholder="Contact phone" name="cPhone" type="text"
                            value={selectedFields.cPhone}
                            onChange={(e) => setSelectedFields({ ...selectedFields, cPhone: e.target.value })}
                        />
                        <InputComponent
                            title="Business physical address" placeholder="Business physical address" name="bAddress" type="text"
                            value={selectedFields.bAddress}
                            onChange={(e) => setSelectedFields({ ...selectedFields, bAddress: e.target.value })}
                        />
                        <InputComponent
                            title="Business type/category" placeholder="Business type/category" name="bType" type="text"
                            value={selectedFields.bType}
                            onChange={(e) => setSelectedFields({ ...selectedFields, bType: e.target.value })}
                        />
                        <ProfileBox>
                            <h1>Enter business logo</h1>
                            <p>The file must be PNG, and no more than 3 MB</p>
                        </ProfileBox>
                        <DropDownContainer>
                            <DropFileStyle {...getRootLogoProps()}>
                                <input
                                    type="url" name="logo"
                                    placeholder="Enter media to upload to IPFS"
                                    {...getInputLogoProps()}
                                />
                                <p>Drop files here</p>
                                <AttachContainer>
                                    <img src={attachSvg} alt="attach" />
                                    <span>Attach file</span>
                                </AttachContainer>
                                <AsideContainer>
                                    {logoThumbs}
                                </AsideContainer>
                            </DropFileStyle>
                        </DropDownContainer>
                    </>
                }
                {accountType != "sponsor" && accountType != "advert" &&
                    <>
                        <TitleField>{"Select your gender"}</TitleField>
                        <SelectBoxDropdown>
                            <Radio.Group options={options}
                                value={selectedFields.isMale}
                                onChange={(e) => setSelectedFields({ ...selectedFields, isMale: e.target.value })}
                            />

                        </SelectBoxDropdown>
                    </>
                }
                {
                    ["hs_ath", "sc_ath", "in_ath", "sponsor"].includes(accountType) &&
                    <>
                        <TitleField>{"Select kind of sport"}</TitleField>
                        <SelectBoxDropdown>
                            <CheckboxGroup options={(accountType == "sponsor" ? creatSports : selectedFields.isMale ? maleSports : femaleSports).map(item => item.value)}
                                value={selectedFields.sports}
                                onChange={(value) => setSelectedFields({ ...selectedFields, sports: value })}
                            />
                        </SelectBoxDropdown>
                    </>
                }

                <ProfileBox>
                    {type == "rPicture" ?
                        <h1>Enter your real picture</h1>
                        : type == "fPicture" ?
                            <h1>Enter your picture</h1>
                            : <h1>Enter your HighSchool or Club logo</h1>
                    }
                    <p>The file must be PNG, and no more than 3 MB</p>
                </ProfileBox>
                <DropDownContainer>
                    <DropFileStyle {...getRootfileProps()}>
                        <input
                            type="url" name="file"
                            placeholder="Enter media to upload to IPFS"
                            {...getInputfileProps()}
                        />
                        <p>Drop files here</p>
                        <AttachContainer>
                            <img src={attachSvg} alt="attach" />
                            <span>Attach file</span>
                        </AttachContainer>
                        <AsideContainer>
                            {thumbs}
                        </AsideContainer>
                    </DropFileStyle>
                </DropDownContainer>
            </div>
            <ButtonGroup>
                <StepButton name="back" onClick={onBackHandler}>Back</StepButton>
                <StepButton onClick={onAcceptHanlder}>ACCEPT</StepButton>
            </ButtonGroup>
        </>
    )
}

export default Profile
