import React, { useEffect, useState, useCallback } from "react"
import { OuterContainer, } from "./components/AuthStyling"
import { userRoles } from "utils/dummyData"
import { useHistory } from "react-router"
import { useMoralis } from "react-moralis"

import AccountType from "./components/AccountType"
import Club from "./components/Club"
import Deposit from "./components/Deposit"
import DepositResult from "./components/DepositResult"
import Notification from "./components/Notification"
import Profile from "./components/Profile"

import { toast } from "react-hot-toast"
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Loading } from "notiflix"

const AuthFlow = ({ moralis }) => {
    const { Moralis, user } = useMoralis()
    const [content, setContent] = useState(null)
    const [step, setStep] = useState(user?.attributes.authStep ?? 0)
    const [accountType, setAccountType] = useState(user?.attributes.role ?? "")
    const history = useHistory()
    const [clubList, setClubList] = useState([])

    useEffect(() => {
        if (user && !user.attributes.isSuperAdmin) {
            if (user.attributes.isAdmin === true) {
                return window.location.replace("/admin")
            }

            if (user?.attributes.isAdmin) {
                return window.location.replace("/myaccount")
            }
        }
    }, [user])

    const memoizedCallback = useCallback(async (moralis) => {
        Loading.standard()
        if (moralis) {
            try {
                let clubs = await moralis?.fn.Cloud.run("getAllClubs")
                const empty = [{ id: 0, attributes: { name: "Not found school & sport club", description: "Not find the school/club in the list, we will inform you when it will be added here via email.", icon: null, type: "all" } }]
                clubs = empty.concat(clubs)
                setClubList(clubs)
                Loading.remove()
            } catch (error) {
                Loading.remove()
                Modal.error({
                    icon: <ExclamationCircleOutlined />,
                    content: error.data ? error.data.message : error.message,
                })
            }
        }
    }, [moralis])

    useEffect(() => {
        if (user) {
            setStep(user?.attributes.authStep)
            setAccountType(user?.attributes.role)
        }
        memoizedCallback(moralis)
    }, [memoizedCallback, moralis, user])

    const close = useCallback(() => {
        setContent(
            <AccountType
                title="Type of account"
                menuData={userRoles}
                setStep={setStep}
                setAccountType={setAccountType}
            />,
        )
        setAccountType("")
        setStep(-1)
        // setContent(null)
    }, [])

    const complete = useCallback(async (type) => {
        if (type == "admin" || type == "ath") {
            onAdminRequestHandler(type)
        } else {
            Loading.standard();
            user.set("authStep", 100);
            await user.save();
            close()
            Loading.remove();
            if (type == "game") {
                Modal.confirm({
                    title: 'YLG',
                    content: 'Do you want to Play Games?',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: () => {
                        history.push("/teams");
                    },
                    onCancel: () => {
                        history.push("/myaccount");
                    },
                });
            } else if (type == "advert") {
                history.push("/teams");
            } else {
                history.push("/myaccount");
            }
        }
    }, [user, close])

    const onAdminRequestHandler = async (type) => {
        Loading.standard()
        try {
            if (type == "admin") {
                await moralis?.fn.Cloud.run("forAdminRequest", {
                    address: user?.attributes.ethAddress,
                })
            } else {
                await moralis?.fn.Cloud.run("forAthRequest", {
                    address: user?.attributes.ethAddress,
                })
            }
            user.set("authStep", 50);
            await user.save();
            setStep(50)
            toast.success("Your request has been successfully sent.")
        } catch (error) {
            toast.error("Failed to send the request.")

        }
        Loading.remove()
    }

    const updateStep = async (index) => {
        Loading.standard();
        user.set("authStep", index);
        await user.save();
        setStep(index)
        Loading.remove();
    }

    useEffect(() => {
        if (accountType && accountType !== undefined && accountType !== "") {
            switch (accountType) {
                case "hs_adm":
                case "sc_adm":
                    switch (step) {
                        case 0:
                            setContent(<Club
                                title={accountType == "hs_adm" ? "Choose School" : "Choose Sport Club"}
                                clubList={clubList}
                                onClickHandler={() => { updateStep(1) }}
                                onBackHandler={() => { setStep(-1); setAccountType("") }}
                            />)
                            break;
                        case 1:
                            setContent(<Deposit
                                title="deposit"
                                desc="In order to become an administrator, you need to make a deposit."
                                label="insufficient funds to make a deposit"
                                onClickHandler={() => { updateStep(2); }}
                                onBackHandler={() => { setStep(0); }}
                            />)
                            break;
                        case 2:
                            setContent(<Notification
                                title="Creating list of athletes"
                                onClickHandler={() => { updateStep(3) }}
                                onBackHandler={() => { setStep(1); }}
                            />);
                            break;
                        case 3:
                            setContent(<Profile
                                title="profile Info"
                                type="rPicture"
                                accountType={accountType}
                                onClickHandler={() => { updateStep(4) }}
                                onBackHandler={() => { setStep(2); }}
                            />
                            )
                            break;
                        case 4:
                        case 50:
                            setContent(<DepositResult
                                title="Data is filled"
                                result="Successfully!"
                                type="admin"
                                step={step}
                                onClickHandler={() => { complete("admin") }}
                                onBackHandler={() => { setStep(3); }}
                            />);
                            break;
                        default:
                            break;
                    }
                    break;
                case "hs_ath":
                case "sc_ath":
                    switch (step) {
                        case 0:
                            setContent(<Club
                                title={accountType == "hs_ath" ? "Choose School" : "Choose Sport Club"}
                                clubList={clubList}
                                onClickHandler={() => { updateStep(1) }}
                                onBackHandler={() => { setStep(-1); setAccountType("") }}
                            />)
                            break;
                        case 1:
                            setContent(<Profile
                                title="profile Info"
                                type="rPicture"
                                accountType={accountType}
                                onClickHandler={() => { updateStep(2) }}
                                onBackHandler={() => { setStep(0); }}
                            />)
                            break;
                        case 2:
                        case 50:
                            setContent(<DepositResult
                                title="Data is filled"
                                result="Successfully!"
                                type="ath"
                                step={step}
                                onClickHandler={() => { complete("ath") }}
                                onBackHandler={() => { setStep(1); }}
                            />)
                            break;
                        default:
                            break;
                    }
                    break;
                case "in_ath":
                case "ylg_adm":
                    switch (step) {
                        case 0:
                            setContent(<Deposit
                                title="deposit"
                                desc="In order to become an administrator, you need to make a deposit."
                                label="insufficient funds to make a deposit"
                                onClickHandler={() => { updateStep(1); }}
                                onBackHandler={() => { setStep(-1); setAccountType("") }}
                            />)
                            break;
                        case 1:
                            setContent(<Profile
                                title="profile Info"
                                type="rPicture"
                                accountType={accountType}
                                onClickHandler={() => { updateStep(2) }}
                                onBackHandler={() => { setStep(0); }}
                            />)
                            break;
                        case 2:
                        case 50:
                            setContent(<DepositResult
                                title="Data is filled"
                                result="Successfully!"
                                type="admin"
                                step={step}
                                onClickHandler={() => { complete("admin") }}
                                onBackHandler={() => { setStep(1); }}
                            />)
                            break;
                        default:
                            break;
                    }
                    break;
                case "sponsor":
                    switch (step) {
                        case 0:
                            setContent(<Profile
                                title="profile Info"
                                type="fPicture"
                                accountType={accountType}
                                onClickHandler={() => { complete("game") }}
                                onBackHandler={() => { setStep(-1); setAccountType("") }}
                                bio={false}
                            />)
                            break;
                        default:
                            break;
                    }
                    break;
                case "advert":
                    switch (step) {
                        case 0:
                            setContent(<Profile
                                title="profile Info"
                                type="rPicture"
                                accountType={accountType}
                                onClickHandler={() => { complete("advert") }}
                                // onClickHandler={() => { updateStep(1); }}
                                onBackHandler={() => { setStep(-1); setAccountType("") }}
                                bio={false}
                            />)
                            break;
                        // case 1:
                        //     setContent(<Deposit
                        //         title="deposit"
                        //         desc="In order to become an administrator, you need to make a deposit."
                        //         label="insufficient funds to make a deposit"
                        //         onClickHandler={() => { updateStep(1); }}
                        //         onBackHandler={() => { setStep(-1); setAccountType("") }}
                        //     />)
                        //     break;
                        // case 2:
                        // case 50:
                        //     setContent(<DepositResult
                        //         title="Data is filled"
                        //         result="Successfully!"
                        //         type="admin"
                        //         step={step}
                        //         onClickHandler={() => { complete("admin") }}
                        //         onBackHandler={() => { setStep(1); }}
                        //     />)
                        //     break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        } else {
            setContent(
                <AccountType
                    title="Type of account"
                    menuData={userRoles}
                    setStep={setStep}
                    setAccountType={setAccountType}
                    onClose={close}
                />
            )
        }
    }, [clubList, accountType, step])

    return (
        <OuterContainer>
            {content}
        </OuterContainer>
    )
}

export default AuthFlow
