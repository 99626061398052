import { io } from 'socket.io-client'
import React, { createContext, useState } from 'react'
export const SocketContext = createContext({})

export const SocketProvider = (props) => {
    var addrss = process.env.REACT_APP_BACK_SERVICE
    const [socket] = useState(io(addrss))

    return (
        <SocketContext.Provider value={{ socket }}>
            {props.children}
        </SocketContext.Provider>
    )
}
