import { useCallback, useEffect, useRef, useState, useContext } from "react"
import { useMoralis, useMoralisWeb3Api } from "react-moralis"
import { toast } from "react-hot-toast"
import {
  AccountBalance,
  NFTContainer,
  NFTItemContainer,
  AccountBalanceContainer,
  AccountInfoDetailsStyling,
  ArrowRight,
  BalanceContainer,
  ButtonTopUp,
  Camera,
  CashBalance,
  FirstRow,
  LeftSide,
  MyNFTCardsContainer,
  MyNFTCardsImage,
  NFTCardsTitle,
  NFTTitleContainer,
  ProfileImage,
  CameraContainer,
  SettingButton,
  SettingButtonContainer,
  ProfileImageContainer,
  ProfileName,
  ChooseAccountText,
  TokenBalance,
  TokenSymbol,
} from "./styles/AccountInfoDetailsStyling"
import { SettingOutlined, ShareAltOutlined } from "@ant-design/icons"
import { Modal, Input } from 'antd';
import Logo from "../../../images/yourlife_white.png"
import { Loading } from "notiflix"
import { useHistory } from "react-router"
import { userRoles } from "utils/dummyData"
import { Row, Col } from 'antd';
import { getGroupAssign, getComissionByAdmin, getEscrowAmount, withdrawEscrow } from "utils/helpers/proxy"
import { useSwapRate } from "hooks/useSwapRate"
import { walletSimpleNFTDetail } from "utils/function"

function AccountInfoDetails({ profileName, moralis }) {
  const history = useHistory()
  const rate = useSwapRate()
  const cameraInpRef = useRef(null)
  const [cameraClick, setCameraClick] = useState(false)
  const [yltBalance, setYLTBalance] = useState(0)
  const [comissonBalance, setComissionBalance] = useState(0)
  const { Moralis, user } = useMoralis()
  const Web3Api = useMoralisWeb3Api()
  const [clubData, setClubData] = useState(null)
  const [profilePicture, setProfilePicture] = useState(null)
  const [tempProfileName, setTempProfileName] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [nfts, setNFTs] = useState([]);
  const [superComission, setSuperComission] = useState(0)
  const [adminComission, setAdminComission] = useState(0)
  const [athleteComission, setAthleteComission] = useState(0)


  const memoizedCallback = useCallback(async () => {
    if (user) {
      Loading.standard()
      if (user?.attributes.club) {
        let res = await Moralis.Cloud.run("getClub", { id: user?.attributes.club, })
        setClubData(res)
        try {
          if (user?.attributes.role != "sponsor") {
            const groupAdmin = await Moralis.executeFunction(getGroupAssign(user?.attributes.ethAddress))
            if (groupAdmin) {
              const comission = await Moralis.executeFunction(getComissionByAdmin(groupAdmin))
              if (comission.length > 0) {
                setSuperComission(Moralis.Units.Token(comission[0], "0") / 100)
                setAdminComission(Moralis.Units.Token(comission[1], "0") / 100)
                setAthleteComission(Moralis.Units.Token(comission[2], "0") / 100)
              }
            }
          }

          const com = await Moralis.executeFunction(getEscrowAmount(user?.attributes.ethAddress))
          if (com) {
            setComissionBalance(Math.floor(Moralis.Units.FromWei(com)))
          }
        } catch (error) {
          console.log(error)
        }
      }
      const mOpt = {
        chain: "bsc testnet",
        address: user?.attributes.ethAddress,
        token_addresses: [
          process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS,
          process.env.REACT_APP_YLNFT1155_CONTRACT_ADDRESS,
        ],
      }
      let res = await Moralis.Web3API.account.getNFTs(mOpt)
      res = res?.result.filter(nft => nft.token_uri)
      const mintedNFT = await Promise.all(
        res?.map(async (item) => walletSimpleNFTDetail(Moralis, item)),
      )
      setNFTs(mintedNFT)
      const bal = await Web3Api.account.getTokenBalances({
        address: user.attributes.ethAddress,
        chain: "bsc testnet",
        token_addresses: [process.env.REACT_APP_YLT_CONTRACT_ADDRESS],
      })
      if (bal[0]?.balance) {
        setYLTBalance(Math.floor(Moralis.Units.FromWei(bal[0].balance)))
      }
      Loading.remove()
    }
  }, [Web3Api.account, user, Moralis.Units])

  useEffect(() => {
    memoizedCallback()
  }, [memoizedCallback])

  useEffect(() => {
    if (cameraClick) {
      cameraInpRef.current.click()
      setCameraClick(false)
    }
  }, [cameraClick])
  const handleFileInput = async () => {
    if (cameraInpRef.current.files.length > 0) {
      const img = cameraInpRef.current.files[0]
      const file = new Moralis.File(img.name, img)
      await file.saveIPFS()
      user.set("profile_picture", file._ipfs)
      await user.save()
      setProfilePicture(file._ipfs)
      toast.success("Your profile picture has been updated successfully", { position: 'top-right' })
    }
  }

  const editProfile = async () => {
    if (user.attributes.nickname) {
      await user.save()
      setTempProfileName(user.attributes.nickname)
      toast.success("Your nickname has been updated successfully", { position: 'top-right' })
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onAdminRequestHandler = async () => {
    Loading.standard()
    try {
      await Moralis.Cloud.run("forAdminRequest", {
        address: user?.attributes.ethAddress,
      })
      toast.success("Your request has been successfully sent.")
    } catch (error) {
      toast.error("Failed to send the request.")

    }
    Loading.remove()
  }

  const handleWithdraw = async () => {
    Loading.standard()
    try {
      const amount = Moralis.Units.Token(comissonBalance, "18")
      const transaction = await Moralis.executeFunction(withdrawEscrow(amount))
      await transaction.wait()
      location.reload()
    } catch (error) {
      console.log(error)
      toast.error("Withdraw Failed, Please try again")
    }
    Loading.remove()
  }

  return (
    <AccountInfoDetailsStyling>
      <Modal
        title="Please enter a new nickname"
        open={isModalOpen}
        onOk={editProfile}
        onCancel={handleCancel}
        okText="Save  "
      >
        <Input placeholder="Nickname" onChange={(e) => user.set("nickname", e.target.value)} />
      </Modal>
      <Row gutter={[24, 16]}>
        <Col xs={24}>
          <FirstRow>
            <LeftSide>
              <ProfileImageContainer>
                <ProfileImage
                  src={
                    profilePicture ||
                    user?.attributes.profile_picture ||
                    require("../../../images/account/Ellipse 58.png").default
                  }
                />
                <CameraContainer>
                  <Camera
                    src={require("../../../images/account/camera.svg").default}
                    onClick={() => setCameraClick(true)}
                  />
                </CameraContainer>
              </ProfileImageContainer>
              <input
                type="file"
                style={{ display: "none" }}
                ref={cameraInpRef}
                accept="image/*"
                onChange={handleFileInput}
              />
              <ProfileName>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p>{tempProfileName || profileName}</p>
                  {clubData &&
                    <>
                      <BalanceContainer>
                        <TokenSymbol
                          src={clubData?.attributes.icon}
                        />
                        <ChooseAccountText style={{ fontSize: "16px" }}>{clubData?.attributes.name}</ChooseAccountText>
                      </BalanceContainer>
                      <ChooseAccountText>({userRoles.filter(item => item.value == user?.attributes.role)[0]?.title})</ChooseAccountText>
                    </>
                  }
                </div>
                {user?.attributes.role != "sponsor" && user?.attributes.role != "advert" &&
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ChooseAccountText style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}>Commission Rate</ChooseAccountText>
                    <ChooseAccountText>{athleteComission}%(Athlete):{adminComission}%(Admin):{superComission}%(YLG)</ChooseAccountText>
                  </div>
                }

                {user?.attributes.role == "advert" &&
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <ProfileImage
                      src={
                        user?.attributes.bLogo ||
                        require("../../../images/account/Ellipse 58.png").default
                      }
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <ChooseAccountText style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}>{user?.attributes.bName}</ChooseAccountText>
                      <ChooseAccountText>{user?.attributes.cName}</ChooseAccountText>
                      <ChooseAccountText>{user?.attributes.cEmail}</ChooseAccountText>
                      <ChooseAccountText>{user?.attributes.cPhone}</ChooseAccountText>
                      <ChooseAccountText>{user?.attributes.bAddress}</ChooseAccountText>
                    </div>
                  </div>
                }
              </ProfileName>
            </LeftSide>
            <SettingButtonContainer style={{ marginRight: '10px' }}>
              <SettingButton onClick={() => {
                const url = "localhost:3000/shareprofile/" + user.id
                navigator.clipboard.writeText(url)
              }}>
                Share  <ShareAltOutlined />
              </SettingButton>
            </SettingButtonContainer>
            {/* <SettingButtonContainer>
              <SettingButton>
                Settings <SettingOutlined />
              </SettingButton>

            </SettingButtonContainer> */}

          </FirstRow>
        </Col>
        <Col xs={24} xl={12}>
          <AccountBalanceContainer>
            <AccountBalance>
              <TokenBalance>Wallet</TokenBalance>
              <BalanceContainer>
                <TokenSymbol
                  src={Logo}
                />
                <TokenBalance>{yltBalance}</TokenBalance>
              </BalanceContainer>
              <CashBalance>(${yltBalance / rate})</CashBalance>
              <ButtonTopUp href="https://swapstaging.yourlifegames.com" target="_blank">
                swap
              </ButtonTopUp>
            </AccountBalance>
          </AccountBalanceContainer>
        </Col>
        <Col xs={24} xl={12}>
          <AccountBalanceContainer>
            <AccountBalance>
              <TokenBalance>Escrow</TokenBalance>
              <BalanceContainer>
                <TokenSymbol
                  src={Logo}
                />
                <TokenBalance>{comissonBalance}</TokenBalance>
              </BalanceContainer>
              <CashBalance>(${comissonBalance / rate})</CashBalance>
              {comissonBalance != null && comissonBalance > 0 && <ButtonTopUp onClick={handleWithdraw}>Withdraw Escrow</ButtonTopUp>}
            </AccountBalance>
          </AccountBalanceContainer>
        </Col>
        {/* <Col xs={24} xl={24} xxl={8}>
          <MyNFTCardsContainer>
            <NFTTitleContainer>
              <NFTCardsTitle>my collection</NFTCardsTitle>
              <ArrowRight src={require("../../../images/account/arrowRight.svg").default} onClick={() => history.push("/collection")} />
            </NFTTitleContainer>
            <NFTContainer>
            {
              nfts.map((item, key) => (
                <NFTItemContainer to={`/admin/mintedNfts/${item.owner}/${item.address}/${item.id}`} key={key}>
                  <MyNFTCardsImage src={item.imgSrc} alt="Collection" />
                </NFTItemContainer>
              ))
            }
            </NFTContainer>
          </MyNFTCardsContainer>
        </Col> */}
      </Row>
    </AccountInfoDetailsStyling>
  )
}

export default AccountInfoDetails
