import { useCallback, useEffect, useState } from "react"
import { useMoralis, useMoralisWeb3Api } from "react-moralis"
import {
  AccountInfoDetailsStyling,
  NFTContainer,
  NFTItemContainer,
  MyNFTCardsContainer,
  MyNFTCardsImage,
  NFTCardsTitle,
  NFTTitleContainer,
} from "./styles/AccountInfoDetailsStyling"
import { Loading } from "notiflix"
import { Row, Col } from 'antd';
import { vaultNftDetail, walletSimpleNFTDetail } from "utils/function"

function AccountNFTs({ moralis }) {
  const { Moralis, user } = useMoralis()
  const Web3Api = useMoralisWeb3Api()
  const [nfts, setNFTs] = useState([]);
  const [allocatedNFTs, setAllocatedNFTs] = useState([]);


  const memoizedCallback = useCallback(async () => {
    if (user) {
      Loading.standard()
      const nfts = await Moralis.Cloud.run("getNFTsFromMain", { gamer: user.attributes.ethAddress, })
      const mainNFT721 = nfts.data_nft721;
      const mainNFT1155 = nfts.data_nft1155;
      let result = mainNFT721.concat(mainNFT1155)
      const listedNFT = await Promise.all(
        result?.map(async (item) => vaultNftDetail(Moralis, item)),
      )
      setAllocatedNFTs(listedNFT)      
      const mOpt = {
        chain: "bsc testnet",
        address: user?.attributes.ethAddress,
        token_addresses: [
          process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS,
          process.env.REACT_APP_YLNFT1155_CONTRACT_ADDRESS,
        ],
      }
      let res = await Moralis.Web3API.account.getNFTs(mOpt)
      res = res?.result.filter(nft => nft.token_uri)
      const mintedNFT = await Promise.all(
        res?.map(async (item) => walletSimpleNFTDetail(Moralis, item)),
      )
      
      setNFTs(mintedNFT)
      Loading.remove()
    }
  }, [Web3Api.account, user, Moralis.Units])

  useEffect(() => {
    memoizedCallback()
  }, [memoizedCallback])

  return (
    <AccountInfoDetailsStyling style={{ marginTop: "5rem" }}>
      <Row gutter={[24, 16]}>
        <Col xs={24} md={12}>
          <MyNFTCardsContainer>
            <NFTTitleContainer>
              <NFTCardsTitle>my nfts</NFTCardsTitle>
              <p>{nfts.length} NFTs</p>
            </NFTTitleContainer>
            <NFTContainer>
              {
                nfts.map((item, key) => (
                  <NFTItemContainer to={`/admin/mintedNfts/${item.owner}/${item.address}/${item.id}`} key={key}>
                    <MyNFTCardsImage src={item.imgSrc} alt="Collection" />
                  </NFTItemContainer>
                ))
              }
            </NFTContainer>
          </MyNFTCardsContainer>
        </Col>
        <Col xs={24} md={12}>
          <MyNFTCardsContainer>
            <NFTTitleContainer>
              <NFTCardsTitle>allocated nfts</NFTCardsTitle>
              <p>{allocatedNFTs.length} NFTs</p>
            </NFTTitleContainer>
            <NFTContainer>
              {
                allocatedNFTs.map((item, key) => (
                  <NFTItemContainer to={`/admin/mintedNfts/${item.owner}/${item.address}/${item.id}`} key={key}>
                    <MyNFTCardsImage src={item.imgSrc} alt="Collection" />
                  </NFTItemContainer>
                ))
              }
            </NFTContainer>
          </MyNFTCardsContainer>
        </Col>
      </Row>
    </AccountInfoDetailsStyling>
  )
}

export default AccountNFTs
