import React, { useEffect, useState } from "react"
import axios from "axios"
import {
  NFTButton,
  ButtonsContainer,
  ButtonsContainerBottom,
  DescriptionText,
  DescriptionTitle,
  DescriptionWrapper,
  IconsContainer,
  LeftSideContainer,
  ProfileContainer,
  MainContainer,
  NftCardImage,
  NftCardName,
  PriceTitle,
  PriceValue,
  PriceValueContainer,
  RightFirstInnerContainer,
  RightSideContainer,
  SalesInfo,
  SalesInfoContainer,
  SalesInfoValueContainer,
  SpecCard,
  SpecsContainer,
  SpecTitle,
  StatsContainer,
  StatsInnerContainer,
  Tags,
  TokenImage,
  TopInnerContainer,
  TopSection,
  ViewCollectionBtn,
} from "./styles/NftDetailsStyles"
import tokenImage from "../../images/yourlife_black.png"
import { IconImg, StatItem, WeightText } from "./styles/NftCardStyling"
import {
  ExportOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  HeartOutlined,
  MoreOutlined,
  RedoOutlined,
  ShareAltOutlined,
  TableOutlined,
  TeamOutlined,
} from "@ant-design/icons"
import { Button, Icon } from "antd"
import { HistoryExpandable } from "./HistoryExpandable"
import { ListingsExpandable } from "./ListingsExpandable"
import { OffersExpandable } from "./OffersExpandable"
import ItemActivity from "./ItemActivity"
import { OffersFullExpandable } from "./OffersFullExpandable"
import { useMoralis } from "react-moralis"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useHistory } from "react-router"
import { formatNumber, getRandomDate } from "../../utils/function"
import { useSwapRate } from "hooks/useSwapRate"

const PlayerStat = ({ iconType, weight }) => {
  return (
    <StatItem>
      <IconImg
        src={require(`../../images/account/collection/${iconType}.svg`).default}
      />
      <WeightText>{weight}</WeightText>
    </StatItem>
  )
}

export const NftDetails = ({ moralis }) => {
  const history = useHistory()
  const rate = useSwapRate()
  const { user, isAuthenticated } = useMoralis()
  const [nftDetail, setNFTDetail] = useState({})
  const ButtonGroup = Button.Group;
  useEffect(() => {
    if (moralis?.fn) {
      const url = document.URL.split("/")
      const owner = url[url.length - 3]
      const address = url[url.length - 2]
      const id = url[url.length - 1]
      const isERC721 = address == process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS
      moralis?.fn.Cloud.run("GetTokenData", { tokenId: id, owner: owner, isERC721: isERC721 }).then(async (res) => {
        if (res) {
          const meta = res.meta
          const imgSrc = meta.data.image
          const personalInfo = meta.data.personal
          const price = moralis?.fn.Units.FromWei(res.attributes.price.toString())
          const dprice = moralis?.fn.Units.FromWei(res.attributes.price.toString()) / 100
          //random info
          const info = [
            {
              id: "1",
              iconType: <TeamOutlined />,
              title: formatNumber(Math.floor(Math.random() * 1000)) + " owners",
            },
            {
              id: "2",
              iconType: <TableOutlined />,
              title: formatNumber(Math.floor(Math.random() * 10000000)) + " TOTAL",
            },
            {
              id: "3",
              iconType: <EyeOutlined />,
              title: formatNumber(Math.floor(Math.random() * 1000000)) + " views",
            },
            {
              id: "4",
              iconType: <HeartOutlined />,
              title: formatNumber(Math.floor(Math.random() * 1000)) + "FAVORITES",
            },
          ]
          //random sale end time
          const startDate = new Date('2023-03-01');
          const endDate = new Date('2023-12-31');
          const randomDate = getRandomDate(startDate, endDate);
          const type = res.attributes.type
          let brickColor = "#3985f5"
          if (type == "auction") {
            brickColor = "#C9C9C9"
          } else if (type == "offer") {
            brickColor = "#0D51FF"
          } else if (type == "bundle") {
            brickColor = "#B20DFF"
          } else if (type == "marketplace") {
            brickColor = "#F7A300"
          }
          const nftData = {
            id: id,
            isERC721,
            type,
            imgSrc: imgSrc,
            sport: meta.data.sport,
            title: meta.data.name,
            description: meta.data.description,
            price: price, //BNB
            priceUSD: dprice,
            personal: personalInfo,
            dexterity: personalInfo && personalInfo.energy,
            stamina: personalInfo && personalInfo.luck,
            dribbling: personalInfo && personalInfo.power,
            speed: personalInfo && personalInfo.speed,
            finishing: personalInfo && personalInfo.wizzardy,
            brickColor: brickColor,
            address: address,
            info: info,
            saleEndDate: randomDate,
            meta: meta.data,
          }
          console.log(nftData)
          setNFTDetail(nftData)
        }
      })
    }
  }, [moralis?.fn, isAuthenticated])
  return (
    <ProfileContainer>
      <MainContainer>
        <div style={{ marginBottom: "20px" }} onClick={() => history.goBack()}><ArrowLeftOutlined style={{ marginRight: "10px" }} />Back</div>
        <TopInnerContainer>
          {/* Left Column */}
          <LeftSideContainer>
            <NftCardImage src={nftDetail.imgSrc} alt="image" />
            <DescriptionWrapper>
              <DescriptionTitle>DESCRIPTION</DescriptionTitle>
              <DescriptionText>{nftDetail.description}</DescriptionText>
              {nftDetail.personal &&
                <>
                  <SpecTitle>Specifications</SpecTitle>
                  <SpecsContainer>
                    {nftDetail.speed !== undefined && (
                      <PlayerStat iconType="Run" weight={nftDetail.speed} />
                    )}
                    {nftDetail.dexterity !== undefined && (
                      <PlayerStat iconType="Wheel" weight={nftDetail.dexterity} />
                    )}
                    {nftDetail.stamina !== undefined && (
                      <PlayerStat iconType="Stamina" weight={nftDetail.stamina} />
                    )}
                    {nftDetail.dribbling !== undefined && (
                      <PlayerStat iconType="Run" weight={nftDetail.dribbling} />
                    )}
                    {nftDetail.finishing !== undefined && (
                      <PlayerStat iconType="Wheel" weight={nftDetail.finishing} />
                    )}
                  </SpecsContainer>
                </>
              }
            </DescriptionWrapper>
          </LeftSideContainer>

          {/* Right Column */}
          <RightSideContainer>
            {/* Top Section */}
            <TopSection>
              <IconsContainer>
                <ButtonGroup>
                  <Button style={{ borderRadius: "8px 0 0 8px", width: "40px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}><RedoOutlined /></Button>
                  <Button style={{ width: "40px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}><ExportOutlined /></Button>
                  <Button style={{ width: "40px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}><ShareAltOutlined /></Button>
                  <Button style={{ borderRadius: "0px 8px 8px 0", width: "40px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}><MoreOutlined /></Button>
                </ButtonGroup>
              </IconsContainer>
            </TopSection>
            <NftCardName>{nftDetail.title ?? "No name"}</NftCardName>
            <StatsContainer>
              {nftDetail.info && nftDetail.info.map((item) => (
                <StatsInnerContainer key={item.id}>
                  <div>{item.iconType}</div>
                  <div>{item.title}</div>
                </StatsInnerContainer>
              ))}
            </StatsContainer>

            {/* 1st card */}
            {
              nftDetail.type != "minted" && <RightFirstInnerContainer>
                <SalesInfoContainer>
                  <FieldTimeOutlined />
                  <SalesInfo>Sales end {nftDetail.saleEndDate}</SalesInfo>
                </SalesInfoContainer>
                <SalesInfoValueContainer>
                  <PriceTitle>current price</PriceTitle>
                  <PriceValueContainer>
                    <TokenImage src={tokenImage} alt="tokenImage" />
                    <PriceValue>{nftDetail.price}</PriceValue>
                    <PriceValue>($ {nftDetail.priceUSD})</PriceValue>
                  </PriceValueContainer>
                </SalesInfoValueContainer>
                <ButtonsContainer>
                  {/* <NFTButton>buy now</NFTButton> */}
                </ButtonsContainer>
              </RightFirstInnerContainer>
            }

            {/* 2nd card */}
            <HistoryExpandable />

            {/* 3rd card */}
            <ListingsExpandable />

            {/* 4th Card */}
            <OffersExpandable />
          </RightSideContainer>
        </TopInnerContainer>

        {/* 5th Card */}
        <ItemActivity />

        {/* 6th Card */}
        {/* <OffersFullExpandable /> */}
      </MainContainer>
    </ProfileContainer>
  )
}
