import React, { useContext, useState, useEffect } from "react"
import { CollectionNftCard } from "./CollectionNftCard"
import { LoadingOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { Spin, Modal } from "antd"
import {
  FilterButton,
  Input,
  MainContainer,
  MainTitle,
  NftContainer,
  SearchButton,
  Select,
  StatContainer,
  StatCounter,
  TopContainer,
  TopInnerContainer,
  TopLeftContainer,
  TopRightContainer,
  BackBtn,
} from "./styles/AllNftStyling"
import { DappContext } from "context"
import { CreateOffer } from "components/Sidebar/CreateOffer"
import { useMoralis } from "react-moralis"
import { useSwapRate } from "hooks/useSwapRate"
import { vaultNftDetail, walletNFTDetail } from "utils/function"

export const Collection = ({ moralis }) => {
  const { user } = useMoralis()
  const [isLoading, setLoading] = useState(true)
  const [filterSelected, setFilterSelected] = useState("all")
  const [onceLoad, setOnceLoad] = useState(true)
  const [active, setActive] = useState("1")
  const [inputField, setInputField] = useState("")
  const [walletNfts, setWalletNfts] = useState([])
  const [vaultNfts, setVaultNfts] = useState([])
  const [nfts, setNfts] = useState([])
  const [number, setNumber] = useState(0)
  const { onCloseSidebar, setSidebarContent, setOpenSidebar } = useContext(DappContext)
  const rate = useSwapRate()
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  const handleClick = (e) => {
    if (isLoading) {
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: "Loading...",
      })
    } else {
      setFilterSelected(e.target.value)
      setActive(e.target.id)
    }
  }

  const handleInputChange = (e) => {
    setInputField(e.target.value)
  }

  const handleOfferClick = () => {
    setSidebarContent(<CreateOffer closeSidebar={onCloseSidebar} />)
    setOpenSidebar(true)
  }

  useEffect(() => {
    const loadNFTs = async () => {
      if (moralis) {
        const nfts = await moralis?.fn.Cloud.run("getNFTsFromMain", { gamer: user.attributes.ethAddress, })
        const mainNFT721 = nfts.data_nft721;
        const mainNFT1155 = nfts.data_nft1155;
        let result = mainNFT721.concat(mainNFT1155)
        const listedNFT = await Promise.all(
          result?.map(async (item) => vaultNftDetail(moralis?.fn, item)),
        )
        setVaultNfts(listedNFT)
        const mOpt = {
          chain: "bsc testnet",
          address: user?.attributes.ethAddress,
          token_addresses: [
            process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS,
            process.env.REACT_APP_YLNFT1155_CONTRACT_ADDRESS,
          ],
        }
        let res = await moralis?.fn.Web3API.account.getNFTs(mOpt)
        res = res?.result.filter(nft => nft.token_uri)
        const mintedNFT = await Promise.all(
          res?.map(async (item) => walletNFTDetail(moralis?.fn, item, rate)),
        )
        setWalletNfts(mintedNFT)

        const allNFTs = listedNFT.concat(mintedNFT)
        setNumber(allNFTs.length)
        setNfts(allNFTs)
        setLoading(false)
      }
    }
    if ((moralis && onceLoad)) {
      setLoading(true)
      loadNFTs()
      setOnceLoad(false)
    }
  }, [moralis, onceLoad, user?.attributes.ethAddress])

  useEffect(() => {
    if (filterSelected == "all") {
      const allNFTs = walletNfts.concat(vaultNfts)
      setNumber(allNFTs.length)
      setNfts(allNFTs)
    } else if (filterSelected == "wallet") {
      setNumber(walletNfts.length)
      setNfts(walletNfts)
    } else if (filterSelected == "vault") {
      setNumber(vaultNfts.length)
      setNfts(vaultNfts)
    } else if (filterSelected == "team") {
      setNumber(vaultNfts.length)
      setNfts(vaultNfts)
    }
  }, [filterSelected])

  return (
    <MainContainer>
      <BackBtn>back</BackBtn>
      <TopContainer>
        <MainTitle>Collection</MainTitle>
      </TopContainer>
      <TopInnerContainer>
        <TopLeftContainer>
          <FilterButton
            id="1"
            active={active}
            onClick={handleClick}
            value="all"
          >
            All
          </FilterButton>
          <FilterButton
            id="2"
            active={active}
            onClick={handleClick}
            value="wallet"
          >
            Wallet
          </FilterButton>
          <FilterButton
            id="3"
            active={active}
            onClick={handleClick}
            value="vault"
          >
            Vault
          </FilterButton>
          <FilterButton
            id="4"
            active={active}
            onClick={handleClick}
            value="team"
          >
            Team
          </FilterButton>
        </TopLeftContainer>
        <TopRightContainer>
          <Input
            value={inputField}
            onChange={handleInputChange}
            placeholder="Search"
          />
          <SearchButton>filters</SearchButton>
        </TopRightContainer>
      </TopInnerContainer>
      <StatContainer>
        {isLoading ? (
          <Spin indicator={antIcon} />
        ) : (
          <StatCounter>{number} NFT</StatCounter>
        )}

        <Select defaultValue="FILTER NEW ONES">
          <option value="filter new ones">FILTER NEW ONES</option>
          <option value="filter old ones">FILTER OLD ONES</option>
        </Select>
      </StatContainer>
      {!isLoading ? (
        <NftContainer>
          {nfts.map((card, index) => (
            <CollectionNftCard key={index} card={card} moralis={moralis} />
          ))}
        </NftContainer>
      ) : (
        <Spin
          style={{ height: "500px", width: "100%", paddingTop: "150px" }}
          tip="Loading..."
        ></Spin>
      )}
    </MainContainer>
  )
}
