import { useState, useEffect, useCallback } from "react"
import { useMoralis } from "react-moralis"
import { useHistory } from "react-router"
import { Modal, Button } from "antd"
import Text from "antd/lib/typography/Text"
import AccountAchievements from "./AccountAchievements"
import AccountInfoDetails from "./AccountInfoDetails"
import AccountStats from "./AccountStats"
import { Link } from "react-router-dom"
import {
  AccountContainerStyling,
  PrizesTitle,
  SecondRowContainerStyling,
  ThirdRowTitle,
  TournanemntImage,
} from "./styles/AccountElementsStyling"
import AccountNFTs from "./AccountNFTs"
import TournamentPosition from "./TournamentPosition"
import {
  ConnectorContainer,
  ConnectorInnerContainer,
  Icon,
  InnerContainer,
} from "components/Account/styles/AccountElements"
import { connectors } from "components/Account/config"
import { Row, Col, Card, Typography } from 'antd';
import { Loading } from "notiflix"
import "./styles/style.css"


const CoachesRoom = ({ nickname, moralis }) => {
  const [active, setActive] = useState(false)
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false)
  const { user, isAuthenticated, account, authenticate } = useMoralis()
  const [teambooster, setTeambooster] = useState([])
  const [vault, setVault] = useState([])
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const history = useHistory()
  const readTeams = useCallback(async () => {
    try {
      Loading.standard()

      const v = await moralis?.fn.Cloud.run("getTeamData", {
        wallet: user.attributes.ethAddress,
      })

      const bData = []
      v.data_booster.forEach(e => {
        const attr = e.attributes
        bData.push(attr)
      });
      setTeambooster(bData)

      const vData = []
      v.data_team.forEach((e) => {
        const attr = e.attributes
        const t = {
          subaddress: attr.subaddress,
          teamname: attr.teamname,
          logo: attr.logo,
          vaultaddress: attr.address,
          category: attr.category,
          from: attr.from,
          gamer: attr.gamer,
          createdTime: attr.createdTime,
        }
        vData.push(t)
      })

      setVault(vData)
      Loading.remove()
    } catch (e) {
      Loading.remove()
      console.log(e)
    }
  }, [moralis, user])

  useEffect(() => {
    if (
      moralis != null &&
      user?.attributes.ethAddress != undefined &&
      loaded == false
    ) {
      setLoaded(true)
      readTeams()
    }
  }, [moralis, user?.attributes.ethAddress])

  useEffect(() => {
    if (user) {
      if (user?.attributes.isSuperAdmin == true) {
        return window.location.replace("/admin/super")
      }
      if (user?.attributes.isAdmin) {
        return window.location.replace("/admin")
      }

      if (user?.attributes.authStep != 100) {
        alert("Please complete your authentication flow.")
        return window.location.replace("/authflow")
      }
    }
  }, [user])

  const isBoostered = (subaddress) => {
    console.log(subaddress)
    console.log(teambooster)
    var boostered = false;
    for (var i = 0; i < teambooster.length; i++) {
      if (teambooster[i].vault === subaddress) {
        boostered = true;
      }
    }
    console.log(boostered)
    return boostered;
  }

  if (!isAuthenticated || !account) {
    return (
      <>
        <Modal
          open={isAuthModalVisible}
          footer={null}
          onCancel={() => setIsAuthModalVisible(false)}
          bodyStyle={{
            padding: "15px",
            fontSize: "17px",
            fontWeight: "500",
          }}
          style={{ fontSize: "16px", fontWeight: "500", zIndex: 100 }}
          width="340px"
        >
          <InnerContainer>Connect Wallet</InnerContainer>
          <ConnectorContainer>
            {connectors.map(({ title, icon, connectorId }, key) => (
              <ConnectorInnerContainer
                key={key}
                onClick={async () => {
                  try {
                    await authenticate({ provider: connectorId })
                    window.localStorage.setItem("connectorId", connectorId)
                    setIsAuthModalVisible(false)
                  } catch (e) {
                    console.error(e)
                  }
                }}
              >
                <Icon src={icon} alt={title} />
                <Text style={{ fontSize: "14px" }}>{title}</Text>
              </ConnectorInnerContainer>
            ))}
          </ConnectorContainer>
        </Modal>
      </>
    )
  }

  return (
    <AccountContainerStyling>
      {/* 1st Row */}
      <Row gutter={[24, 16]}>
        <Col xs={24} md={24} xl={12}>
          <AccountInfoDetails profileName={nickname} moralis={moralis} />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <AccountStats />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <AccountAchievements />
        </Col>
      </Row>

      <AccountNFTs moralis={moralis} />

      {(user?.attributes.role == "sponsor" || user?.attributes.role == "advert") &&
        <>
          {/* <SecondRowContainerStyling>
            <Col md={24} xl={18}>
              <TournanemntImage
                src={require("../../../images/account/BG_tournament.png").default}
              />
            </Col>
            <Col md={24} xl={6}>
              <Row gutter={[24, 16]} style={{ padding: "0 1rem" }}>
                <Col xs={24}>
                  <PrizesTitle>prizes</PrizesTitle>
                </Col>
                <Col xs={24} md={8} xl={24}>
                  <TournamentPosition color="#FF6D03" position="1" prize="100000" />
                </Col>
                <Col xs={24} md={8} xl={24}>
                  <TournamentPosition color="#D8DEFF" position="2" prize="50000" />
                </Col>
                <Col xs={24} md={8} xl={24}>
                  <TournamentPosition color="#C14600" position="3" prize="15000" />
                </Col>
                <Col xs={24} md={12} xl={24}>
                  <Button type="primary" style={{ width: "100%" }} size="large">ENROLL</Button>
                </Col>
                <Col xs={24} md={12} xl={24}>
                  <Button type="primary" ghost style={{ width: "100%" }} size="large">ALL TOURNAMENTS</Button>
                </Col>
              </Row>
            </Col>
          </SecondRowContainerStyling> */}
          <Row justify="space-between" align="middle" className="maring-top-40">
            <Col xs={24} md={24} className="manage-wrap">
              <ThirdRowTitle>{user?.attributes.role == "advert" ? "my ads" : "my teams"}</ThirdRowTitle>
              <Button
                type="primary"
                block
                className="team-manage-btn"
                onClick={() => history.push("/teams")}
              >
                MANAGE
              </Button>
            </Col>
          </Row>
          <Row className="maring-top-20">
            {loading == false && vault.length > 0 && vault.map((item, index) => {
              return (
                <Col
                  key={index}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  className="padding-10"
                >
                  <Link to={"/teams/" + item.subaddress + "/" + item.teamname}>
                    <Card bordered={false} className="team-card">
                      <div className="booster-active-wrap">
                        {
                          isBoostered(item.subaddress) ?
                            <Typography className="booster-active">
                              booster activeated
                            </Typography> : <></>
                        }
                      </div>
                      <img
                        className="team-logo"
                        src={item.logo}
                        alt="YTL_team_logo"
                      />
                      <div className="card-title">{item.teamname}</div>
                    </Card>
                  </Link>
                </Col>
              )
            })}
          </Row>
        </>
      }
    </AccountContainerStyling>
  )
}

export default CoachesRoom
