import React from "react"
import {
  FriendName,
  FriendRowStyling,
  IconAction,
  IconsContainer,
  ProfileImage,
  RowStyling,
} from "./styles/AccountAchievementStyling"
import profileImage from "images/default-avatar.svg"

export const FriendRow = ({
  user,
  isSelected,
}) => {
  return (
    <FriendRowStyling>
      <RowStyling isSelected={isSelected}>
        <ProfileImage
          src={user?.attributes.profile_picture || profileImage}
          alt="badgeIcon"
        />
        <FriendName>{user?.attributes.nickname}</FriendName>
        <IconsContainer>
          <IconAction
            src={
              require(`../../../images/account/Friends_trade_icon.svg`)
                .default
            }
            alt="Friends_trade_icon"
          />
          <IconAction
            src={
              require(`../../../images/account/Friends_chat_icon.svg`).default
            }
            alt="Friends_chat_icon"
          />
          <IconAction
            src={
              require(`../../../images/account/Friends_delete_icon.svg`)
                .default
            }
            alt="Friends_delete_icon"
          />
        </IconsContainer>
      </RowStyling>
    </FriendRowStyling>
  )
}
