import { useState } from 'react';
import { Table } from 'antd';
import { AdminAvatarImage, DescContainer, LevelText } from "screens/Rating/RatingStyling"
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import profileImage from "images/avatar.png"
const { Column, ColumnGroup } = Table;

const data = [
    {
        key: 1,
        name: 'Матвейка Гвоздедер',
        icon: null,
        mark: 22,
        price: 43434543,
        score: 432,
    },
    {
        key: 2,
        name: 'Матвейка Гвоздедер',
        icon: null,
        mark: -1,
        price: 43434543,
        score: -432,
    },
    {
        key: 3,
        name: 'Матвейка Гвоздедер',
        icon: null,
        mark: 5,
        price: 43434543,
        score: 432,
    },
    {
        key: 4,
        name: 'Матвейка Гвоздедер',
        icon: null,
        mark: 8,
        price: 43434543,
        score: 432,
    },
    {
        key: 5,
        name: 'Матвейка Гвоздедер',
        icon: null,
        mark: -4,
        price: 43434543,
        score: -432,
    },
    {
        key: 6,
        name: 'Матвейка Гвоздедер',
        icon: null,
        mark: -2,
        price: 43434543,
        score: -432,
    },
];
const Rating = ({ moralis }) => {

    return (
        <Table dataSource={data} scroll={{ x: 1300, }} >
            <Column title="Rate" key="rate"
                render={(_, record, index) => {
                    ++index;
                    if (index <= 3) {
                        return <LevelText backgroundImage={"level_back" + index}>#{index}</LevelText>
                    }
                    return index;
                }} />
            <Column title="Avatar" key="avatar" render={(_, record) => (<AdminAvatarImage src={record.icon || profileImage} />)} />
            <Column title="Name" key="name" sorter={(a, b) => a.name.localeCompare(b.name)}
                render={(_, record) => (<a href={record.link ?? "#"} target="_blank" rel="noreferrer">{record.name}</a>)} />
            <Column title="Price" key="price" render={(_, record) => {
                let plus = record.mark > 0
                let color = plus ? '#3CCD25' : '#F14343';
                return <div style={{ color: color }}>{plus ? <ArrowUpOutlined /> : <ArrowDownOutlined />}{record.mark}</div>
            }}
                sorter={(a, b) => a.type.localeCompare(b.type)} />
            <Column title="Score" key="score" render={(_, record) => {
                let plus = record.score > 0
                let color = plus ? '#3CCD25' : '#F14343';
                return <DescContainer>{record.price}<span style={{ marginLeft: "10px", color: color }}>({(plus ?? "-") + record.score})</span></DescContainer>
            }} />
        </Table>
    );
};
export default Rating;