import React, { useContext } from "react"
import {
  ActionBtn,
  CardContainer,
  DetailsContainer,
  IconImg,
  InnerContainer,
  NFTImg,
  NftTitle,
  RightContainer,
  SidebarContainer,
  SportTitle,
  StatItem,
  TitleContainer,
  TopHeaderText,
  WeightText,
} from "./styles/SellNFTStyling"
import { AddInOffer } from "components/Sidebar/AddInOffer"
import { AddInBundle } from "components/Sidebar/AddInBundle"
import { CloseButton, SidebarTitle } from "./styles/SidebarStyling"
import { DappContext } from "context"
import { CloseOutlined } from "@ant-design/icons"

const PlayerStat = ({ iconType, weight }) => {
  return (
    <StatItem>
      <IconImg
        src={require(`../../images/account/collection/${iconType}.svg`).default}
      />
      <WeightText>{weight}</WeightText>
    </StatItem>
  )
}

export const AddIn = ({ options, moralis, closeSidebar }) => {
  const { onCloseSidebar, setSidebarContent, setOpenSidebar } = useContext(DappContext)

  const handleCloseSidebar = () => {
    closeSidebar()
  }

  const handleClickAddInOffer = () => {
    const nftObj = { isERC721: options.isERC721, id: options.id, amount: options.amount }
    setSidebarContent(
      <AddInOffer
        nftItem={JSON.stringify(nftObj)}
        moralis={moralis}
        closeSidebar={onCloseSidebar}
      />,
    )
    setOpenSidebar(true)
  }

  const handleClickAddInBundle = () => {
    const nftObj = { isERC721: options.isERC721, id: options.id, amount: options.amount }
    setSidebarContent(
      <AddInBundle
        nftItem={JSON.stringify(nftObj)}
        moralis={moralis}
        closeSidebar={onCloseSidebar}
      />,
    )
    setOpenSidebar(true)
  }

  return (
    <SidebarContainer>
      <TitleContainer>
        <SidebarTitle>{options.action[0]}</SidebarTitle>
      </TitleContainer>
      <CardContainer>
        <TopHeaderText>{options.status}</TopHeaderText>
        <InnerContainer>
          <NFTImg src={options.imgSrc} />
          <RightContainer>
            <SportTitle>{options.sport}</SportTitle>
            <NftTitle>{options.title}</NftTitle>
            <DetailsContainer>
              {options.personal !== null && (
                <>
                  <PlayerStat iconType="Run" weight={options.personal.speed} />
                  <PlayerStat
                    iconType="Wheel"
                    weight={options.personal.dexterity}
                  />
                  <PlayerStat
                    iconType="Stamina"
                    weight={options.personal.stamina}
                  />
                  <PlayerStat
                    iconType="Stamina"
                    weight={options.personal.dribbling}
                  />
                  <PlayerStat
                    iconType="Stamina"
                    weight={options.personal.dribbling}
                  />
                </>
              )}
            </DetailsContainer>
          </RightContainer>
        </InnerContainer>
      </CardContainer>
      <ActionBtn onClick={handleClickAddInOffer}>add in offer</ActionBtn>
      <ActionBtn onClick={handleClickAddInBundle}>add in bundle</ActionBtn>
      <CloseButton onClick={handleCloseSidebar}><CloseOutlined /></CloseButton>
    </SidebarContainer>
  )
}
