import React, { useCallback, useEffect, useState } from "react"
import { Title, StepButton, DepositText, BalanceBox, WalletBalanceText, InsuffiText, GoSwapButton, Balance, ButtonGroup } from "./AuthStyling"
import Logo from "images/authentication/logo.png"
import { useMoralis, useMoralisWeb3Api } from "react-moralis"
import { depositYLT, getSufficientAmount } from "utils/helpers/proxy"
import { increaseAllowance } from "utils/helpers/ylt"
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Loading } from "notiflix"
import { userRoles } from "utils/dummyData"

const Deposit = ({ title = "", desc = "", label, onClickHandler, onBackHandler }) => {
    const [yltBalance, setYLTBalance] = useState(0)
    const [suffAmount, setSuffAmount] = useState(null)
    const { Moralis, user } = useMoralis()
    const Web3Api = useMoralisWeb3Api()

    const memoizedCallback = useCallback(async () => {
        Loading.standard()
        if (user) {
            try {
                const bal = await Web3Api.account.getTokenBalances({
                    address: user?.attributes.ethAddress,
                    chain: "bsc testnet",
                    token_addresses: [process.env.REACT_APP_YLT_CONTRACT_ADDRESS],
                })
                const roleIndex = userRoles.map(item => item.value).indexOf(user.attributes.role);
                let value = await Moralis.executeFunction(getSufficientAmount(roleIndex))
                if (bal[0]?.balance) setYLTBalance(Math.floor(Moralis.Units.FromWei(bal[0].balance)))
                if (value) setSuffAmount(Moralis.Units.FromWei(value))
                Loading.remove()
            } catch (error) {
                Loading.remove()
                Modal.error({
                    icon: <ExclamationCircleOutlined />,
                    content: error.data ? error.data.message : error.message,
                })
            }
        }
    }, [Web3Api.account, user, Moralis.Units])

    useEffect(() => {
        memoizedCallback()
    }, [memoizedCallback])

    const depositYltHandler = async () => {
        Loading.standard()
        let transaction
        try {
            transaction = await Moralis.executeFunction(
                increaseAllowance(
                    process.env.REACT_APP_YLPROXY_CONTRACT_ADDRESS,
                    Moralis.Units.Token(suffAmount, "18"),
                ),
            )
            await transaction.wait()
            transaction = await Moralis.executeFunction(
                depositYLT(Moralis.Units.Token(suffAmount, "18")),
            )
            await transaction?.wait()
            Loading.remove()
            onClickHandler()
        } catch (error) {
            Loading.remove()
            Modal.error({
                icon: <ExclamationCircleOutlined />,
                content: error.data ? error.data.message : error.message,
            })
        }
    }

    return (
        <>
            <div>
                <Title>{title}</Title>
                <DepositText>{desc}</DepositText>
                <BalanceBox>
                    <Balance>
                        <img src={Logo} alt="no logo" />
                        <p>{suffAmount}</p>
                    </Balance>
                    <p>current amount</p>
                </BalanceBox>
                <WalletBalanceText>
                    On your account: <span>{yltBalance} YLT</span>
                </WalletBalanceText>
                {yltBalance < suffAmount && (
                    <>
                        <InsuffiText>{label}</InsuffiText>
                        <GoSwapButton href="https://swapstaging.yourlifegames.com" target="_blank">GO TO SWAP</GoSwapButton>
                    </>
                )}
            </div>
            <ButtonGroup>
                <StepButton name="back" onClick={onBackHandler}>Back</StepButton>
                <StepButton onClick={depositYltHandler} disabled={yltBalance < suffAmount}>Confirm Deposit</StepButton>
            </ButtonGroup>
        </>
    )
}

export default Deposit
