import React from "react"
import { Title, StepButton, NotificationBox, ButtonGroup } from "./AuthStyling"

const Notification = ({ title, onClickHandler, onBackHandler }) => (
    <>
        <div>
            <Title>{title}</Title>
            <NotificationBox>
                <p>
                    Selecting this type of account will create a list of athletes. You will need to add athletes from your school or club to it.
                </p>
                <p>
                    You can see it later in your account.
                </p>
            </NotificationBox>
        </div>
        <ButtonGroup>
            <StepButton name="back" onClick={onBackHandler}>Back</StepButton>
            <StepButton onClick={onClickHandler}>Confirm</StepButton>
        </ButtonGroup>
    </>
)

export default Notification
