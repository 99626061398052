import React, { useContext, useState, useEffect, useCallback } from "react"
import {
  Button,
  ButtonContainer,
  MainContainer,
  MainTitle,
  NftContainer,
  StatContainer,
  StatCounter,
  TopContainer,
  BackBtn,
} from "./styles/SchoolClubsStyling"
import { Select } from "antd"
import { DappContext } from "context"
import { useHistory } from "react-router"
import { CreateSchoolClub } from "./components/CreateSchoolClub"
import { SchoolClubCard } from "./components/SchoolClubCard"
import { SchoolClubTable } from "./components/SchoolClubTable"
import { useMoralis } from "react-moralis"
import { Loading } from "notiflix"

export const SchoolClubs = ({ moralis }) => {
  const history = useHistory()
  const [filterSelected, setFilterSelected] = useState("all")
  const [onceLoad, setOnceLoad] = useState(true)
  const [allClubs, setAllClubs] = useState([])
  const [searchClubs, setSearchClubs] = useState([])
  const [number, setNumber] = useState(0)
  const { onCloseSidebar, setSidebarContent, setOpenSidebar } = useContext(DappContext)

  const loadloadInitClubs = useCallback(async () => {
    if (moralis) {
      Loading.standard()
      const result = await moralis?.fn.Cloud.run("getAllClubs")
      result.sort((a, b) => { new Date(b.date) - new Date(a.date) })
      setAllClubs(result)
      setNumber(result.length)
      setSearchClubs(result)
      Loading.remove()
    }
  }, [moralis])

  const loadFilterClubs = useCallback(async () => {
    if (moralis) {
      Loading.standard()
      let result
      if (filterSelected == "all") {
        result = allClubs
      } else {
        result = allClubs.filter((item) => item.attributes.type == filterSelected)
      }
      result.sort((a, b) => { new Date(b.date) - new Date(a.date) })
      setNumber(result.length)
      setSearchClubs(result)
      Loading.remove()
    }
  }, [allClubs, filterSelected])

  useEffect(() => {
    if (moralis && !onceLoad) {
      loadFilterClubs()
    }
  }, [loadFilterClubs, onceLoad, filterSelected])

  useEffect(() => {
    if (moralis && onceLoad) {
      loadloadInitClubs()
      setOnceLoad(false)
    }
  }, [moralis, onceLoad, loadloadInitClubs])

  const handleOfferClick = () => {
    setSidebarContent(
      <CreateSchoolClub moralis={moralis} closeSidebar={onCloseSidebar} />,
    )
    setOpenSidebar(true)
  }

  const handleSortChange = (value) => {
    setFilterSelected(value)
  }

  return (
    <MainContainer>
      <BackBtn onClick={() => { history.push("/admin/super") }}>back</BackBtn>
      <TopContainer>
        <MainTitle>School & Sport Clubs</MainTitle>
        <ButtonContainer>
          <Button onClick={handleOfferClick}>create new</Button>
        </ButtonContainer>
      </TopContainer>
      <StatContainer>
        <StatCounter>{number} School & Sport Clubs</StatCounter>
        <Select
          defaultValue="FILTER"
          onChange={(e) => handleSortChange(e)}
        >
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="school">School</Select.Option>
          <Select.Option value="club">Sport Clubs</Select.Option>
        </Select>
      </StatContainer>
      {searchClubs.length > 0 && <SchoolClubTable clubs={searchClubs} />}
      {/* <NftContainer>
        {searchClubs.map((card, index) => (
          <SchoolClubCard
            key={index}
            card={card}
          />
        ))}
      </NftContainer> */}
    </MainContainer>
  )
}