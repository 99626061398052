
import { Button, Col, Input, Image, Row, Space, Tabs, Typography, Progress, Card, Drawer, Divider } from "antd"
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { CssDivs } from "components/CssStyledComponent/CssStyledComponent"
import { useMedia } from "hooks/useMedia"
import { CloseOutlined, SearchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Loading } from "notiflix"
import axios from "axios"
import { tokenURI as tokenURI1155, burnBatch, setApprovalForAll } from "utils/helpers/ylnft1155"
import { approve } from "utils/helpers/ylt"
import { payTournamentFee } from "utils/helpers/contest"
import { useMoralisWeb3Api, useMoralis } from "react-moralis"

const priceList = [
    {
        id: "gold",
        name: "#1",
        price: "100000",
        bgImg: "url(/price_bg_1.png)",
    },
    {
        id: "silver",
        name: "#2",
        price: "50000",
        bgImg: "url(/price_bg_2.png)",
    },
    {
        id: "copper",
        name: "#3",
        price: "15000",
        bgImg: "url(/price_bg_3.png)",
    },
]

const bs = {
    Speed: 'team-b-card b-bg-speed',
    Power: 'team-b-card b-bg-power',
    Energy: 'team-b-card b-bg-energy'
}


const RenderTime = ({ remainingTime }) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
        isNewTimeFirstTick.current = true;
        prevTime.current = currentTime.current;
        currentTime.current = remainingTime;
    } else {
        isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
        setTimeout(() => {
            setOneLastRerender((val) => val + 1);
        }, 20);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
        <div className="time-wrapper">
            <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
                {remainingTime}
            </div>
            {prevTime.current !== null && (
                <div
                    key={prevTime.current}
                    className={`time ${!isTimeUp ? "down" : ""}`}
                >
                    {prevTime.current}
                </div>
            )}
        </div>
    );
};

const LeagueItem = (props) => {
    const { vault, moralis, info, user } = props;

    const isMobile = useMedia("(max-width: 640px)")
    const [open, setOpen] = useState(false)
    const [registered, setRegistered] = useState(false)
    const [takeing, setTaking] = useState(false)
    const [finding, setFinding] = useState(false)
    const [prepare, setPrepare] = useState(false)
    const [enemy, setEnemy] = useState(undefined)
    const [enemyBooster, setEnemyBooster] = useState([])
    const [myteam, setMyteam] = useState(undefined)
    const [teamBooster, setTeamBooster] = useState([])


    const { Moralis } = useMoralis()

    const readPlayerOne = async () => {
        try {
            const p = await moralis?.fn.Cloud.run("isInThisTournament", {
                address: user,
                id: info.id
            })
            setRegistered(p)
        } catch (e) {
            console.log(">>", e)
        }
    }

    const readTeams = useCallback(
        async (vt) => {
            Loading.standard()
            try {
                const v = await moralis?.fn.Cloud.run("getOneTeamData", {
                    vault: vt,
                })
                var tb = [];
                for (var k = 0; k < v.data_booster.length; k++) {
                    const attr = v.data_booster[k].attributes
                    const metaUri = await moralis?.fn.executeFunction(
                        tokenURI1155(attr.nftID),
                    )
                    const res = await axios.get(metaUri)
                    const meta = res.data
                    const n = {
                        vaultaddress: attr.vault,
                        subaddress: attr.vault,
                        gamer: attr.gamer,
                        tokenId: attr.nftID,
                        meta: meta,
                        amount: attr.amount
                    }
                    tb.push(n)
                }
                setTeamBooster(tb)
                Loading.remove()
            } catch (e) {
                console.log(e)
                Loading.remove()
            }
        },
        [moralis],
    )

    useEffect(() => {
        const t = vault.filter(v => v.category == info.category)
        if (t != undefined && t.length > 0) {
            setMyteam(t[0])
            readTeams(t[0].subaddress)
            readPlayerOne()
        }
    }, [vault])

    const openModal = (a, info) => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    const register = async () => {
        try {
            Loading.standard()
            // const price = Moralis.Units.Token(info.fee, "18")
            const approved = await Moralis.executeFunction(
                approve(
                    process.env.REACT_APP_YLCONTEST_CONTRACT_ADDRESS,
                    info.fee
                ),
            )
            await approved.wait()
            const transaction = await Moralis.executeFunction(
                payTournamentFee(
                    info.id
                ),
            )
            const res = await transaction.wait()
            if (res.status) {
                setRegistered(true)
                Loading.remove()
            } else {
                Loading.remove()
            }
        } catch (e) {
            Loading.remove()
        }
    }

    const takein = () => {
        setFinding(true)
        setTaking(true)
    }

    const checkMatchingTeam = async () => {
        try {
            const v = await moralis?.fn.Cloud.run("getMatchDataForPlayer", { player: user, category: info.category, tid: info.id })
            if (v.status) {
                setEnemy(v.data.data_team.attributes)
                var tb = [];
                for (var k = 0; k < v.data.data_booster.length; k++) {
                    const attr = v.data.data_booster[k].attributes
                    const metaUri = await moralis?.fn.executeFunction(
                        tokenURI1155(attr.nftID),
                    )
                    const res = await axios.get(metaUri)
                    const meta = res.data
                    const n = {
                        vaultaddress: attr.vault,
                        subaddress: attr.vault,
                        gamer: attr.gamer,
                        tokenId: attr.nftID,
                        meta: meta,
                        amount: attr.amount
                    }
                    tb.push(n)
                }
                setEnemyBooster(tb)
            } else {
                setEnemy(undefined)
            }
            setPrepare(true)
            if (enemy != undefined) {
                setPrepare(true)
            }
        } catch (e) {
            console.log(">>", e)
        }
    }

    useEffect(() => {
        
    }, [myteam])

    const isBoostered = (subaddress) => {
        var boostered = false;
        for (var i = 0; i < teamBooster.length; i++) {
            if (teamBooster[i].vaultaddress === subaddress) {
                boostered = true;
            }
        }
        return boostered;
    }

    const getDate = (timestamp) => {
        var date = new Date(timestamp * 1000);
        var day = date.getDate();
        var month = date.toLocaleString('default', { month: 'long' });
        var year = date.getFullYear();
        var dateString = day + ' ' + month + ' ' + year;
        return dateString;
    }

    return (
        <>
            <div className="league-carousel maring-bt-20 league-item" onClick={() => openModal()}>
                <div className="cs-wrap">
                    <img className="cs-img banner-img " src={info.meta.image} alt="" />
                    <div className="cs-info-wrap">
                        <div className="cs-f-wrap">
                            <p className="cs-date cs-itext">{getDate(info.created)}</p>
                            <p className="cs-place cs-itext"><span className="place-sub">{info.teamCount}</span>/{info.maxplayer} Places</p>
                            <p className="cs-type cs-itext">{info.category}</p>
                        </div>
                        <p className="cs-info">
                            {info.meta.name}
                        </p>
                    </div>
                </div>
                <div className="prize-wrap">
                    {
                        info.meta.gameType == "Tournament" ?
                            <Row>
                                <Col md={24} className="justify-between">
                                    <p className="prize">Prizes({Moralis.Units.FromWei(info.paidFee)})</p>
                                    <div className="justify-between">
                                        <Image
                                            width={25}
                                            src="/yourlife.png"
                                            preview={false}
                                            className="img-blender"
                                        />
                                        <p className="prize margin-left-10">{Moralis.Units.FromWei(info.fee)}</p>
                                    </div>
                                </Col>
                            </Row> :
                            <Row>
                                <Col md={24} className="justify-between">
                                    <p className="prize">Total Prizes Pool({Moralis.Units.FromWei(info.paidFee)})</p>
                                    <div className="justify-between">
                                        <Image
                                            width={25}
                                            src="/yourlife.png"
                                            preview={false}
                                            className="img-blender"
                                        />
                                        <p className="prize margin-left-10">{Moralis.Units.FromWei(info.fee)}</p>
                                    </div>
                                </Col>
                            </Row>
                    }
                    {
                        info.meta.gameType == "Tournament" ?
                            <Row gutter={16}>
                                {priceList.map((item) => (
                                    <Col key={item.id} md={8} xs={24}>
                                        <CssDivs backgroundImage={item.bgImg}
                                            backgroundSize="cover"
                                            backgroundPosition="center"
                                            backgroundRepeat="no-repeat"
                                            borderRadius="8px"
                                            display="flex"
                                            flexDirection="column"
                                            gap="4px"
                                            justifyContent="center"
                                            alignItems="center"
                                            pt="10px"
                                            pb="10px"
                                        >
                                            <Typography.Title
                                                level={3}
                                                style={{ color: "white", marginBottom: "0rem" }}
                                            >
                                                {item.name}
                                            </Typography.Title>
                                            <CssDivs
                                                border="none"
                                                borderTop="1px solid white"
                                                width="80%"
                                            />
                                            <Space>
                                                <Image
                                                    width={25}
                                                    src="/yourlife.png"
                                                    preview={false}
                                                />
                                                <Typography.Title
                                                    level={3}
                                                    style={{ color: "white", marginBottom: "0rem" }}
                                                >
                                                    {item.price}
                                                </Typography.Title>
                                            </Space>
                                        </CssDivs>
                                    </Col>
                                ))}
                            </Row> : <></>
                    }
                    <Row>
                        <Col xs={24} md={24} className="maring-top-20">
                            <Button type="primary" className="primary-btn " block>
                                ENROLL
                            </Button>
                        </Col>
                    </Row>
                </div>

            </div>
            <Drawer
                placement={"right"}
                width={isMobile ? "90%" : prepare ? "50%" : "35%"}
                closable={false}
                open={open}
                className="league-drawer"
            >
                <Row justify="center" align="middle">
                    <Col xs={24} className="close-btn-wrap">
                        <CloseOutlined className="close-btn" onClick={() => onClose()} />
                    </Col>
                    <Col xs={24} md={24}>
                        <div className="cs-wrap">
                            <img src={info.meta.image} alt="" className="drawer-img" />
                            <div className="cs-info-wrap-small">
                                <p className="cs-info-small">
                                    {info.meta.name}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="padding5">
                    {
                        registered == false ?
                            <>
                                <Col xs={24} md={24} className="maring-bt-20">
                                    <div className="cs-info-wrap">
                                        <div className="cs-f-wrap">
                                            <p className="ls-date ls-itext">{getDate(info.created)}</p>
                                            <p className="cs-place ls-itext"><span className="place-sub">{info.teamCount}</span>/{info.maxplayer} Places</p>
                                            <p className="ls-type ls-itext">{info.category}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={24} className="maring-top-40">
                                    <p>Only in the last year with a little Cristiano won two Champions Leagues, Euro 2016, the championship of Spain and the club world championship. Ronaldo took the Golden Ball for several years, greatly changing his sports program. We have known this guy for a very long time, we remember him in the green shirt of Sporting, without press and with tears inside the Luzhniki stadium. All this time, CR7 has been working on himself - including his eating habits.</p>
                                </Col>
                                <Col xs={24} md={24} className="divider"><Divider style={{ borderColor: '#c9c9c9' }} /></Col>
                            </> : <></>

                    }

                    <Col md={24} className="justify-between">
                        <p className="prize">Total Prize Pool</p>
                        <div className="justify-between">
                            <Image
                                width={25}
                                src="/yourlife.png"
                                preview={false}
                                className="img-blender"
                            />
                            <p className="prize margin-left-10">{Moralis.Units.FromWei(info.paidFee)}</p>
                        </div>
                    </Col>
                    {
                        prepare ?
                            <>
                                <Col xs={24} md={24} className="divider"><Divider style={{ borderColor: '#c9c9c9' }} /></Col>
                                <Col xs={24} md={24} className="maring-top-20">
                                    <Row>
                                        <Col xs={24} md={10}>
                                            <Row>
                                                <Col xs={24} md={24} >
                                                    <Link to={"/teams/" + myteam.subaddress + "/" + myteam.teamname}>
                                                        <Card bordered={false} className="team-s-card">
                                                            <div className="booster-active-wrap">
                                                                <Typography className="booster-active">
                                                                    booster activeated
                                                                </Typography>
                                                            </div>
                                                            <img
                                                                className="team-s-logo"
                                                                src={myteam.logo}
                                                                alt="YTL_team_logo"
                                                            />
                                                            <div className="card-s-title">{myteam.teamname}</div>
                                                        </Card>
                                                    </Link>
                                                </Col>
                                                <Col xs={24} md={24} className="maring-top-10">
                                                    <p>Boosters</p>
                                                </Col>
                                                {
                                                    teamBooster.length > 0 ?
                                                        teamBooster.map((item, index) => {
                                                            return (
                                                                <Col xs={24} md={24} key={index} className="maring-top-10">
                                                                    <Card bordered={false} className={bs[item.meta.boostedCharact]} >
                                                                        <div className="justify-center">
                                                                            <img className="b-list-img" src={item.meta.image} />
                                                                            <p className="margin-left-10">+{item.meta.boostedAmount} {item.meta.boostedCharact}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>12h 35m</p>
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                            )
                                                        }) :
                                                        <></>
                                                }
                                                <Col xs={24} md={24} className="maring-top-10">
                                                    <Button type="primary" className="primary-btn" block>
                                                        ADD BOOSTERS
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} md={4} className="justify-center">
                                            <h1>VS</h1>
                                        </Col>
                                        {
                                            enemy != undefined ?
                                                <Col xs={24} md={10}>
                                                    <Row>
                                                        <Col xs={24} md={24} >

                                                            <Card bordered={false} className="team-s-card">
                                                                <div className="booster-active-wrap">
                                                                    <Typography className="booster-active">
                                                                        booster activeated
                                                                    </Typography>
                                                                </div>
                                                                <img
                                                                    className="team-s-logo"
                                                                    src={enemy?.logo}
                                                                    alt="YTL_team_logo"
                                                                />
                                                                <div className="card-s-title">{enemy?.teamname}</div>
                                                            </Card>

                                                        </Col>
                                                        <Col xs={24} md={24} className="maring-top-10">
                                                            <p>Boosters</p>
                                                        </Col>
                                                        {
                                                            enemyBooster.length > 0 ?
                                                                enemyBooster.map((item, index) => {
                                                                    return (
                                                                        <Col xs={24} md={24} key={index} className="maring-top-10">
                                                                            <Card bordered={false} className={bs[item.meta.boostedCharact]} >
                                                                                <div className="justify-center">
                                                                                    <img className="b-list-img" src={item.meta.image} />
                                                                                    <p className="margin-left-10">+{item.meta.boostedAmount} {item.meta.boostedCharact}</p>
                                                                                </div>
                                                                                <div>
                                                                                    <p>12h 35m</p>
                                                                                </div>
                                                                            </Card>
                                                                        </Col>
                                                                    )
                                                                }) :
                                                                <p>No applied booster</p>
                                                        }
                                                    </Row>
                                                </Col> :
                                                <Col xs={24} md={10} style={{ textAlign: "center", margin: 'auto' }}>
                                                    No matched
                                                </Col>
                                        }
                                    </Row>
                                </Col>
                                <Col xs={24} md={24} className="b-tab">
                                    <Row className="w100">
                                        <Col xs={6} sm={6} md={6}>
                                            <Button type="primary" className="grey-btn" block>
                                                Back
                                            </Button>
                                        </Col>
                                        <Col xs={18} sm={18} md={18} className="padding-10">
                                            <div className="justify-between">
                                                <p>01:23</p>
                                                <p>Select boosters</p>
                                            </div>
                                            <Progress percent={50} showInfo={false} />
                                        </Col>
                                    </Row>
                                </Col>
                            </> :
                            registered ?
                                <>
                                    <Col xs={24} md={24} className="divider"><Divider style={{ borderColor: '#c9c9c9' }} /></Col>
                                    {/* <Col md={24} className="maring-top-10">
                                    <p>Select a team for the tournament</p>
                                </Col> */}
                                    {/* <Col xs={24} md={24} className=" maring-top-10">
                                    <div className="serach-wrap">
                                        <input placeholder="Search team" className="search-box" />
                                        <SearchOutlined className="search-icon" />
                                    </div>
                                </Col> */}
                                    {
                                        myteam != undefined ?
                                            <Col
                                                xs={24}
                                                md={24}
                                            >
                                                <Link to={"/teams/" + myteam.subaddress + "/" + myteam.teamname}>
                                                    <Card bordered={false} className="team-s-card">
                                                        <div className="booster-active-wrap">
                                                            {
                                                                isBoostered(myteam.subaddress) ?
                                                                    <Typography className="booster-active">
                                                                        booster activeated
                                                                    </Typography> : <></>
                                                            }
                                                        </div>
                                                        <img
                                                            className="team-s-logo"
                                                            src={myteam.logo}
                                                            alt="YTL_team_logo"
                                                        />
                                                        <div className="card-s-title">{myteam.teamname}</div>
                                                    </Card>
                                                </Link>
                                            </Col> :
                                            <Col
                                                xs={24}
                                                md={24}
                                                className="maring-top-20"
                                            >
                                                There is no team available. Please create a team.
                                            </Col>
                                    }

                                </> : <></>
                    }
                    {prepare == false ? registered == false ?
                        <Col xs={24} md={24} className="maring-top-20">
                            <Button type="primary" className="primary-btn " block onClick={() => register()}>
                                Register
                            </Button>
                        </Col> :
                        myteam != undefined ?
                            takeing ?
                                <>
                                    <Col xs={24} md={24} className="maring-top-20 align-center">
                                        <p className="card-s-title align-center">FINDING THE ENEMY</p>
                                        <div className="timer-wrapper maring-top-10">
                                            <CountdownCircleTimer
                                                isPlaying={finding}
                                                duration={15}
                                                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                                colorsTime={[15, 6, 3, 0]}
                                                onComplete={() => {
                                                    setFinding(false)
                                                    checkMatchingTeam();
                                                    return { shouldRepeat: true, delay: 1.5 } // repeat animation in 1.5 seconds
                                                }}
                                            >{RenderTime}
                                            </CountdownCircleTimer>
                                        </div>


                                    </Col>
                                    <Col xs={24} md={24} className="maring-top-20 ">
                                        <Button type="primary" className="grey-btn" block>
                                            Back
                                        </Button>
                                    </Col>
                                </> :
                                <>
                                    <Col xs={24} md={24} className="maring-top-20 ">
                                        <Row gutter={10}>
                                            <Col xs={12} sm={12} md={12}>
                                                <Button type="primary" className="grey-btn" block>
                                                    Back
                                                </Button>
                                            </Col>
                                            <Col xs={12} sm={12} md={12}>
                                                <Button type="primary" className="primary-btn" block onClick={() => { takein() }}>
                                                    TAKE
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                            :
                            <Col xs={24} md={24} className="maring-top-20 ">
                                <Button type="primary" className="primary-btn" block>
                                    CREATE A TEAM
                                </Button>
                            </Col>
                        : <></>
                    }

                </Row>
            </Drawer>
        </>
    )
}



export default LeagueItem
