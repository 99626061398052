import { Booster } from "components/Sidebar/Booster"
import { CollectionExchange } from "components/Sidebar/CollectionExchange"
import { CollectionSale } from "components/Sidebar/CollectionSale"
import { DappContext } from "context"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  ActionBtn,
  ButtonContainer,
  ButtonIcon,
  ColorBrick,
  ColorBrickContainer,
  GeometricCard,
  Graphics,
  IconImg,
  NameBlock,
  PlayerImage,
  PlayerName,
  PlayerStats,
  StatItem,
  TextBrick,
  TextBrickContainer,
  USDValue,
  ValueWrapper,
  WeightText,
} from "./styles/NftCardStyling"

export const PlayerStat = ({ iconType, weight }) => {
  return (
    <StatItem>
      <IconImg
        src={require(`../../images/account/collection/${iconType}.svg`).default}
      />
      <WeightText>{weight}</WeightText>
    </StatItem>
  )
}

export const CollectionNftCard = ({ card, isTeam, moralis, }) => {
  const { onCloseSidebar, setSidebarContent, setOpenSidebar } = useContext(DappContext)

  const onClickSwap = (event) => {
    const options = {
      id: card.id,
      amount: card.amount,
      isERC721: card.isERC721,
      imageUrl: card.imgSrc,
      sport: card.sport,
      playerName: card.title,
      speedElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Run" weight={card.personal.speed} />
      ),
      dexterityElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Wheel" weight={card.personal.dexterity} />
      ),
      staminaElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Stamina" weight={card.personal.stamina} />
      ),
      dribblingElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Run" weight={card.personal.dribbling} />
      ),
      finishingElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Wheel" weight={card.personal.finishing} />
      ),
    }
    setSidebarContent(
      <CollectionExchange options={options} closeSidebar={onCloseSidebar} />,
    )
    setOpenSidebar(true)
  }

  const onClickAuction = (event) => {
    const options = {
      id: card.id,
      amount: card.amount,
      isERC721: card.isERC721,
      imageUrl: card.imgSrc,
      sport: card.sport,
      playerName: card.title,
      speedElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Run" weight={card.personal.speed} />
      ),
      dexterityElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Wheel" weight={card.personal.dexterity} />
      ),
      staminaElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Stamina" weight={card.personal.stamina} />
      ),
      dribblingElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Run" weight={card.personal.dribbling} />
      ),
      finishingElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Wheel" weight={card.personal.finishing} />
      ),
    }
    setSidebarContent(
      <CollectionSale options={options} closeSidebar={onCloseSidebar} />,
    )
    setOpenSidebar(true)
  }

  const onClickUp = (event) => {
    const options = {
      imageUrl: card.imgSrc,
      sport: card.sport,
      playerName: card.title,
      speedElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Run" weight={card.personal.speed} />
      ),
      dexterityElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Wheel" weight={card.personal.dexterity} />
      ),
      staminaElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Stamina" weight={card.personal.stamina} />
      ),
      dribblingElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Run" weight={card.personal.dribbling} />
      ),
      finishingElmnt: card.personal && (
        <PlayerStat isTeam={isTeam} iconType="Wheel" weight={card.personal.finishing} />
      ),
    }
    setSidebarContent(
      <Booster options={options} closeSidebar={onCloseSidebar} meta={card.meta} id={card.id} />,
    )
    setOpenSidebar(true)
  }

  return (
    <GeometricCard isTeam={isTeam}>
      {/* <TopHeaderText isSell={isSell}>{status}</TopHeaderText> */}
      <Graphics isTeam={isTeam}>
        {card.personal !== null && (
          <>
            <PlayerStats isTeam={isTeam}>
              <PlayerStat isTeam={isTeam} iconType="Run" weight={card.personal.speed} />
              <PlayerStat isTeam={isTeam} iconType="Wheel" weight={card.personal.dexterity} />
              <PlayerStat isTeam={isTeam} iconType="Stamina" weight={card.personal.stamina} />
            </PlayerStats>
          </>
        )}
        <Link to={`/admin/mintedNfts/${card.owner}/${card.address}/${card.id}`} state={{nft: card}}>
          <PlayerImage src={card.imgSrc} alt="playerImage" />
        </Link>
        {card.personal !== null && (
          <>
            <PlayerStats isTeam={isTeam}>
              <PlayerStat isTeam={isTeam} iconType="Run" weight={card.personal.dribbling} />
              <PlayerStat isTeam={isTeam} iconType="Wheel" weight={card.personal.finishing} />
            </PlayerStats>
          </>
        )}
      </Graphics>
      <NameBlock>
        <PlayerName isTeam={isTeam}>{card.title}</PlayerName>
      </NameBlock>
      <ColorBrickContainer isTeam={isTeam}>
        <ColorBrick color={card.brickColor} />
      </ColorBrickContainer>
      <TextBrickContainer isTeam={isTeam}>
        <TextBrick isTeam={isTeam}>{card.sport}</TextBrick>
      </TextBrickContainer>
      <ButtonContainer>
        <ActionBtn onClick={onClickSwap}>
          <ButtonIcon src="/swap_icon.png" />
        </ActionBtn>
        <ActionBtn onClick={onClickAuction}>
          <ButtonIcon src="/auction_icon.png" />
        </ActionBtn>
        <ActionBtn onClick={onClickUp}>
          <ButtonIcon src="/upArrow_icon.png" />
        </ActionBtn>
      </ButtonContainer>
    </GeometricCard>
  )
}
