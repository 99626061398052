import styled from "styled-components"

export const ClubImage = styled.img`
  height: 3rem;
  border-radius: 2px;
  align-self: flex-start;
  margin-right: 1rem;
`

export const AdminAvatarImage = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  align-self: flex-start;
`

export const DescContainer = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
  overflow: hidden;
`

export const LevelText = styled.div`
  width: 60px;
  height: 60px;
  font-size: 24px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0px;
  text-align: center;
  color: #FFFFFF;
  background-color: aliceblue;
  background-image: url(/images/rating/${(props) => props.backgroundImage}.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`