import React, { useState, useRef } from "react"
import {
  AdminInfoContainerMobile,
  AdminInfoInnerContainerMobile,
  AdminInfoMobile,
  AdminUsernameContainerMobile,
  AdminUsernameMobile,
  AdminUsernameProfile,
  CameraContainer,
  CameraIconMobile,
  CameraBar,
  ConnectWalletMobileBtn,
  ProfileImgMobile,
  Role,
} from "./styles/SuperAdminProfileMobileStyling"
import { dummyData } from "utils/dummyData"
import cameraImg from "images/account/camera.svg"
import { BalanceInfo } from "./components/BalanceInfo"
import { FunctionBtnMobile } from "./components/FunctionBtnMobile"
import { CodeOutlined } from "@ant-design/icons"
import { FunctionsListMobile } from "./MobileScreens/FunctionsListMobile"

export const SuperAdminProfileMobile = (props) => {
  const [deposit, setDeposit] = useState(null)
  const {
    handleFileInput,
    tempProfileName,
    editProfile,
    profileImage,
    user,
  } = props
  const cameraInpRef = useRef(null);
  return (
    <>
      <AdminInfoContainerMobile>
        <AdminInfoMobile>
          <ProfileImgMobile src={profileImage} alt="super admin image" />
          <CameraContainer>
            <CameraBar>
              <CameraIconMobile
                src={cameraImg}
                onClick={() => cameraInpRef.current.click()}
              />
            </CameraBar>
            <input
              type="file"
              style={{ display: "none" }}
              ref={cameraInpRef}
              accept="image/*"
              onChange={() => handleFileInput(cameraInpRef)}
            />
          </CameraContainer>
        </AdminInfoMobile>
        <AdminUsernameContainerMobile>
          <AdminUsernameMobile>
            {user?.attributes?.nickname || tempProfileName}
          </AdminUsernameMobile>
          <Role>super admin</Role>
        </AdminUsernameContainerMobile>
        <BalanceInfo
          isSuperPage={true}
          isdeposit={deposit}
          address={user?.attributes.ethAddress}
          data={dummyData}
        />
      </AdminInfoContainerMobile>

      <FunctionBtnMobile
        text="create nft"
        icon={<CodeOutlined />}
        link="/admin/createNFT"
      />
      <FunctionBtnMobile
        text="create tournament"
        icon={<CodeOutlined />}
        link="/admin/createTournament"
      />

      {/* functions */}
      <FunctionsListMobile isSuperPage={true} />
    </>
  )
}
