import React, { useState } from "react"
import {
  CloseButton,
  MainContainer,
  SidebarContainer,
  SidebarTitle,
  TitleContainer,
  NftContainer,
  NftImage,
  NftAttr,
  PriceBox,
  PriceUSD,
  PriceYLT,
  AttrBox,
  AttrContainer,
} from "./styles/SidebarStyling"

import { approve } from "utils/helpers/ylt"
import { getMarketFee, buyMarketListedNFT } from "utils/helpers/marketplace"
import { buyMarketListedNFT as buy1155MarketListedNFT } from "utils/helpers/marketplace1155"
import { useMoralis } from "react-moralis"
import { Button, Col, Row, Modal, Input, Tag } from "antd"
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons"
import RunIcon from "images/account/collection/Run.svg"
import Logo from "images/marketplace/nftcard/logo.png"
import { Loading } from "notiflix"

export const Confirm = ({ options, closeSidebar, meta }) => {
  const { Moralis } = useMoralis()
  const [buyAmount, SetBuyAmount] = useState(1)

  const onClickAccept = async () => {

    Loading.standard()
    let transaction
    try {
      if (options.isERC721) {
        const price = Moralis.Units.Token(options.price, "18")
        transaction = await Moralis?.executeFunction(
          approve(process.env.REACT_APP_YLMARKETPLACE_CONTRACT_ADDRESS1, price),
        )
        await transaction.wait(3)
        await Moralis?.executeFunction(
          buyMarketListedNFT(options.itemId),
        )
        await transaction.wait(3)
        location.reload()
      } else {
        if (buyAmount < options.amount * 1) {
          const p = options.price * buyAmount
          const price = Moralis.Units.Token(p.toString(), "18")
          transaction = await Moralis?.executeFunction(
            approve(process.env.REACT_APP_YLNFT1155MARKETPLACE_CONTRACT_ADDRESS, price),
          )
          await transaction.wait(3)
          await Moralis?.executeFunction(
            buy1155MarketListedNFT(options.itemId, buyAmount),
          )
          await transaction.wait(3)
          location.reload()
        } else {
          Loading.remove()
          Modal.error({
            icon: <ExclamationCircleOutlined />,
            content: "Please enter the correct QTY",
          })
        }
      }
    } catch (error) {
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }
  return (
    <SidebarContainer>
      <MainContainer>
        <TitleContainer>
          <SidebarTitle>CONFIRM</SidebarTitle>
        </TitleContainer>
        <NftContainer>
          <NftImage src={options?.imgSrc} alt={options?.imgSrc} />
          <NftAttr>
            <p>SOCCER</p>
            <h1>{options?.playerName}</h1>
            <PriceBox>
              <img src={Logo}></img><PriceYLT>{options?.price}</PriceYLT>
              <PriceUSD>(${options?.priceUSD})</PriceUSD>
            </PriceBox>
            {options.isERC721 ?
              (
                <AttrContainer>
                  <AttrBox>
                    <img src={RunIcon}></img>
                    <span>{options?.personal.speed}</span>
                  </AttrBox>
                  <AttrBox>
                    <img src={RunIcon}></img>
                    <span>{options?.personal.dexterity}</span>
                  </AttrBox>
                  <AttrBox>
                    <img src={RunIcon}></img>
                    <span>{options?.personal.stamina}</span>
                  </AttrBox>
                  <AttrBox>
                    <img src={RunIcon}></img>
                    <span>{options?.personal.dribbling}</span>
                  </AttrBox>
                  <AttrBox>
                    <img src={RunIcon}></img>
                    <span>{options?.personal.finishing}</span>
                  </AttrBox>
                </AttrContainer>
              )
              :
              <NftAttr>
                <PriceBox>
                  <p>{`NFT QTYs: ${options.amount}`}</p>
                </PriceBox>
                {options.meta.boostedCharact &&
                  <PriceBox style={{ display: "flex", flexWrap: "wrap" }}>
                    {(options.meta.boostedCharact == "All" || options.meta.boostedCharact == "Speed") &&
                      <Tag color="processing" style={{ margin: "5px" }}>{`Speed: +${options.meta.boostedAmount}%`}</Tag>}
                    {(options.meta.boostedCharact == "All" || options.meta.boostedCharact == "Energy") &&
                      <Tag color="red" style={{ margin: "5px" }}>{`Energy: +${options.meta.boostedAmount}%`}</Tag>}
                    {(options.meta.boostedCharact == "All" || options.meta.boostedCharact == "Power") &&
                      <Tag color="gold" style={{ margin: "5px" }}>{`Power: +${options.meta.boostedAmount}%`}</Tag>}
                    {(options.meta.boostedCharact == "All" || options.meta.boostedCharact == "Jumping") &&
                      <Tag color="purple" style={{ margin: "5px" }}>{`Jumping: +${options.meta.boostedAmount}%`}</Tag>}
                  </PriceBox>
                }
              </NftAttr>
            }
          </NftAttr>
        </NftContainer>

        {!options.isERC721 &&
          <>
            <NftAttr>
              <p>Please enter the purchase quantity</p>
            </NftAttr>
            <Input
              placeholder="Please enter the purchase quantity"
              style={{
                borderRadius: "8px",
                opacity: "0.5",
                backgroundColor: "#DEDEDE",
                height: "40px",
                margin: "20px 0"
              }}
              bordered={false}
              value={buyAmount}
              onChange={(e) => { SetBuyAmount(e.target.value) }}
            />
            <NftAttr style={{ marginBottom: "20px" }}>
              <p>Total Price: {options.price * buyAmount} YLT</p>
            </NftAttr>
          </>
        }
        <Row gutter={16}>
          <Col span={12}>
            <Button
              block
              type="primary"
              ghost
              size="large"
              onClick={closeSidebar}
            >
              BACK
            </Button>
          </Col>
          <Col span={12}>
            <Button block type="primary" size="large" onClick={onClickAccept}>
              BUY
            </Button>
          </Col>
        </Row>
      </MainContainer>
      <CloseButton onClick={closeSidebar}><CloseOutlined /></CloseButton>
    </SidebarContainer>
  )
}
