export const MenuItems = [
  {
    title: "Admin",
    path: "/admin",
    cName: "dropdown-link",
  },
  {
    title: "Settings",
    path: "/",
    cName: "dropdown-link",
  },
  // {
  //   title: "Transfers",
  //   path: "/transfers",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "Development",
  //   path: "/development",
  //   cName: "dropdown-link",
  // },
]

export const SuperMenuItems = [
  {
    title: "Admin",
    path: "/admin/super",
    cName: "dropdown-link",
  },
  {
    title: "Settings",
    path: "/",
    cName: "dropdown-link",
  },
]