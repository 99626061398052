import { Button, Col, Input, Image, Row, Space, Tabs, Typography, Layout, Card, Drawer, Upload } from "antd"
import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { useMedia } from "hooks/useMedia"
import "../Vaultteam/style.css"
import "./style.css"
import { BackButton, ArrowBtn } from "./components/basic"
import { SearchOutlined } from "@ant-design/icons"
import axios from "axios"
import { Loading } from "notiflix"
import { useMoralisWeb3Api, useMoralis } from "react-moralis"
import LeagueItem from "./components/item"




const Tournaments = ({ moralis }) => {
    let inputRef
    const history = useHistory()
    const location = useLocation()
    const Web3Api = useMoralisWeb3Api()
    const { user, isAuthenticated, Moralis, web3 } = useMoralis()
    const isMobile = useMedia("(max-width: 640px)")
    const params = useParams()
    const [open, setOpen] = useState(false)
    const [notifyOpen, setNotifyOpen] = useState("")
    const [loaded, setLoaded] = useState(false)
    const [vault, setVault] = useState([])
    const [loading, setLoading] = useState(false)


    const [tournaments, setTournaments] = useState([])

    const readTeams = useCallback(async () => {
        try {
            Loading.standard()
            const v = await moralis?.fn.Cloud.run("getTeamData", {
                wallet: user.attributes.ethAddress,
            })
            const vData = []
            v.data_team.forEach((e) => {
                const attr = e.attributes
                const t = {
                    subaddress: attr.subaddress,
                    teamname: attr.teamname,
                    logo: attr.logo,
                    vaultaddress: attr.address,
                    category: attr.category,
                    from: attr.from,
                    gamer: attr.gamer,
                    createdTime: attr.createdTime,
                }
                vData.push(t)
            })
            
            setVault(vData)
            Loading.remove()
        } catch (e) {
            Loading.remove()
            console.log(e)
        }
    }, [moralis, user])

    const readData = useCallback(async () => {
        try {
            Loading.standard()
            setLoading(true)
            setLoaded(true)
            const v = await moralis?.fn.Cloud.run("getTournaments")

            const tn = v.data_tournament;
            const _tournament = [];
            for (var i = 0; i < tn.length; i++) {
                const _t = tn[i].attributes;
                const meta = await axios.get(_t.uri)
                const t_one = {
                    address: _t.address,
                    maxplayer: _t.maxPlayers,
                    fee: _t.tournamentFee,
                    id: _t.tournamentID,
                    category: _t.category,
                    updatedAt: _t.updatedAt,
                    uri: _t.uri,
                    paidFee: _t?.paidFee == undefined ? 0 : _t?.paidFee,
                    teamCount: _t?.teamCount == undefined ? 0 : _t?.teamCount,
                    created: _t.creationTime,
                    meta: meta.data
                }
                _tournament.push(t_one)
            }
            console.log(">>>>>LOG", _tournament)
            setTournaments(_tournament)
            Loading.remove()
            setLoading(false)
        } catch (e) {
            setLoading(false)
            Loading.remove()
            console.log(e)
        }
    }, [moralis])

    useEffect(() => {
        if (
            moralis != null &&
            user?.attributes.ethAddress != undefined &&
            loaded == false
        ) {
            setLoaded(true)
            readData()
            readTeams()
        }
    }, [moralis, user?.attributes.ethAddress])

    const openModal = (a, info) => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }



    return (
        <>
            <Layout className="layout team-detail">
                <Row justify="start">
                    <Col span={24} className="maring-top-30">
                        <BackButton onClick={() => history.goBack()}>Back</BackButton>
                    </Col>
                </Row>
                <Row justify="space-between" align="middle" className="maring-top-20">
                    <Col xs={24} md={10} className="align-center">
                        <Row type="flex" align="middle" justify="start">
                            <h1 className="team-title">Tournaments</h1>
                        </Row>
                    </Col>
                    <Col xs={24} md={10} className="maring-top-20">
                        <div className="serach-wrap">
                            <input placeholder="Search player" className="search-box" />
                            <SearchOutlined className="search-icon" />
                        </div>
                    </Col>
                </Row>
                <Row className="maring-top-30">
                    <Col xs={24} md={24}>
                        <span className="">58</span>
                        <span>/76 </span>
                        <span> Tournaments</span>
                    </Col>
                </Row>
                <Row className="maring-top-30" gutter={16}>
                    {
                        loaded && tournaments.length > 0 &&
                        tournaments.map((t, index) => {
                            return (
                                <Col xs={24} sm={24} md={12} lg={12} key={index}>
                                    <LeagueItem info={t} vault={vault} moralis={moralis} user={user?.attributes.ethAddress} />
                                </Col>
                            )
                        })
                    }
                    {/* <Col xs={24} sm={24} md={12} lg={12}>
                        <LeagueItem data={dummy[0]} vault={vault} moralis={moralis} />
                        <LeagueItem data={dummy[1]} vault={vault} moralis={moralis} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <LeagueItem data={dummy[1]} vault={vault} moralis={moralis} />
                        <LeagueItem data={dummy[0]} vault={vault} moralis={moralis} />
                    </Col> */}
                </Row>
            </Layout>
        </>
    )
}

export default Tournaments
