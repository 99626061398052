
import React, { useState } from "react"
import { OuterContainer, Title, StepButton, ProfileBox, DropDownContainer, DropFileStyle, AttachContainer, AsideContainer, AsideImageContainer, ButtonGroup } from "./CreateSchoolClubStyling"
import { useDropzone } from "react-dropzone"
import attachSvg from "images/attach.svg"
import InputComponent from "screens/AuthFlow/components/InputComponent"
import SelectBox from "components/SelectBox"
import { useMoralis } from "react-moralis"
import { Loading } from "notiflix"
import { Modal, Checkbox } from "antd"
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons"
import { CloseButton, SidebarContainer, } from "components/Sidebar/styles/SidebarStyling"
import { TitleField, SelectBoxDropdown, } from "screens/Admin/styles/CreateNFTStyles"
import { sports } from "utils/dummyData"
import { ClubTypes } from "utils/dummyData"

const CheckboxGroup = Checkbox.Group;
export const CreateSchoolClub = ({ moralis, closeSidebar }) => {
  const { Moralis, user } = useMoralis()
  const [name, setName] = useState("")
  const [link, setLink] = useState("")
  const [type, setType] = useState("")
  const [sport, setSport] = useState([])
  const [description, setDescription] = useState("")
  const [files, setFiles] = useState([])

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
    },
  })

  const thumbs = files.map((file) => (
    <AsideImageContainer key={file.name}>
      <img
        src={file.preview}
        onLoad={() => {
          URL.revokeObjectURL(file.preview)
        }}
      />
    </AsideImageContainer>
  ))

  const onAcceptHanlder = async () => {
    Loading.standard();
    try {
      const checkName = await Moralis.Cloud.run("checkClubName", { name: name })
      if (checkName) {
        Loading.remove()
        Modal.error({
          icon: <ExclamationCircleOutlined />,
          content: "This club name is already exist!",
        })
      } else {
        const data = files[0];
        const file = new Moralis.File(data.name, data);
        await file.saveIPFS();
        const request = {
          name: name,
          type: type,
          link: link,
          sport: sport,
          icon: file._ipfs,
          description: description,
        }
        await Moralis.Cloud.run("createClub", request)
        location.reload()
      }
    } catch (error) {
      Loading.remove();
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }

  return (
    <SidebarContainer>
      <OuterContainer>
        <div>
          <Title>Creat New School & Sport Clubs</Title>
          <>
            <TitleField>{"Type"}</TitleField>
            <SelectBoxDropdown>
              <SelectBox
                options={ClubTypes.map(item => item.label)}
                selectedOption={type}
                onChange={(value) => {
                  const sel = ClubTypes.filter(c => c.label == value)
                  setType(sel[0]?.value)
                }}
                placeholder="Type"
              />
            </SelectBoxDropdown>
          </>
          <InputComponent
            title="Enter name" placeholder="Name" name="Name" type="text"
            onChange={(e) => setName(e.target.value)}
          />
          <>
            <TitleField>{"Select kind of sport"}</TitleField>
            <SelectBoxDropdown>
              <CheckboxGroup options={sports.map(item => item.value)} value={sport} onChange={(value) => setSport(value)} />
            </SelectBoxDropdown>
          </>
          <InputComponent
            title="Website" placeholder="Website" name="link" type="text"
            onChange={(e) => setLink(e.target.value)}
          />
          <InputComponent
            title="Enter description" placeholder="Description" name="description" type="textArea"
            onChange={(e) => setDescription(e.target.value)}
          />
          <ProfileBox>
            <h1>Enter your HighSchool or Club logo</h1>
            <p>The file must be PNG, and no more than 3 MB</p>
          </ProfileBox>
          <DropDownContainer>
            <DropFileStyle {...getRootProps()}>
              <input
                type="url"
                placeholder="Enter media to upload to IPFS"
                {...getInputProps()}
              />
              <p>Drop files here</p>
              <AttachContainer>
                <img src={attachSvg} alt="attach" />
                <span>Attach file</span>
              </AttachContainer>
              <AsideContainer>
                {thumbs}
              </AsideContainer>
            </DropFileStyle>
          </DropDownContainer>
        </div>
        <ButtonGroup>
          <StepButton name="back" onClick={() => closeSidebar()}>Back</StepButton>
          <StepButton onClick={onAcceptHanlder}>Create</StepButton>
        </ButtonGroup>
      </OuterContainer>
      <CloseButton onClick={closeSidebar}><CloseOutlined /></CloseButton>
    </SidebarContainer>
  )
}
