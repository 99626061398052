import Carousel from "react-grid-carousel"
import {
  Button,
  Col,
  Input,
  Image,
  Row,
  Space,
  Tabs,
  Typography,
  Layout,
  Card,
  Drawer,
  Upload,
} from "antd"
import { PlusCircleOutlined, CloseOutlined, PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons"
import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { useMedia } from "hooks/useMedia"
import "./style.css"
import { BackButton, ArrowBtn } from "./components/basic"
import { PlayerCard, PlayerStats, PlayerStat } from "./components/playcard"
import {
  CssDivs,
  CssDiv,
} from "components/CssStyledComponent/CssStyledComponent"
import Item from "antd/lib/list/Item"
import { SearchOutlined } from "@ant-design/icons"
import { tokenURI, updateTokenURI } from "utils/helpers/ylnft721"
import { tokenURI as tokenURI1155, burnBatch, setApprovalForAll } from "utils/helpers/ylnft1155"
import axios from "axios"
import { Loading } from "notiflix"
import { revertNftFromVaultToWalletERC721, revertNftFromVaultToWalletERC1155 } from "utils/helpers/subvault"
import { useMoralisWeb3Api, useMoralis } from "react-moralis"
import { depositeERC721toSubVault, depositeERC1155toSubVault } from "utils/helpers/ylvault"
import { approve } from "utils/helpers/ylnft721"
import { approve as approveYLT } from "utils/helpers/ylt"
import wheel from "../../images/account/collection/Wheel.svg"
import stamina from "../../images/account/collection/Stamina.svg"
import run from "../../images/account/collection/Run.svg"
import { activeMatch, play } from "utils/helpers/contest"


const priceList = [
  {
    id: "gold",
    name: "#1",
    price: "100000",
    bgImg: "url(/price_bg_1.png)",
  },
  {
    id: "silver",
    name: "#2",
    price: "50000",
    bgImg: "url(/price_bg_2.png)",
  },
  {
    id: "copper",
    name: "#3",
    price: "15000",
    bgImg: "url(/price_bg_3.png)",
  },
]

const tabList = [
  {
    label: "12 Hours",
    price: 15000,
  },
  {
    label: "24 Hours",
    price: 30000,
  },
  {
    label: "3 DAYS",
    price: 45000,
  },
  {
    label: "WEEK",
    price: 90000,
  },
]

const TOKEN_ADDRESS = [
  process.env.REACT_APP_YLNFT721_CONTRACT_ADDRESS,
  process.env.REACT_APP_YLNFT1155_CONTRACT_ADDRESS,
]

const TeamDetail = ({ moralis }) => {
  let inputRef
  const history = useHistory()
  const location = useLocation()
  const Web3Api = useMoralisWeb3Api()
  const { user, isAuthenticated, Moralis, web3 } = useMoralis()
  const isMobile = useMedia("(max-width: 640px)")
  const params = useParams()
  const [open, setOpen] = useState(false)
  const [notifyOpen, setNotifyOpen] = useState("")
  const [loaded, setLoaded] = useState(false)
  const [type, setType] = useState("baseball")
  const [teamPower, setTeamPower] = useState({
    power: 0,
    speed: 0,
    energy: 0,
    luck: 50,
    wizzardy: 50,
  })
  const [players, setPlayers] = useState([])
  const [teamInfo, setTeamInfo] = useState({
    logo: "/images/vaultteam/YLT_teamlogo.png",
    subaddress: "",
    address: "",
    category: "",
    gamer: "",
    from: "",
    createdAt: "",
    teamname: "",
    quickMatch: false
  })

  const [sideContents, setSideContents] = useState("get reward")
  const [modalInfo, setModalInfo] = useState(null)
  const [delIndex, setDelIndex] = useState(0)
  const [addlist, setAddlist] = useState([])
  const [erc721, setErc721] = useState([])
  const [addBstlist, setAddBstlist] = useState([])
  const [delBstlist, setDelBstlist] = useState([])
  const [erc1155, setErc1155] = useState([])
  const [teamBooster, setTeamBooster] = useState([])
  const [tournaments, setTournaments] = useState([])


  const openModal = (a, info) => {
    setModalInfo(info)
    setSideContents(a)
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const enableQuickMatch = async (enable) => {
    try {
      Loading.standard()
      const tr = await Moralis.executeFunction(
        activeMatch(
          teamInfo.category,
          params.address
        ),
      )
      const res = await tr.wait()
      if (res.status) {
        var t = teamInfo
        t.quickMatch = enable
        setTeamInfo({ ...t })
      }
      Loading.remove()
    } catch (e) {
      Loading.remove()
      console.log("err", e)
    }
  }

  const readTeams = useCallback(
    async (vault) => {
      Loading.standard()
      try {
        const v = await moralis?.fn.Cloud.run("getOneTeamData", {
          vault: vault,
        })
        var tb = [];
        var bstList = []
        for (var k = 0; k < v.data_booster.length; k++) {
          const attr = v.data_booster[k].attributes
          const metaUri = await moralis?.fn.executeFunction(
            tokenURI1155(attr.nftID),
          )
          const res = await axios.get(metaUri)
          const meta = res.data
          const n = {
            vaultaddress: attr.vault,
            subaddress: attr.vault,
            gamer: attr.gamer,
            tokenId: attr.nftID,
            meta: meta,
            amount: attr.amount
          }
          tb.push(n)
          const bst = {
            id: attr.nftID,
            amount: 0,
            address: attr.vault
          }
          bstList.push(bst)
        }
        setDelBstlist(bstList)
        setTeamBooster(tb)

        // calculate the team property based on player average.
        const pData = []
        var power = 0
        var speed = 0
        var energy = 0
        for (var i = 0; i < v.data_players.length; i++) {
          const attr = v.data_players[i].attributes
          const metaUri = await moralis?.fn.executeFunction(
            tokenURI(attr.tokenId),
          )
          const meta = await axios.get(metaUri)
          const n = {
            vaultaddress: attr.address,
            subaddress: attr.vault,
            from: attr.from,
            gamer: attr.gamer,
            tokenId: attr.tokenId,
            depositTime: attr.depositTime,
            meta: meta.data,
          }
          power = power + meta.data.personal.power * 1
          speed = speed + meta.data.personal.speed * 1
          energy = energy + meta.data.personal.energy * 1
          pData.push(n)
        }

        // calculate the team additional property plus based on booster. 
        var enery_plus = 1;
        var speed_plus = 1;
        var power_plus = 1;
        for (var j = 0; j < tb.length; j++) {
          const amount = tb[j].amount * 1;
          const b_c = tb[j].meta.boostedCharact;
          const b_a = tb[j].meta.boostedAmount;
          if (b_c == "Movement" || b_c == "Energy" || b_c == "All") {
            enery_plus = enery_plus * ((100 + b_a * 1) / 100) ** amount;
          }
          if (b_c == "Speed" || b_c == "All") {
            speed_plus = speed_plus * ((100 + b_a * 1) / 100) ** amount;
          }
          if (b_c == "Power" || b_c == "All") {
            power_plus = power_plus * ((100 + b_a * 1) / 100) ** amount;
          }
        }

        if (v.data_players.length > 0) {
          const _p = Math.ceil((power * power_plus / v.data_players.length) * 10) / 10
          const _s = Math.ceil((speed * speed_plus / v.data_players.length) * 10) / 10
          const _e = Math.ceil((energy * enery_plus / v.data_players.length) * 10) / 10
          setTeamPower({
            power: _p,
            speed: _s,
            energy: _e,
            luck: 50,
            wizzardy: 50,
          })
          await moralis?.fn.Cloud.run("addPropertyToSubvault", {
            power: _p,
            speed: _s,
            energy: _e,
            subaddress: vault,
          })
        } else {
          await moralis?.fn.Cloud.run("addPropertyToSubvault", {
            power: 0,
            speed: 0,
            energy: 0,
            subaddress: vault,
          })
        }
        setPlayers(pData)
        const attr = v.data_team.attributes
        const t = {
          logo: attr.logo,
          subaddress: attr.subaddress,
          address: attr.address,
          category: attr.category,
          gamer: attr.gamer,
          from: attr.from,
          createdAt: attr.createdAt,
          teamname: attr.teamname,
          quickMatch: attr?.quickMatch
        }
        setTeamInfo(t)
        readNfts(attr.category)
        readTournaments(attr.category)
        Loading.remove()
      } catch (e) {
        console.log(e)
        Loading.remove()
      }
    },
    [moralis],
  )

  useEffect(() => {
    if (params.type != null && params.type != undefined) {
      setType(params.type)
    }
    if (
      params.address != null &&
      params.address != undefined &&
      moralis != null &&
      loaded == false
    ) {
      setLoaded(true)
      readTeams(params.address)

    }
  }, [params.type, moralis])

  const readNfts = async (category) => {
    if (isAuthenticated) {

      const nfts = await moralis?.fn.Cloud.run("getNFTsFromMain", {
        gamer: user.attributes.ethAddress,
      })

      const nft721OfMain = []
      for (var j = 0; j < nfts.data_nft721.length; j++) {
        const attr = nfts.data_nft721[j].attributes
        const metaUri = await moralis?.fn.executeFunction(
          tokenURI(attr.tokenId),
        )
        const meta = await axios.get(metaUri)
        const n = {
          gamer: attr.gamerAddr,
          id: attr.tokenId,
          imgSrc: meta.data.image,
          sport: meta.data.sport,
          title: meta.data.name,
          meta: meta.data,
          price: nfts.sale_price[j]
        }
        if (meta.data.sport == category) {
          nft721OfMain.push(n)
        }
      }
      setErc721(nft721OfMain)

      const nft1155OfMain = []
      var bstList = []
      for (var k = 0; k < nfts.data_nft1155.length; k++) {
        const attr = nfts.data_nft1155[k].attributes
        const metaUri = await moralis?.fn.executeFunction(
          tokenURI1155(attr.tokenId),
        )
        const meta = await axios.get(metaUri)
        const n = {
          id: attr.tokenId,
          imgSrc: meta.data.image,
          sport: meta.data.sport,
          title: meta.data.name,
          amount: attr.amount,
          meta: meta.data,
        }
        if (n.sport == category) {
          const bst = {
            id: n.id,
            amount: 0
          }
          bstList.push(bst)
          nft1155OfMain.push(n)
        }
      }
      setErc1155(nft1155OfMain)
      setAddBstlist(bstList)

    }
  }

  const readTournaments = useCallback(async (category) => {
    try {
      Loading.standard()
      setLoaded(true)
      const v = await moralis?.fn.Cloud.run("getTournamentsByCategory", { category: category })
      const tn = v.data_tournament;
      const _tournament = [];
      for (var i = 0; i < tn.length; i++) {
        const _t = tn[i].attributes;
        const meta = await axios.get(_t.uri)
        const t_one = {
          address: _t.address,
          maxplayer: _t.maxPlayers,
          fee: _t.tournamentFee,
          id: _t.tournamentID,
          category: _t.category,
          updatedAt: _t.updatedAt,
          uri: _t.uri,
          paidFee: _t?.paidFee == undefined ? 0 : _t?.paidFee,
          teamCount: _t?.teamCount == undefined ? 0 : _t?.teamCount,
          created: _t.creationTime,
          meta: meta.data
        }
        _tournament.push(t_one)
      }
      console.log(">>>>>LOG", _tournament)
      setTournaments(_tournament)
      Loading.remove()
    } catch (e) {
      Loading.remove()
      console.log(e)
    }
  }, [moralis])

  const onTabChange = (key) => {
    console.log(key)
  }

  const fileUpload = async (event) => {
    const data = event
    const image_ipfs = new Moralis.File(data.name, data)
    const img = await image_ipfs.saveIPFS()
    const image = "https://gateway.moralisipfs.com/ipfs/" + img._hash
  }

  const createTeam = async () => { }

  const takeReward = async () => { }

  const { Search } = Input
  const onSearch = (value) => console.log(value)
  const removeItem = (e, index) => {
    if (e != undefined) {
      setDelIndex(index)
      openModal("remove a player", e)
    }
  }
  const openinfo = (e) => {
    if (e != undefined) {
      openModal("playerinfo", e)
    }
  }
  const deletePlayer = async (e) => {

    try {
      Loading.standard()
      const transaction = await moralis?.fn.executeFunction(
        revertNftFromVaultToWalletERC721(
          e.subaddress,
          [Number(e.tokenId)],
          e.meta.sport,
        ),
      )
      const res = await transaction.wait()
      var p = players
      p.splice(delIndex)
      setPlayers(p)
      setSideContents("remove a player")
      setNotifyOpen("removed")
      Loading.remove()
      if (res.status) {
        console.log()
      }
    } catch (e) {
      Loading.remove()
      setSideContents("remove a player")
      setNotifyOpen("cantremove")
      console.log(e)
    }
  }

  const select2add = (i) => {
    if (addlist.includes(i)) {
      var d = addlist
      var index = d.indexOf(i)
      d.splice(index, 1)
      setAddlist([...d])
    } else {
      setAddlist([...addlist, i])
    }
  }

  const addPlayer = async () => {
    if (addlist.length == 0) {
      return
    }
    var addPlayer = []
    addlist.forEach((i) => {
      addPlayer.push(erc721[i].id)
    })

    try {
      Loading.standard()
      // for (var i = 0; i < addPlayer.length; i++) {
      //   const transaction = await Moralis.executeFunction(
      //     approve(process.env.REACT_APP_YLVAULT_CONTRACT_ADDRESS, addPlayer[i]),
      //   )
      //   await transaction.wait()
      // }
      const transaction = await Moralis.executeFunction(
        depositeERC721toSubVault(
          addPlayer
        ),
      )
      await transaction.wait()
      Loading.remove()
      window.location.reload()
    } catch (e) {
      Loading.remove()
      console.log(e)
    }
  }

  const boosterPlus = (item, mode) => {
    const id = item.id
    var bstList = addBstlist
    bstList.forEach((it, index) => {
      if (it.id == id) {
        if (mode == 0) {
          if (bstList[index].amount < item.amount) {
            bstList[index].amount++;
          }
        } else {
          if (bstList[index].amount > 0) {
            bstList[index].amount--;
          }
        }
      }
    })
    setAddBstlist([...bstList])
  }

  const boosterMinus = (item, mode) => {
    const id = item.tokenId
    var bstList = delBstlist
    bstList.forEach((it, index) => {
      if (it.id == id) {
        if (mode == 0) {
          if (bstList[index].amount < item.amount) {
            bstList[index].amount++;
          }
        } else {
          if (bstList[index].amount > 0) {
            bstList[index].amount--;
          }
        }
      }
    })
    setDelBstlist([...bstList])
  }

  const addBooster = async () => {
    try {
      Loading.standard()
      if (addBstlist.length == 0) {
        return
      }
      const tr_approve = await Moralis.executeFunction(
        setApprovalForAll(
          process.env.REACT_APP_YLVAULT_CONTRACT_ADDRESS,
          true
        ),
      )
      await tr_approve.wait()

      for (var i = 0; i < addBstlist.length; i++) {
        if (addBstlist[i].amount > 0) {
          const tr = await Moralis.executeFunction(
            depositeERC1155toSubVault(
              addBstlist[i].id,
              addBstlist[i].amount,
              teamInfo.category
            ),
          )
          await tr.wait()
        }
      }
      Loading.remove()
      window.location.reload()
    } catch (e) {
      Loading.remove()
      console.log(e)
    }
  }

  const removeBooster = async () => {
    try {
      Loading.standard()
      for (var i = 0; i < delBstlist.length; i++) {
        if (delBstlist[i].amount > 0) {
          const tr = await Moralis.executeFunction(
            revertNftFromVaultToWalletERC1155(
              delBstlist[i].address,
              delBstlist[i].id,
              teamInfo.category,
              delBstlist[i].amount,
            ),
          )
          await tr.wait()
        }
      }
      Loading.remove()
      window.location.reload()
    } catch (e) {
      Loading.remove()
      console.log(e)
    }
  }

  const addBooster2Player = async () => {

    try {
      Loading.standard();
      const nft721Id = modalInfo.tokenId;
      const subAddress = modalInfo.subaddress;
      const vaultAddress = modalInfo.vaultaddress;
      var meta = modalInfo.meta;
      var power = meta.personal.power;
      var energy = meta.personal.energy;
      var speed = meta.personal.speed;

      var burnIds = [];
      var burnAmount = [];
      for (var i = 0; i < addBstlist.length; i++) {
        if (addBstlist[i].amount > 0) {
          burnIds.push(addBstlist[i].id)
          burnAmount.push(addBstlist[i].amount)
          erc1155.forEach((booster, index) => {
            if (booster.id == addBstlist[i].id) {
              const b_c = booster.meta.boostedCharact;
              const b_a = booster.meta.boostedAmount;
              if (b_c == "Movement" || b_c == "Energy") {
                energy = energy * ((100 + b_a * 1) / 100) ** addBstlist[i].amount;
              }
              if (b_c == "Speed") {
                speed = speed * ((100 + b_a * 1) / 100) ** addBstlist[i].amount;
              }
              if (b_c == "Power") {
                power = power * ((100 + b_a * 1) / 100) ** addBstlist[i].amount;
              }
            }
          })
        }
      }
      meta.personal.power = power;
      meta.personal.energy = energy;
      meta.personal.speed = speed;
      const tr = await Moralis.executeFunction(
        burnBatch(
          user.attributes.ethAddress,
          burnIds,
          burnAmount,
        ),
      )
      const burn_res = await tr.wait()
      if (burn_res.status) {
        const options = JSON.stringify(meta)
        const file = new Moralis.File("file.json", {
          base64: btoa(options),
        })
        const response = await file.saveIPFS()
        const metadata_hash = "https://gateway.moralisipfs.com/ipfs/" + response._hash
        const update_uri = await Moralis.executeFunction(
          updateTokenURI(
            user.attributes.ethAddress,
            nft721Id,
            metadata_hash,
          ),
        )
        const update_uri_res = await update_uri.wait()
        if (update_uri_res.status) {
          window.location.reload()
        } else {
          console.log("Error")
        }
        Loading.remove()
      }
    } catch (e) {
      Loading.remove()
      console.log(e)
    }
  }

  const getDate = (timestamp) => {
    var date = new Date(timestamp * 1000);
    var day = date.getDate();
    var month = date.toLocaleString('default', { month: 'long' });
    var year = date.getFullYear();
    var dateString = day + ' ' + month + ' ' + year;
    return dateString;
  }

  const quickMatch = async () => {
    try {
      history.push("/quickmatch/" + params.address)
    } catch (e) {
      console.log("err", e)
    }
  }

  return (
    <>
      <Layout className="layout team-detail">
        <Row justify="start">
          <Col span={24}>
            <BackButton onClick={() => history.goBack()}>Previous</BackButton>
            <input
              ref={(refParam) => (inputRef = refParam)}
              type="file"
              onChange={(e) => fileUpload(e.target.files[0])}
              style={{ display: "none" }}
            />
          </Col>
        </Row>
        <Row justify="end" align="middle" className="maring-top-20">
          <Col xs={24} md={12} lg={9} className="align-center">
            <Row type="flex" align="middle" justify="center">
              <img
                className="team-detail-logo margin-right-10"
                src={teamInfo.logo}
                alt="YTL_team_logo"
              />
              <h1 className="team-title">{type}</h1>
            </Row>
          </Col>
          <Col xs={24} md={12} lg={9} className="align-center d-flex justify-center">
            <span className="level-type margin-right-20 d-flex">
              <img style={{ width: '25px' }} src={wheel} alt="" />
              <span className="level-value margin-left-10">
                {teamPower.power}
              </span>
            </span>
            <span className="level-type margin-right-20 d-flex">
              <img style={{ width: '21px' }} src={run} alt="" />
              <span className="level-value margin-left-10">
                {teamPower.speed}
              </span>
            </span>
            <span className="level-type margin-right-10 d-flex">
              <img style={{ width: '20px' }} src={stamina} alt="" />
              <span className="level-value margin-left-10">
                {teamPower.energy}
              </span>
            </span>
          </Col>
          <Col xs={24} lg={6} className="align-right">
            <Space wrap>
              <Button type="primary" className="primary-btn " block
                onClick={() => {
                  openModal("booster", "")
                }}
              >
                TEAM BOOSTERS
              </Button>
              <Button type="primary" className="primary-btn " block
                onClick={() => enableQuickMatch(!teamInfo.quickMatch)}
              >
                {teamInfo.quickMatch ? "Disallow Match" : "Allow Match"}
              </Button>
            </Space>
          </Col>
        </Row>
        <Row type="flex" className="maring-top-20">
          <Col sm={24} md={24} lg={24} className="d-flex overflowX">
            <div className="player-card-wrap-white">
              <p className="wrap-title">{teamInfo.category}</p>
              <div className="d-flex">
                {players.map((item, index) => {
                  return (
                    <div key={index} className="h-gap">
                      <PlayerCard
                        sportBg={"#ffffff"}
                        removeItem={removeItem}
                        openinfo={openinfo}
                        info={item}
                        index={index}
                      />
                    </div>
                  )
                })}
                {
                  players.length < 5 &&
                  <div className="h-tap">
                    <Card
                      bordered={true}
                      className="add-card"
                      onClick={() => openModal("add player", "")}
                    >
                      <PlusCircleOutlined className="logo-add" />
                      <div className="add-logo maring-top-10">add player</div>
                    </Card>
                  </div>
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row className="maring-top-30" gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="quick-match-btn d-flex maring-top-10" onClick={() => quickMatch()}>
              <img
                className="quick-logo margin-right-10"
                src="/images/vaultteam/quickmatch.png"
                alt="YTL_team_logo"
              />
              <p className="quick-btn-text">Quick Match</p>
            </div>
            <p className="sub-team-title maring-top-40">TOURNAMENTS</p>
            {
              tournaments.length > 0 &&
              <div className="league-carousel">
                <Carousel
                  rows={1}
                  cols={1}
                  showDots={true}
                  loop={true}
                  centerMode={true}
                  arrowLeft={<ArrowBtn type="left">&larr;</ArrowBtn>}
                  arrowRight={<ArrowBtn type="right">&rarr;</ArrowBtn>}
                  dotColorActive="#ffffff"
                  dotColorInactive={"#F5F5F0"}
                  autoplay={3000}
                >
                  {tournaments.map((t, index) => (
                    <Carousel.Item key={index} className="ghost">
                      <div key={index} className="cs-wrap">
                        <img className="cs-img2" src={t.meta.image} alt="" />
                        <div className="cs-info-wrap">
                          <div className="cs-f-wrap">
                            <p className="cs-date">{getDate(t.created)}</p>
                            <p className="cs-type">{t.category}</p>
                          </div>
                          <p className="cs-info">
                            {t.meta.name}
                          </p>
                        </div>
                      </div>
                    </Carousel.Item>
                  ))}

                </Carousel>
                <div className="prize-wrap">
                  <p className="prize">Prizes</p>

                  <Row gutter={16}>
                    {priceList.map((item) => (
                      <Col key={item.id} md={8} xs={24}>
                        <CssDivs
                          backgroundImage={item.bgImg}
                          backgroundSize="cover"
                          backgroundPosition="center"
                          backgroundRepeat="no-repeat"
                          borderRadius="8px"
                          display="flex"
                          flexDirection="column"
                          gap="4px"
                          justifyContent="center"
                          alignItems="center"
                          pt="10px"
                          pb="10px"
                        >
                          <Typography.Title
                            level={3}
                            style={{ color: "white", marginBottom: "0rem" }}
                          >
                            {item.name}
                          </Typography.Title>
                          <CssDivs
                            border="none"
                            borderTop="1px solid white"
                            width="80%"
                          />
                          <Space>
                            <Image
                              width={25}
                              src="/yourlife.png"
                              preview={false}
                            />
                            <Typography.Title
                              level={3}
                              style={{ color: "white", marginBottom: "0rem" }}
                            >
                              {item.price}
                            </Typography.Title>
                          </Space>
                        </CssDivs>
                      </Col>
                    ))}
                    <Col xs={24} md={24} className="maring-top-20">
                      <Button type="primary" className="primary-btn " block>
                        ENROLL
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            }

          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="maring-bt-30">
            <div className="maring-top-10">
              <img className="camp-img" src="/training_camp.png" alt="" />
              <p className="camp-text">Training camp</p>
              <div className="prize-wrap">
                <Row>
                  <Col sm={24} md={24} lg={24} className="d-flex overflowX">
                    <div className="trainer-item">
                      <img
                        className="trainer-img"
                        src="/player.png"
                        alt="playerImage"
                      />
                      <div className="reward-btn-wrap">
                        <p className="trainer-time">12h 28m</p>
                      </div>
                    </div>

                    <div className="trainer-item">
                      <img
                        className="trainer-img"
                        src="/player.png"
                        alt="playerImage"
                      />
                      <div className="reward-btn-wrap">
                        <Button
                          type="primary"
                          className="primary-btn"
                          block
                          onClick={() => openModal("get reward", "")}
                        >
                          Get reward
                        </Button>
                      </div>
                    </div>

                    {players.map((item, index) => {
                      return (
                        <div key={index} className="trainer-item">
                          <Card bordered={true} className="trainer-card">
                            <PlusCircleOutlined className="logo-add" />
                            <div className="add-logo">add player</div>
                          </Card>
                        </div>
                      )
                    })}
                  </Col>
                  <Col
                    sm={24}
                    md={24}
                    lg={24}
                    className="d-flex overflowX maring-top-30"
                  >
                    <Tabs centered className="tab-box">
                      {tabList.map((item, index) => {
                        return (
                          <Item md={6} tab={item.label} key={index}>
                            <div className="justify-center padding-10 maring-bt-20">
                              <Image
                                width={25}
                                src="/yourlife.png"
                                preview={false}
                              />
                              <p className="tab-price">{item.price}</p>
                            </div>
                          </Item>
                        )
                      })}
                    </Tabs>
                  </Col>
                </Row>
                <Button
                  type="primary"
                  className="primary-btn maring-top-20"
                  block
                >
                  ENROLL
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Layout>

      <Drawer
        placement={"right"}
        width={isMobile ? "90%" : "35%"}
        closable={false}
        // onClose={onClose}
        open={open}
        className="team-drawer"
      >
        <Row justify="center" align="middle">
          <Col xs={24} className="close-btn-wrap">
            <CloseOutlined
              className="close-btn"
              onClick={() => setOpen(false)}
            />
          </Col>
          {notifyOpen == "" ? (
            <Col xs={24} md={24}>
              {/* <h1 className="drawer-title">{sideContents}</h1> */}
            </Col>
          ) : (
            <></>
          )}

          {sideContents == "playerinfo" && (
            <>
              <Col xs={24} md={24}>
                <h1 className="drawer-title">Player Info</h1>
              </Col>
              <Col xs={24} md={24}>
                <div className="rewarder-card-wrap">
                  <img
                    className="rewarder-img"
                    src={modalInfo.meta.image ? modalInfo.meta.image : "/player.png"}
                    alt="playerImage"
                  />
                  <div className="rewarder-detail">
                    <p className="rewarder-sport-type">
                      {modalInfo?.meta.sport}
                    </p>
                    <h3 className="rewarder-name maring-top-5">
                      {modalInfo?.meta.name}
                    </h3>
                    <div className="maring-top-10">
                      <PlayerStats>
                        <PlayerStat
                          iconType="Wheel"
                          weight={modalInfo?.meta.personal.power}
                        />
                        <PlayerStat
                          iconType="Run"
                          weight={modalInfo?.meta.personal.speed}
                        />
                        <PlayerStat
                          iconType="Stamina"
                          weight={modalInfo?.meta.personal.energy}
                        />
                      </PlayerStats>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={24}>
                <Typography className="team-name">Boosters in your main vault</Typography>
              </Col>
              <Col xs={24} md={24} className="d-flex overflowX maring-top-10">
                {erc1155.length > 0 && erc1155.map((item, index) => {
                  return (
                    <div key={index} className="trainer-item pos-rel">
                      <img
                        className="booster-img "
                        src={item.imgSrc}
                        alt="playerImage"
                      />
                      <div className="booster-info">
                        {addBstlist[index].amount}
                        /
                        {item.amount}
                      </div>
                      <div className="booster-plus">
                        <PlusSquareOutlined className="booster-btn"
                          onClick={() => boosterPlus(item, 0)}
                        />
                        <MinusSquareOutlined className="booster-btn"
                          onClick={() => boosterPlus(item, 1)}
                        />
                      </div>
                      <div className="reward-btn-wrap">
                        <Button type="primary" className="primary-btn" block>
                          +{item.meta.boostedAmount} {item.meta.boostedCharact}
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </Col>
              <Col xs={24} md={24}>
                <Typography className="">If you apply boosters for player, those NFT1155s will be burned and player's property will be increased permanantly.</Typography>
              </Col>
              <Col xs={24} md={24} className="align-left maring-top-10">
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={24}>
                    <Button type="primary" className="primary-btn" block
                      onClick={() => addBooster2Player()}
                    >
                      Apply for this player
                    </Button>
                  </Col>
                </Row>
                {/* <Row gutter={10} className="maring-top-5">
                  <Col xs={24} sm={24} md={24}>
                    <Button type="primary" className="grey-btn" block>
                      Back
                    </Button>
                  </Col>
                </Row> */}
              </Col>
            </>
          )}

          {sideContents == "booster" && (
            <>
              <Col xs={24} md={24}>
                <h1 className="drawer-title">Your own boosters</h1>
              </Col>
              <Col xs={24} md={24}>
                <Typography className="team-name">Boosters applied to team</Typography>
              </Col>
              <Col xs={24} md={24} className="d-flex overflowX maring-top-10">
                {teamBooster.length > 0 && teamBooster.map((item, index) => {
                  return (
                    <div key={index} className="trainer-item pos-rel">
                      <img
                        className="booster-img "
                        src={item.meta.image}
                        alt="playerImage"
                      />
                      <div className="booster-info">
                        {item.amount}
                      </div>
                      <div className="reward-btn-wrap">
                        <Button type="primary" className="primary-btn" block>
                          +{item.meta.boostedAmount} {item.meta.boostedCharact}
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </Col>
              <Col xs={24} md={24} className="align-left maring-top-10">
                <Typography className="">...</Typography>
              </Col>
              {/* <Col xs={24} md={24} className="align-left maring-top-10">
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={24}>
                    <Button type="primary" className="warn-btn" block
                      onClick={() => removeBooster()}
                    >
                      Remove boosters from team
                    </Button>
                  </Col>
                </Row>
              </Col> */}
              <Col xs={24} md={24}>
                <Typography className="team-name">Boosters in your main vault</Typography>
              </Col>
              <Col xs={24} md={24} className="d-flex overflowX maring-top-10">
                {erc1155.length > 0 && erc1155.map((item, index) => {
                  return (
                    <div key={index} className="trainer-item pos-rel">
                      <img
                        className="booster-img "
                        src={item.imgSrc}
                        alt="playerImage"
                      />
                      <div className="booster-info">
                        {addBstlist[index].amount}
                        /
                        {item.amount}
                      </div>
                      <div className="booster-plus">
                        <PlusSquareOutlined className="booster-btn"
                          onClick={() => boosterPlus(item, 0)}
                        />
                        <MinusSquareOutlined className="booster-btn"
                          onClick={() => boosterPlus(item, 1)}
                        />
                      </div>
                      <div className="reward-btn-wrap">
                        <Button type="primary" className="primary-btn" block>
                          +{item.meta.boostedAmount} {item.meta.boostedCharact}
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </Col>
              <Col xs={24} md={24} className="align-left maring-top-10">
                <Typography className="">If you apply boosters to team, those NFT1155s will go to your team and will increase the property of team temporary</Typography>
              </Col>
              <Col xs={24} md={24} className="align-left maring-top-10">
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={24}>
                    <Button type="primary" className="primary-btn" block
                      onClick={() => addBooster()}
                    >
                      Apply boosters for team
                    </Button>
                  </Col>
                </Row>
              </Col>
            </>
          )}

          {sideContents == "remove a player" &&
            (notifyOpen == "cantremove" ? (
              <>
                <Col xs={24} md={24} className="justify-center mt-25vh">
                  <img
                    className="rewarder-img"
                    src="/images/vaultteam/failed.png"
                    alt="playerImage"
                  />
                </Col>
                <Col xs={24} md={18} className="justify-center maring-top-10">
                  <p className="notify-msg maring-top-10">
                    Can't remove a player
                  </p>
                </Col>
                <Col xs={24} md={18} className="justify-center">
                  <p className="notify-sub-msg">
                    The player is currently participating in the tournament, you
                    cannot remove him from the roster
                  </p>
                </Col>
                <Col xs={12} md={8} className="justify-center maring-top-20">
                  <Button
                    type="primary"
                    className="primary-btn"
                    block
                    onClick={() => {
                      setNotifyOpen("")
                      setOpen(false)
                    }}
                  >
                    Close
                  </Button>
                </Col>
              </>
            ) : notifyOpen == "removed" ? (
              <>
                <Col xs={24} md={24} className="justify-center mt-25vh">
                  <img
                    className="rewarder-img"
                    src="/images/vaultteam/check.png"
                    alt="playerImage"
                  />
                </Col>
                <Col xs={24} md={18} className="justify-center maring-top-10">
                  <p className="notify-msg maring-top-10">
                    Player removed from team
                  </p>
                </Col>
                <Col xs={12} md={8} className="justify-center maring-top-20">
                  <Button
                    type="primary"
                    className="primary-btn"
                    block
                    onClick={() => {
                      setNotifyOpen("")
                      setOpen(false)
                    }}
                  >
                    Close
                  </Button>
                </Col>
              </>
            ) : (
              <>
                <Col xs={24} md={24}>
                  <h1 className="drawer-title">Remove player from team</h1>
                </Col>
                <Col xs={24} md={24}>
                  <div className="rewarder-card-wrap">
                    <img
                      className="rewarder-img"
                      src={modalInfo.meta.image ? modalInfo.meta.image : "/player.png"}
                      alt="playerImage"
                    />
                    <div className="rewarder-detail">
                      <p className="rewarder-sport-type">
                        {modalInfo?.meta.sport}
                      </p>
                      <h3 className="rewarder-name maring-top-5">
                        {modalInfo?.meta.name}
                      </h3>
                      <div className="maring-top-10">
                        <PlayerStats>
                          <PlayerStat
                            iconType="Wheel"
                            weight={modalInfo?.meta.personal.power}
                          />
                          <PlayerStat
                            iconType="Run"
                            weight={modalInfo?.meta.personal.speed}
                          />
                          <PlayerStat
                            iconType="Stamina"
                            weight={modalInfo?.meta.personal.energy}
                          />
                        </PlayerStats>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={24} className="align-left maring-top-10">
                  <Typography className="">If you remove a player from team, that NFT721 will be back to your main vault.</Typography>
                </Col>
                <Col xs={24} md={24} className="align-left maring-top-10">
                  <Row gutter={10}>
                    {/* <Col xs={12} sm={12} md={12}>
                      <Button type="primary" className="grey-btn" block>
                        Back
                      </Button>
                    </Col> */}
                    <Col xs={24} sm={24} md={24}>
                      <Button
                        type="primary"
                        className="primary-btn"
                        block
                        onClick={() => deletePlayer(modalInfo)}
                      >
                        Delete from your team
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </>
            ))}

          {sideContents == "add player" && (
            <>
              <Col xs={24} md={24} className="maring-top-20">
                <div className="serach-wrap">
                  <input placeholder="Search player" className="search-box" />
                  <SearchOutlined className="search-icon" />
                </div>
              </Col>
              <Col
                sm={24}
                md={24}
                lg={24}
                className="d-flex overflowX maring-top-10"
              >
                <Row className="logo-list-wrap">
                  {erc721.map((item, index) => {
                    return (
                      <Col key={index} xs={12} md={8}>
                        <Card bordered={true} className="logo-card">
                          {/* <PlusCircleOutlined className="logo-add" /> */}
                          <img
                            className="add-player"
                            src={item.imgSrc}
                            alt="YTL_team_logo"
                          />
                          <div className="sel-btn">
                            <div
                              className="opt-out"
                              onClick={() => select2add(index)}
                            >
                              {addlist.includes(index) && (
                                <div className="opt-in"></div>
                              )}
                            </div>
                          </div>
                        </Card>
                      </Col>
                    )
                  })}
                </Row>
              </Col>
              <Col xs={24} md={24} className="align-left maring-top-10">
                <Row gutter={10}>
                  <Col xs={12} sm={12} md={12}>
                    <Button type="primary" className="grey-btn" block>
                      Back
                    </Button>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Button
                      type="primary"
                      className="primary-btn"
                      block
                      onClick={() => addPlayer()}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Col>
            </>
          )}
          {sideContents == "get reward" && (
            <>
              <Col xs={24} md={24}>
                <div className="rewarder-card-wrap">
                  <img
                    className="rewarder-img"
                    src="/player.png"
                    alt="playerImage"
                  />
                  <div className="rewarder-detail">
                    <p className="rewarder-sport-type">Soccer</p>
                    <h3 className="rewarder-name maring-top-5">
                      Christian Andrade
                    </h3>
                    <div className="maring-top-10">
                      <PlayerStats>
                        <PlayerStat iconType="Run" weight={321} />
                        <PlayerStat iconType="Wheel" weight={32} />
                        <PlayerStat iconType="Stamina" weight={8} />
                      </PlayerStats>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={24}>
                <div className="tab-box cs-f-wrap padding-20">
                  <div className="tab-price">reward</div>
                  <div className="justify-center ">
                    <Image width={25} src="/yourlife.png" preview={false} />
                    <p className="tab-price">15000</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={24} className="align-left maring-top-10">
                <Button
                  type="primary"
                  block
                  className="team-create-btn"
                  onClick={() => takeReward()}
                >
                  TAKE
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Drawer>
    </>
  )
}

export default TeamDetail
