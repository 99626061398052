import React from "react"
import { TitleField } from "./AuthStyling"
import { Input } from "antd"
const { TextArea } = Input

const InputComponent = ({ title, placeholder, name, value, onChange, type }) => {
    return (
        <>
            {title && <TitleField>{title}</TitleField>}
            {
                type == "text" || type == "number" ?
                    <Input
                        className="select-field"
                        required
                        type={type}
                        placeholder={placeholder}
                        name={name}
                        value={value}
                        onChange={onChange}
                        style={{ borderRadius: "8px" }}
                    />
                    :
                    <TextArea
                        className="select-field"
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        style={{
                            height: "150px",
                            padding: "20px",
                            borderRadius: "8px",
                        }}
                    />
            }
        </>
    )
}

export default InputComponent
