import { Button, Col, Input, Image, Row, Space, Tabs, Typography, Modal, Card, Progress, Divider, Drawer } from "antd"
import React, { useState, useEffect, useRef, useCallback, useContext } from "react"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { useMedia } from "hooks/useMedia"
import "./style.css"
import "../Vaultteam/style.css"
import { BackButton } from "screens/Vaultteam/components/basic"
import { SearchOutlined } from "@ant-design/icons"
import axios from "axios"
import { Loading } from "notiflix"
import { useMoralisWeb3Api, useMoralis } from "react-moralis"
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { tokenURI, setApprovalForAll } from "utils/helpers/ylnft1155"
import { depositeERC1155toSubVault } from "utils/helpers/ylvault"
import { createMatchID, play } from "utils/helpers/contest"
import { Link } from "react-router-dom"
import wheel from "../../images/account/collection/Wheel.svg"
import stamina from "../../images/account/collection/Stamina.svg"
import run from "../../images/account/collection/Run.svg"
import { PlusCircleOutlined, CloseOutlined, PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons"
import { Autoplay } from "swiper"
import { SocketContext } from "socketContext"

const bs = {
    Speed: 'team-b-card b-bg-speed',
    Power: 'team-b-card b-bg-power',
    Energy: 'team-b-card b-bg-energy',
    All: 'team-b-card b-bg-all'
}

const RenderTime = ({ remainingTime }) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
        isNewTimeFirstTick.current = true;
        prevTime.current = currentTime.current;
        currentTime.current = remainingTime;
    } else {
        isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
        setTimeout(() => {
            setOneLastRerender((val) => val + 1);
        }, 20);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
        <div className="time-wrapper">
            <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
                {remainingTime}
            </div>
            {prevTime.current !== null && (
                <div
                    key={prevTime.current}
                    className={`time ${!isTimeUp ? "down" : ""}`}
                >
                    {prevTime.current}
                </div>
            )}
        </div>
    );
};

const QuickMatch = ({ moralis }) => {
    const { socket } = useContext(SocketContext)
    const history = useHistory()
    const location = useLocation()
    const { user, isAuthenticated, Moralis, web3 } = useMoralis()
    const isMobile = useMedia("(max-width: 640px)")
    const params = useParams()
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [openWinner, setOpenWinner] = useState(false);
    const [loaded, setLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [finding, setFinding] = useState(true)
    const [myteam, setMyteam] = useState(undefined)
    const [enemyteam, setEnemyteam] = useState(undefined)
    const [mybooster, setMybooster] = useState([])
    const [enemybooster, setEnemybooster] = useState([])
    const [erc1155, setAddablebooster] = useState([])
    const [addBstlist, setAddBstlist] = useState([])
    const [time, setTime] = useState(300)
    const [matched, setMatched] = useState(false)
    const [winner, setWinner] = useState(0)
    const [started, setStarted] = useState(false)
    const [mid, setMid] = useState(0)


    const readAvailableBooster = async (address, category) => {
        if (isAuthenticated) {
            const nfts = await moralis?.fn.Cloud.run("getBoosterFromMain", {
                gamer: address,
            })
            const nft1155OfMain = []
            var bstList = []
            for (var k = 0; k < nfts.data_nft1155.length; k++) {
                const attr = nfts.data_nft1155[k].attributes
                const metaUri = await moralis?.fn.executeFunction(
                    tokenURI(attr.tokenId),
                )
                const meta = await axios.get(metaUri)
                const n = {
                    id: attr.tokenId,
                    imgSrc: meta.data.image,
                    sport: meta.data.sport,
                    title: meta.data.name,
                    amount: attr.amount,
                    meta: meta.data,
                }
                if (n.sport == category) {
                    const bst = {
                        id: n.id,
                        amount: 0
                    }
                    bstList.push(bst)
                    nft1155OfMain.push(n)
                }
            }
            setAddablebooster(nft1155OfMain)
            setAddBstlist(bstList)
        }
    }

    const readTeams = async (vaultAdr, target) => {
        try {
            console.log(">>>>sub", vaultAdr, target)
            const v = await moralis?.fn.Cloud.run("getOneTeamData", {
                vault: vaultAdr,
            })
            console.log(">>>>VVV", v)
            var mb = [];
            for (var k = 0; k < v.data_booster.length; k++) {
                const attr = v.data_booster[k].attributes
                const metaUri = await moralis?.fn.executeFunction(
                    tokenURI(attr.nftID),
                )
                const res = await axios.get(metaUri)
                const meta = res.data
                const n = {
                    vaultaddress: attr.vault,
                    subaddress: attr.vault,
                    gamer: attr.gamer,
                    tokenId: attr.nftID,
                    meta: meta,
                    amount: attr.amount
                }
                mb.push(n)
            }

            if (target == "me") {
                setMyteam(v.data_team.attributes)
                setMybooster(mb)
                readAvailableBooster(user?.attributes.ethAddress, v.data_team.attributes.category)
            } else {
                setEnemyteam(v.data_team.attributes)
                setEnemybooster(mb)
            }

            return { contest: v.data_team.attributes.contest, matchedAt: v.data_team.attributes.contest_matched };
        } catch (e) {
            return "";
        }
    }

    const checkMatchingTeam = async () => {
        if (enemyteam != undefined) {
            setFinding(false)
        } else {
            setFinding(true)
        }

    }

    const findEnemy = async () => {
        try {
            console.log("start...")
            // set subvault contest as 0x if it is "" or undefined, and return new or old
            const res = await moralis?.fn.Cloud.run("waitEnemyForContest", { subaddress: params.id })
            console.log(">>>>WAIT", res)
            if (res.status == "new") {
                socket.emit("waitEnemyForContest", { subaddress: params.id })
                readTeams(params.id, "me")
                socket.on("listenMatchData", (data) => {
                    if (data.me == params.id) {
                        if (data.enemy != "") {
                            readTeams(data.enemy, "enemy")
                            startCountDown(0)
                            setFinding(false)
                        }
                    }
                })
            } else {
                const data = await readTeams(params.id, "me")
                if (data?.contest != "" && data?.contest != undefined) {
                    // contest can be "0x" or "0xabc..."
                    if (data?.contest != "0x") {
                        const res = await readTeams(data?.contest, "enemy")
                        const st = Math.floor((Date.now() - res.matchedAt) / 1000);
                        startCountDown(st);
                        setFinding(false)
                    } else {
                        socket.on("listenMatchData", (data) => {
                            if (data.me == params.id) {
                                if (data.enemy != "") {
                                    readTeams(data.enemy, "enemy")
                                    startCountDown(0)
                                    setFinding(false)
                                }
                            }
                        })
                    }
                }
            }
        } catch (e) {
            console.log(">>err", e)
        }
    }

    useEffect(() => {
        if (
            moralis != null &&
            user?.attributes.ethAddress != undefined &&
            loaded == false
        ) {
            setLoaded(true)
            findEnemy()
        }
    }, [moralis, user?.attributes.ethAddress])

    useEffect(() => {
        if (myteam != undefined && enemyteam != undefined)
            socket.on("listenMatchResult", (data) => { 
                if (data.me == params.id) {
                    const _matchID = data.matchID;
                    const _tournamentID = data.tournamentID;
                    getMatchResult(_matchID, myteam, enemyteam) 
                }
            })
    }, [myteam, enemyteam])

    const addBooster = async () => {
        try {
            Loading.standard()
            if (addBstlist.length == 0) {
                return
            }
            const tr_approve = await Moralis.executeFunction(
                setApprovalForAll(
                    process.env.REACT_APP_YLVAULT_CONTRACT_ADDRESS,
                    true
                ),
            )
            await tr_approve.wait()

            for (var i = 0; i < addBstlist.length; i++) {
                if (addBstlist[i].amount > 0) {
                    const tr = await Moralis.executeFunction(
                        depositeERC1155toSubVault(
                            addBstlist[i].id,
                            addBstlist[i].amount,
                            myteam.category
                        ),
                    )
                    await tr.wait()
                }
            }

            var enery_plus = 1;
            var speed_plus = 1;
            var power_plus = 1;
            var extraBooster = mybooster
            addBstlist.forEach((b) => {
                erc1155.forEach((erc) => {
                    if (b.id == erc.id) {
                        const n = {
                            vaultaddress: "",
                            subaddress: "",
                            gamer: "",
                            tokenId: erc.id,
                            meta: erc.meta,
                            amount: b.amount
                        }
                        var isIn = false
                        for (var i = 0; i < extraBooster.length; i++) {
                            if (extraBooster[i].tokenId == b.id) {
                                extraBooster[i].amount = extraBooster[i].amount * 1 + b.amount * 1;
                                isIn = true
                            }
                        }
                        if (!isIn) {
                            extraBooster.push(n)
                        }

                        // additional property upgrade
                        const amount = b.amount * 1;
                        const b_c = erc.meta.boostedCharact;
                        const b_a = erc.meta.boostedAmount;
                        if (b_c == "Movement" || b_c == "Energy" || b_c == "All") {
                            enery_plus = enery_plus * ((100 + b_a * 1) / 100) ** amount;
                        }
                        if (b_c == "Speed" || b_c == "All") {
                            speed_plus = speed_plus * ((100 + b_a * 1) / 100) ** amount;
                        }
                        if (b_c == "Power" || b_c == "All") {
                            power_plus = power_plus * ((100 + b_a * 1) / 100) ** amount;
                        }
                    }
                })
            })
            setMybooster([...extraBooster])

            var team = myteam
            const _p = Math.floor((myteam.property.power * power_plus) * 10) / 10
            const _s = Math.floor((myteam.property.speed * speed_plus) * 10) / 10
            const _e = Math.floor((myteam.property.energy * enery_plus) * 10) / 10
            const pr = {
                power: _p,
                speed: _s,
                energy: _e
            }
            await moralis?.fn.Cloud.run("addPropertyToSubvault", {
                power: _p,
                speed: _s,
                energy: _e,
                subaddress: params.id,
            })
            team.property = pr;
            setMyteam({ ...team })
            readAvailableBooster(user?.attributes.ethAddress, myteam.category)
            setOpen(false)
            Loading.remove()
        } catch (e) {
            Loading.remove()
            console.log(e)
        }
    }

    const boosterPlus = (item, mode) => {
        const id = item.id
        var bstList = addBstlist
        bstList.forEach((it, index) => {
            if (it.id == id) {
                if (mode == 0) {
                    if (bstList[index].amount < item.amount) {
                        bstList[index].amount++;
                    }
                } else {
                    if (bstList[index].amount > 0) {
                        bstList[index].amount--;
                    }
                }
            }
        })
        setAddBstlist([...bstList])
    }

    const getMatchResult = async (_matchID, myteam, enemyteam) => { 
        var cnt = 0;
        var timer2 = setInterval(async () => {
            cnt++;
            if (cnt == 40) {
                const v = await moralis?.fn.Cloud.run("getMatchResult", {
                    matchId: _matchID.toString()
                })
                console.log("???", v)
                setOpenModal(false)
                if (v.winner == myteam.gamer) {
                    setWinner(1)
                } else if (v.winner == enemyteam.gamer) {
                    setWinner(2)
                } else {
                    setWinner(0)
                }
                setOpenWinner(true)
                clearInterval(timer2)
            }
        }, 1000);

    }


    const startCountDown = (init) => {
        var cnt = init;
        var timer = setInterval(() => {
            cnt++;
            setTime(300 - cnt)
            if (cnt == 300) {
                setStarted(true)
                clearInterval(timer)
                setOpenModal(true)
            }
        }, 1000);
    }

    const getMins = (sec) => {
        const min = Math.floor(sec / 60) + " mins " + sec % 60 + " sec"
        return min
    }

    return (
        <div style={{ minHeight: "75vh" }}>

            <Row justify="start">
                <Col span={24} className="maring-top-30">
                    <BackButton onClick={() => history.goBack()}>Back</BackButton>
                </Col>
            </Row>
            <Row justify="space-between" align="middle" className="maring-top-20">
                <Col xs={24} md={10} className="align-center">
                    <Row type="flex" align="middle" justify="start">
                        <h1 className="team-title">Quick Match</h1>
                    </Row>
                </Col>
            </Row>
            <Row>
                {
                    finding == true &&
                    <>
                        <Col xs={24} md={24} className="divider"><Divider style={{ borderColor: '#c9c9c9' }} /></Col>
                        <Col xs={24} md={24} className="maring-top-20 align-center">
                            <p className="card-s-title-match align-center">FINDING THE ENEMY</p>
                            <div className="timer-wrapper maring-top-40 maring-bt-40">
                                <CountdownCircleTimer
                                    isPlaying={finding}
                                    duration={15}
                                    colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                    colorsTime={[15, 6, 3, 0]}
                                    onComplete={() => {
                                        checkMatchingTeam();
                                        return { shouldRepeat: true, delay: 1.5 } // repeat animation in 1.5 seconds
                                    }}
                                >{RenderTime}
                                </CountdownCircleTimer>
                            </div>
                        </Col>
                    </>
                }

                {
                    finding == false &&
                    <>
                        <Col xs={24} md={24} className="divider"><Divider style={{ borderColor: '#c9c9c9' }} /></Col>
                        <Col xs={24} md={24} className="maring-top-20">
                            <Row>
                                <Col xs={24} md={10}>
                                    <Row>
                                        <Col xs={24} md={24} >
                                            <Link to={"/teams/" + myteam.subaddress + "/" + myteam.teamname}>
                                                <Card bordered={false} className={winner == 1 ? "team-s-card winner" : "team-s-card"} >
                                                    <div className="booster-active-wrap-match">
                                                        {
                                                            mybooster.length > 0 ?
                                                                <Typography className="booster-active">
                                                                    booster activeated
                                                                </Typography> :
                                                                <div></div>
                                                        }
                                                        <div className="align-center d-flex justify-center">
                                                            <span className="level-type margin-right-10 d-flex">
                                                                <img style={{ width: '15px' }} src={wheel} alt="" />
                                                                <span className="level-value-match margin-left-5">
                                                                    {myteam.property.power}
                                                                </span>
                                                            </span>
                                                            <span className="level-type margin-right-10 d-flex">
                                                                <img style={{ width: '13px' }} src={run} alt="" />
                                                                <span className="level-value-match margin-left-5">
                                                                    {myteam.property.speed}
                                                                </span>
                                                            </span>
                                                            <span className="level-type margin-right-10 d-flex">
                                                                <img style={{ width: '12px' }} src={stamina} alt="" />
                                                                <span className="level-value-match margin-left-5">
                                                                    {myteam.property.energy}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <img
                                                        className="team-s-logo"
                                                        src={myteam.logo}
                                                        alt="YTL_team_logo"
                                                    />
                                                    <div className="card-s-title">{myteam.teamname}</div>
                                                </Card>
                                            </Link>
                                        </Col>
                                        <Col xs={24} md={24} className="maring-top-10">
                                            <p>Boosters</p>
                                        </Col>
                                        {
                                            mybooster.length > 0 ?
                                                mybooster.map((item, index) => {
                                                    return (
                                                        <Col xs={24} md={24} key={index} className="maring-top-10">
                                                            <Card bordered={false} className={bs[item.meta.boostedCharact]} >
                                                                <div className="justify-center">
                                                                    <img className="b-list-img" src={item.meta.image} />
                                                                    <p className="margin-left-10">+{item.meta.boostedAmount} {item.meta.boostedCharact}</p>
                                                                </div>
                                                                <div>
                                                                    <p>{item.amount}</p>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    )
                                                }) :
                                                <></>
                                        }
                                        <Col xs={24} md={24} className="maring-top-10">
                                            {
                                                started == false &&
                                                <Button type="primary" className="primary-btn" block onClick={() => setOpen(true)}>
                                                    ADD BOOSTERS
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} md={4} className="justify-center">
                                    <h1>VS</h1>
                                </Col>
                                {
                                    enemyteam != undefined ?
                                        <Col xs={24} md={10}>
                                            <Row>
                                                <Col xs={24} md={24} >

                                                    <Card bordered={false} className={winner == 2 ? "team-s-card winner" : "team-s-card"}>
                                                        <div className="booster-active-wrap-match">
                                                            {
                                                                enemybooster.length > 0 ?
                                                                    <Typography className="booster-active">
                                                                        booster activeated
                                                                    </Typography> : <div></div>
                                                            }
                                                            <div className="align-center d-flex justify-center">
                                                                <span className="level-type margin-right-10 d-flex">
                                                                    <img style={{ width: '15px' }} src={wheel} alt="" />
                                                                    <span className="level-value-match margin-left-5">
                                                                        {enemyteam.property.power}
                                                                    </span>
                                                                </span>
                                                                <span className="level-type margin-right-10 d-flex">
                                                                    <img style={{ width: '13px' }} src={run} alt="" />
                                                                    <span className="level-value-match margin-left-5">
                                                                        {enemyteam.property.speed}
                                                                    </span>
                                                                </span>
                                                                <span className="level-type margin-right-10 d-flex">
                                                                    <img style={{ width: '12px' }} src={stamina} alt="" />
                                                                    <span className="level-value-match margin-left-5">
                                                                        {enemyteam.property.energy}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <img
                                                            className="team-s-logo"
                                                            src={enemyteam?.logo}
                                                            alt="YTL_team_logo"
                                                        />
                                                        <div className="card-s-title">{enemyteam?.teamname}</div>
                                                    </Card>

                                                </Col>
                                                <Col xs={24} md={24} className="maring-top-10">
                                                    <p>Boosters</p>
                                                </Col>
                                                {
                                                    enemybooster.length > 0 ?
                                                        enemybooster.map((item, index) => {
                                                            return (
                                                                <Col xs={24} md={24} key={index} className="maring-top-10">
                                                                    <Card bordered={false} className={bs[item.meta.boostedCharact]} >
                                                                        <div className="justify-center">
                                                                            <img className="b-list-img" src={item.meta.image} />
                                                                            <p className="margin-left-10">+{item.meta.boostedAmount} {item.meta.boostedCharact}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>12h 35m</p>
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                            )
                                                        }) :
                                                        <p>No applied booster</p>
                                                }
                                            </Row>
                                        </Col> :
                                        <Col xs={24} md={10} style={{ textAlign: "center", margin: 'auto' }}>
                                            No matched
                                        </Col>
                                }
                            </Row>
                        </Col>
                        {
                            started == false &&
                            <Col xs={24} md={24} className="b-tab-match">
                                <Row className="w100">
                                    <Col xs={6} sm={6} md={6}>
                                        <Button type="primary" className="grey-btn" block>
                                            Back
                                        </Button>
                                    </Col>
                                    <Col xs={18} sm={18} md={18} className="padding-10">
                                        <div className="justify-between">
                                            <p>{getMins(time)}</p>
                                            <p>Select boosters</p>
                                        </div>
                                        <Progress percent={Math.floor(time / 3)} showInfo={false} />
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </>
                }
            </Row>

            <Drawer
                placement={"right"}
                width={isMobile ? "90%" : "35%"}
                closable={false}
                // onClose={onClose}
                open={open}
                className="team-drawer"
            >
                <Row justify="center" align="middle">
                    <Col xs={24} className="close-btn-wrap">
                        <CloseOutlined
                            className="close-btn"
                            onClick={() => setOpen(false)}
                        />
                    </Col>

                    <Col xs={24} md={24}>
                        <h1 className="drawer-title">Your own boosters</h1>
                    </Col>

                    <Col xs={24} md={24}>
                        <Typography className="team-name">Boosters in your main vault</Typography>
                    </Col>
                    <Col xs={24} md={24} className="d-flex overflowX maring-top-10">
                        {erc1155.length > 0 && erc1155.map((item, index) => {
                            return (
                                <div key={index} className="trainer-item pos-rel">
                                    <img
                                        className="booster-img "
                                        src={item.imgSrc}
                                        alt="playerImage"
                                    />
                                    <div className="booster-info">
                                        {addBstlist[index]?.amount}
                                        /
                                        {item.amount}
                                    </div>
                                    <div className="booster-plus">
                                        <PlusSquareOutlined className="booster-btn"
                                            onClick={() => boosterPlus(item, 0)}
                                        />
                                        <MinusSquareOutlined className="booster-btn"
                                            onClick={() => boosterPlus(item, 1)}
                                        />
                                    </div>
                                    <div className="reward-btn-wrap">
                                        <Button type="primary" className="primary-btn" block>
                                            +{item.meta.boostedAmount} {item.meta.boostedCharact}
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                    </Col>
                    <Col xs={24} md={24} className="align-left maring-top-10">
                        <Typography className="">If you apply boosters to team, those NFT1155s will go to your team and will increase the property of team temporary</Typography>
                    </Col>
                    <Col xs={24} md={24} className="align-left maring-top-10">
                        <Row gutter={10}>
                            <Col xs={24} sm={24} md={24}>
                                <Button type="primary" className="primary-btn" block
                                    onClick={() => addBooster()}
                                >
                                    Apply boosters for team
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Drawer>

            <Modal
                centered
                open={openModal}
                maskClosable={false}
                width={800}
                footer={null}
                bodyStyle={{ padding: 0 }}
            >

                <iframe style={{ width: '100%', height: '450px' }} src="/video/sample.mp4?autoplay=1" allow="autoplay" ></iframe>
            </Modal>

            <Modal
                centered
                open={openWinner}
                onCancel={() => setOpenWinner(false)}
                width={800}
                footer={null}
                bodyStyle={{ padding: 0 }}
            >
                {
                    (winner == 1 || winner == 2) ?
                        <p className="b-result">You are <span className={winner == 1 ? "b-winner" : "b-looser"}>{winner == 1 ? "Winner" : "Looser"}</span>!</p> :
                        <p className="b-result">No winner or looser</p>
                }
            </Modal>



        </div>
    )
}

export default QuickMatch
