import React, { useContext, useState } from "react"
import { useMoralis } from "react-moralis"
import { Link } from "react-router-dom"
import {
  ButtonGroup,
  ColorBrick,
  ColorBrickContainer,
  GeometricCard,
  Graphics,
  IconImg,
  NameBlock,
  PlayerImage,
  PlayerName,
  PlayerStats,
  StatItem,
  TextBrick,
  TextBrickContainer,
  USDValue,
  ValueWrapper,
  WeightText,
  TopHeaderText,
} from "./styles/CardElements"
import { DappContext } from "context"
import { NftBuy } from "components/Sidebar/NftBuy"
import { Button, Tag } from "antd"
import { Confirm } from "components/Sidebar/Confirm"

const PlayerStat = ({ iconType, weight }) => {
  return (
    <StatItem>
      <IconImg
        src={require(`../../images/account/collection/${iconType}.svg`).default}
      />
      <WeightText>{weight}</WeightText>
    </StatItem>
  )
}

const NFTGeometricCard = ({ nft }) => {
  const {
    flag_offerAuction,
    setSidebarContent,
    setOpenSidebar,
    onCloseSidebar,
  } = useContext(DappContext)

  const openNftBuySidebar = () => {
    setSidebarContent(<NftBuy option={nft} closeSidebar={onCloseSidebar} />)
    setOpenSidebar(true)
  }

  const removeFromAuction = () => { }

  const openConfirmSidebar = () => {
    setSidebarContent(<Confirm options={nft} closeSidebar={onCloseSidebar} meta={nft.meta} />,)
    setOpenSidebar(true)
  }

  const onClickBuy = () => {
    switch (flag_offerAuction) {
      case "all":
      case "market":
      case "offer":
        openConfirmSidebar()
        break
      case "auction":
        openNftBuySidebar()
        break
      default:
        break
    }
  }

  return (
    <GeometricCard backgroundColor={"white"}>
      <TopHeaderText>{nft.status}</TopHeaderText>
      <Graphics>
        {nft.personal !== null && (
          <>
            <PlayerStats>
              <PlayerStat
                iconType="Run"
                weight={nft.personal.speed}
              />
              <PlayerStat
                iconType="Wheel"
                weight={nft.personal.dexterity}
              />
              <PlayerStat
                iconType="Stamina"
                weight={nft.personal.stamina}
              />
            </PlayerStats>
          </>
        )}
        <Link to={`/admin/mintedNfts/${nft.owner}/${nft.address}/${nft.id}`}>
          <PlayerImage src={nft.imgSrc} alt="playerImage" />
        </Link>
        {nft.personal !== null && (
          <>
            <PlayerStats>
              <PlayerStat
                iconType="Run"
                weight={nft.personal.dribbling}
              />
              <PlayerStat
                iconType="Wheel"
                weight={nft.personal.finishing}
              />
            </PlayerStats>
          </>
        )}
      </Graphics>
      <NameBlock>
        <PlayerName>{nft.title}</PlayerName>
        <ValueWrapper>
          {`${nft.price} YLT`}
          <USDValue>{` ($${nft.priceUSD})`}</USDValue>
        </ValueWrapper>
        {!nft.isERC721 &&
          <ValueWrapper>
            {`NFT QTY: ${nft.amount}`}
          </ValueWrapper>
        }
        {nft.meta.boostedCharact &&
          <ValueWrapper style={{ flexWrap: "wrap" }}>
            {(nft.meta.boostedCharact == "All" || nft.meta.boostedCharact == "Speed") &&
              <Tag color="processing" style={{ margin: "5px" }}>{`Speed: +${nft.meta.boostedAmount}%`}</Tag>}
            {(nft.meta.boostedCharact == "All" || nft.meta.boostedCharact == "Energy") &&
              <Tag color="red" style={{ margin: "5px" }}>{`Energy: +${nft.meta.boostedAmount}%`}</Tag>}
            {(nft.meta.boostedCharact == "All" || nft.meta.boostedCharact == "Power") &&
              <Tag color="gold" style={{ margin: "5px" }}>{`Power: +${nft.meta.boostedAmount}%`}</Tag>}
            {(nft.meta.boostedCharact == "All" || nft.meta.boostedCharact == "Jumping") &&
              <Tag color="purple" style={{ margin: "5px" }}>{`Jumping: +${nft.meta.boostedAmount}%`}</Tag>}
          </ValueWrapper>
        }
      </NameBlock>
      <ColorBrickContainer>
        <ColorBrick color={nft.brickColor} />
      </ColorBrickContainer>
      <TextBrickContainer>
        <TextBrick>{nft.sport}</TextBrick>
      </TextBrickContainer>
      <ButtonGroup>
        <Button
          onClick={() => onClickBuy()}
          block
          style={{
            width: "97%",
            marginLeft: "auto",
            marginRight: "auto",
            border: "none",
            background: "rgba(57, 133, 245, 0.2)",
            color: "#3985f5",
            borderRadius: "4px",
          }}
        >
          BUY
        </Button>
      </ButtonGroup>
    </GeometricCard>
  )
}

export default NFTGeometricCard
