/* eslint-disable prettier/prettier */
import { useState } from "react"
import { useDropzone } from "react-dropzone"
import { Loading } from "notiflix"
import { useHistory } from "react-router"
import "../styles/formNftCard.css"
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
    DropDownContainer,
    DropFileStyle,
    AttachContainer,
    FormStyling,
    SubmitBtn,
    TitleField,
    SelectBoxDropdown,
    AsideContainer,
    AsideImageContainer,
} from "../styles/CreateNFTStyles"
import attachSvg from "../../../images/attach.svg"
import { sports, gameTypes, gamePeriods } from "utils/dummyData"
import { createTournament } from "utils/helpers/contest"
import { useMoralis } from "react-moralis"
import { Select, Input, DatePicker, Radio, Typography, Row, Col, Card } from "antd"
import SelectBox from "components/SelectBox"
import InputComponent from "screens/AuthFlow/components/InputComponent"

export const FormTournament = ({ propNftType }) => {
    const history = useHistory()
    const { Moralis, user } = useMoralis()
    const [files, setFiles] = useState([])
    const [selectedFields, setSelectedFields] = useState({
        network: "Binance",
        gamePeriod: gamePeriods[0].value,
        gameType: null,
        sport: sports[0].value,
        startDate: 0,
        gameName: null,
        gameDesc: null,
        maxPlayer: 0,
        pricePool: 0,
        gameFee: 0,
    })




    const thumbs = files.map((file) => (
        <AsideImageContainer key={file.name}>
            <img
                src={file.preview}
                onLoad={() => {
                    URL.revokeObjectURL(file.preview)
                }}
            />
        </AsideImageContainer>
    ))

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            )
        },
    })

    const onSubmit = async (e) => {
        e.preventDefault()
        const {
            network,
            gamePeriod,
            gameType,
            sport,
            startDate,
            gameName,
            gameDesc,
            maxPlayer,
            pricePool,
            gameFee
        } = selectedFields

        Loading.standard();
        try {
            const data = files[0]
            const image_ipfs = new Moralis.File(data.name, data)
            const img = await image_ipfs.saveIPFS()
            const image = "https://gateway.moralisipfs.com/ipfs/" + img._hash
            const options = JSON.stringify({
                name: gameName,
                desc: gameDesc,
                sport,
                startDate,
                image,
                gamePeriod,
                gameType,
                network,
                maxPlayer,
                pricePool,
                gameFee,
                date: Date.now(),
            })
            const file = new Moralis.File("file.json", {
                base64: btoa(options),
            })
            const response = await file.saveIPFS()
            const metadata_hash = "https://gateway.moralisipfs.com/ipfs/" + response._hash
            const price = Moralis.Units.Token(gameFee, "18")
            const transaction = await Moralis.executeFunction(
                createTournament(sport, maxPlayer, price, metadata_hash),
            )
            await transaction.wait()

        } catch (error) {
            Modal.error({
                icon: <ExclamationCircleOutlined />,
                content: error.data ? error.data.message : error.message,
            })
        }
        Loading.remove()
    }

    return (
        <FormStyling id="createNFTForm" onSubmit={onSubmit}>

            {/* Game Type */}
            <TitleField>{"Type of Game"}</TitleField>
            <SelectBoxDropdown>
                <SelectBox
                    options={gameTypes.map(item => item.value)}
                    selectedOption={selectedFields.gameType}
                    onChange={(value) => setSelectedFields({ ...selectedFields, gameType: value })}
                    placeholder="Select the game type"
                />
            </SelectBoxDropdown>

            {/* Game Type */}
            <TitleField>{"Select period of tournament"}</TitleField>
            <SelectBoxDropdown>
                <SelectBox
                    options={gamePeriods.map(item => item.value)}
                    selectedOption={selectedFields.gamePeriod}
                    onChange={(value) => setSelectedFields({ ...selectedFields, gamePeriod: value })}
                    placeholder="Select period of tournament"
                />
            </SelectBoxDropdown>

            <InputComponent
                title="Number of players (multiple of 2)" placeholder="Number of players (multiple of 2)" name="maxPlayer" type="number"
                onChange={(e) => setSelectedFields({ ...selectedFields, maxPlayer: e.target.value })}
            />

            <InputComponent
                title="gamers deposit (in YLT)" placeholder="gamers deposit (in YLT)" name="gameFee" type="number"
                onChange={(e) => setSelectedFields({ ...selectedFields, gameFee: e.target.value })}
            />

            <TitleField>{"Select kind of sport"}</TitleField>
            <SelectBoxDropdown>
                <SelectBox
                    options={sports.map(item => item.value)}
                    selectedOption={selectedFields.sport}
                    onChange={(value) => setSelectedFields({ ...selectedFields, sport: value })}
                    placeholder="Select kind of sport"
                />
            </SelectBoxDropdown>

            <TitleField>{"Enter start date"}</TitleField>
            <DatePicker
                placeholder="__.__.____"
                dateFormat="dd/MM/yyyy"
                onChange={(value) => {setSelectedFields({ ...selectedFields, startDate: value?._d.getTime() })}}
                style={{ borderRadius: "8px", padding: "16px", marginTop: "16px" }}
            />

            <InputComponent
                title="name of tournament" placeholder="name of tournament" name="gameName" type="text"
                onChange={(e) => setSelectedFields({ ...selectedFields, gameName: e.target.value })}
            />

            <InputComponent
                title="Tournament description" placeholder="Tournament description" name="gameDesc" type="textArea"
                onChange={(e) => setSelectedFields({ ...selectedFields, gameDesc: e.target.value })}
            />

            <InputComponent
                title="Total prize pool (in YLT)" placeholder="Total prize pool (in YLT)" name="pricePool" type="number"
                onChange={(e) => setSelectedFields({ ...selectedFields, pricePool: e.target.value })}
            />

            <>
                <TitleField>
                    Upload an image for a banner <br />
                    <span>The file must be PNG, and no more than 3 MB</span>
                </TitleField>
                <DropDownContainer>
                    <DropFileStyle {...getRootProps()}>
                        <input
                            type="url"
                            placeholder="Upload an image for a banner"
                            {...getInputProps()}
                        />
                        <p>Drag file here</p>
                        <AttachContainer>
                            <img src={attachSvg} alt="attach" />
                            <span>Attach file</span>
                        </AttachContainer>
                        <AsideContainer>
                            {thumbs}
                        </AsideContainer>
                    </DropFileStyle>
                </DropDownContainer>
            </>


            <SubmitBtn type="submit" placeholder="MINT" value="Create" />
        </FormStyling>
    )
}
