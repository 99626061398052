import { DappContext } from "context"
import { useCallback, useContext, useEffect, useState } from "react"
import { useMoralis } from "react-moralis"
import { Link, useLocation } from "react-router-dom"
import { Modal } from 'antd';
import { FormNFTCard } from "./components/FormNFTCard"

import {
  MainContainer,
  MainHeading,
  CenterSide,
  TabContainer,
  TabButton,
  FormContainer,
} from "./styles/CreateNFTStyles"
import { useHistory } from "react-router"
import { creatNFTTabs } from "utils/dummyData"

const CreateNFT = ({ moralis }) => {
  const { user } = useMoralis()
  const history = useHistory()
  const location = useLocation()
  const passData = location.state

  useEffect(() => {
    if (user && (!user.attributes.isSuperAdmin && !user.attributes.isAdmin)) {
      Modal.error({
        title: 'Error message',
        content: "Only administrators can access this page.",
        onOk() {
          return window.location.replace("/nftMarket")
        },
      });
    }
  }, [user])

  const { flagHeightAuto, setFlagHeightAuto } = useContext(DappContext)

  const [nftType, setNftType] = useState("Player")

  useEffect(() => {
    setFlagHeightAuto(true)
  }, [setFlagHeightAuto])

  useEffect(() => {
    if (passData) {
      setNftType(passData.type)
    }
  }, [passData])

  const selectNftType = useCallback((e) => {
    setNftType(e)
  }, [])

  return (
    <MainContainer>
      <CenterSide>
        <div onClick={() => history.goBack()} style={{ color: "#6A6A69", fontSize: "16px" }} >&#8592; Back</div>
        <MainHeading>Create NFT</MainHeading>
        <TabContainer>
          {
            creatNFTTabs.map((item, key) => (
              item.roles.includes(user?.attributes.role) &&
              <TabButton onClick={() => selectNftType(item.value)} isFocused={nftType === item.value} key={key} >
                {(item.value == "Sponsorship" ? "NFT for " : "") + item.value}
              </TabButton>
            ))
          }
        </TabContainer>
        <FormContainer>
          <FormNFTCard propNftType={nftType} moralis={moralis} />
        </FormContainer>
      </CenterSide>
    </MainContainer>
  )
}

export default CreateNFT
