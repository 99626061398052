import React, { useEffect, useRef, useState, useContext } from "react"
import { useHistory } from "react-router"
import Chat from "screens/Chat"
import { Button, Input as Input_ANT, Space, Modal } from "antd"
import {
  CodeOutlined,
  LineChartOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons"
import { DappContext } from "context"
import { useMoralis } from "react-moralis"
import toast from "react-hot-toast"
import { BalanceInfo } from "screens/Superadmin/components/BalanceInfo"
import { FunctionBtn } from "screens/Superadmin/components/FunctionBtn"
import { FunctionsList } from "screens/Superadmin/components/FunctionsList"
import {
  MainContainer,
  MainContainerMobile,
} from "screens/Superadmin/styles/SuperAdminStyling"
import { Statistics } from "components/Sidebar/Statistics"
import {
  AdminInfo,
  AdminInfoContainer,
  AdminInfoInnerContainer,
  AdminUsername,
  CameraBar,
  CameraIcon,
  ProfileImg,
  Role,
  ComissionTxt,
  AdminInfoButtonIconsContainer,
} from "screens/Superadmin/styles/AdminInfoStyling"
import {
  Input,
  InputBtn,
  InputContainer,
  MainInnerContainer,
  MainInnerContainerMobile,
} from "./styles/AdminScreenStyles"
import whiteLabel from "images/yourlife_back_white.png"
import cameraImg from "images/account/camera.svg"
import { dummyData, userRoles } from "utils/dummyData"
import {
  isMintableAccount,
  mintableAccounts,
  depositYLT,
  getSufficientAmount,
} from "utils/helpers/proxy"
import { increaseAllowance } from "utils/helpers/ylt"
import { useMedia } from "hooks/useMedia"
import { FunctionsListMobile } from "screens/Superadmin/MobileScreens/FunctionsListMobile"
import { FunctionBtnMobile } from "screens/Superadmin/components/FunctionBtnMobile"
import { Loading } from "notiflix"
import { getGroupAssign, getComissionByAdmin, setGroupAssign } from "utils/helpers/proxy"

const AdminScreen = ({ moralis }) => {
  const [yltDeposit, setYltDeposit] = useState(0)
  const { Moralis, user, isAuthenticated, isWeb3Enabled } = useMoralis()
  const [onceLoad, setOnceLoad] = useState(true)
  const [deposit, setDeposit] = useState(null)
  const [clubData, setClubData] = useState(null)
  const cameraInpRef = useRef(null)
  const cameraInpRef1 = useRef(null)
  const [profilePicture, setProfilePicture] = useState(null)
  const [tempProfileName, setTempProfileName] = useState(null)
  const [depositValue, setDepositValue] = useState("")
  const [suffAmount, setSuffAmount] = useState(null)
  const { setOpenSidebar, setSidebarContent, onCloseSidebar } = useContext(DappContext)
  const [assigned, setAssigned] = useState(false)
  const [superComission, setSuperComission] = useState(0)
  const [adminComission, setAdminComission] = useState(0)
  const [athleteComission, setAthleteComission] = useState(0)
  const history = useHistory()
  const profileName = "Add a nickname"
  const profileImage =
    profilePicture ||
    user?.attributes.profile_picture ||
    require("../../images/account/Ellipse 58.png").default

  const isDesktop = useMedia("(min-width: 1024px)")
  const isMobile = useMedia("(max-width: 1024px)")

  useEffect(() => {
    if (user) {
      if (user?.attributes.isSuperAdmin == true) {
        return window.location.replace("/admin/super")
      }
      if (!user?.attributes.isAdmin) {
        if (user?.attributes.authStep == 100) {
          return window.location.replace("/myaccount")
        } else {
          alert("Please complete your authentication flow.")
          return window.location.replace("/authflow")
        }
      }

    }
  }, [user])

  useEffect(() => {
    const fetch = async () => {
      Loading.standard()
      let value
      try {
        const comission = await Moralis.executeFunction(getComissionByAdmin(user?.attributes.ethAddress))
        if (comission.length > 0) {
          setSuperComission(Moralis.Units.Token(comission[0], "0") / 100)
          setAdminComission(Moralis.Units.Token(comission[1], "0") / 100)
          setAthleteComission(Moralis.Units.Token(comission[2], "0") / 100)
        }
        const roleIndex = userRoles.map(item => item.value).indexOf(user.attributes.role);
        value = await moralis?.fn.executeFunction(getSufficientAmount(roleIndex))
        if (user?.attributes.club) {
          let res = await moralis?.fn.Cloud.run("getClub", { id: user?.attributes.club, })
          setClubData(res)
        }
        const groupAdmin = await Moralis.executeFunction(getGroupAssign(user?.attributes.ethAddress))
        if (groupAdmin != "0x0000000000000000000000000000000000000000") {
          setAssigned(true)
        } else {
          if (user?.attributes.role == "in_ath") {
            Modal.error({
              icon: <ExclamationCircleOutlined />,
              content: "You must assign yourself first.",
            })
          }
        }
        Loading.remove()
      } catch (error) {
        Loading.remove()
        Modal.error({
          icon: <ExclamationCircleOutlined />,
          content: error.data ? error.data.message : error.message,
        })
      }
      if (value) setSuffAmount(Moralis.Units.FromWei(value))
    }
    if (isAuthenticated && isWeb3Enabled && onceLoad && moralis && user) {
      fetch()
      setOnceLoad(false)
    }
  }, [user, moralis, isAuthenticated, isWeb3Enabled, onceLoad, Moralis.Units])

  const handleClick = () => {
    // eslint-disable-next-line no-debugger
    // debugger
    if (isDesktop && cameraInpRef1.current) cameraInpRef1.current.click()
    else if (!isDesktop && cameraInpRef.current) cameraInpRef.current.click()
  }

  const handleFileInput = async () => {
    if (cameraInpRef.current.files.length > 0) {
      const img = cameraInpRef.current.files[0]
      const file = new Moralis.File(img.name, img)
      await file.saveIPFS()
      user.set("profile_picture", file._ipfs)
      await user.save()
      setProfilePicture(file._ipfs)
      toast.success("Your profile picture has been updated successfully!", { autoClose: 2000 })
    }
  }

  const editProfile = async () => {
    const name = prompt("Please enter a new nickname")
    if (name) {
      user.set("nickname", name)
      await user.save()
      setTempProfileName(name)
      toast.success("Your nickname has been updated successfully!")
    }
  }

  const onChange = (e) => {
    e.preventDefault()
    setYltDeposit(e.target.value)
  }

  const increaseAllowanceAmount = async (amount) => {
    const transaction = await moralis?.fn.executeFunction(
      increaseAllowance(
        process.env.REACT_APP_YLPROXY_CONTRACT_ADDRESS,
        Moralis.Units.Token(amount, "18"),
      ),
    )
    await transaction.wait()
  }

  const depositYlt = async () => {
    Loading.standard()
    const depositAmount = Number(depositValue)
    if (depositAmount >= suffAmount) {
      let transaction
      try {
        await increaseAllowanceAmount(depositAmount)
        transaction = await moralis?.fn.executeFunction(
          depositYLT(Moralis.Units.Token(depositAmount, "18")),
        )
      } catch (error) {
        Loading.remove()
        Modal.error({
          icon: <ExclamationCircleOutlined />,
          content: error.data ? error.data.message : error.message,
        })
      }
      await transaction?.wait()
      setDeposit(!deposit)
      setDepositValue("")
      Loading.remove()
    } else {
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: `Deposit amount should be more than ${suffAmount}!`,
      })
    }
  }

  const handleInput = (e) => {
    setDepositValue(e.target.value)
  }

  const toCreateNFTFunc = async () => {
    Loading.standard()
    try {
      const isMintable = await moralis?.fn.executeFunction(isMintableAccount(user?.attributes.ethAddress))
      const mintable = await moralis?.fn.executeFunction(mintableAccounts(user?.attributes.ethAddress))
      if (isMintable || user?.attributes.isSuperAdmin) {
        history.push("/admin/createNFT")
        Loading.remove()
      } else {
        Loading.remove()
        Modal.error({
          icon: <ExclamationCircleOutlined />,
          content: mintable
            ? `You should deposit at least ${suffAmount} YLT!`
            : "Super Admin should give you mint permission!",
        })
      }
    } catch (error) {
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }

  const openStatistic = () => {
    setSidebarContent(<Statistics closeSidebar={onCloseSidebar} />)
    setOpenSidebar(true)
  }

  const assignHandler = async () => {
    Loading.standard()
    try {
      let transaction = await Moralis.executeFunction(setGroupAssign(user?.attributes.ethAddress))
      await transaction.wait()
      Loading.remove()
      setAssigned(true)
    } catch (error) {
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }
  return (
    <React.Fragment>

      {isDesktop && (
        <MainContainer>
          <MainInnerContainer>
            <AdminInfoContainer>
              <AdminInfo>
                <ProfileImg src={profileImage} alt="profile image" />
                <CameraBar>
                  <CameraIcon src={cameraImg} onClick={handleClick} />
                </CameraBar>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={cameraInpRef1}
                  accept="image/*"
                  onChange={() => handleFileInput()}
                />
                <AdminInfoInnerContainer>
                  <AdminUsername>
                    {user?.attributes?.nickname ||
                      tempProfileName ||
                      profileName}
                  </AdminUsername>
                  {clubData && <Role backgroundImage={clubData?.attributes.icon}>({clubData?.attributes.name})</Role>}
                  <ComissionTxt>Commission Rate<br />{superComission}%(YLG):{user?.attributes.role != "in_ath" && (adminComission + "%(Admin):")}{athleteComission}%(Athlette)</ComissionTxt>
                  {
                    user?.attributes.role == "in_ath" && !assigned &&
                    <Button size="large" type="primary" onClick={assignHandler}>
                      Assign
                    </Button>
                  }
                </AdminInfoInnerContainer>
                <AdminInfoButtonIconsContainer>
                  <Space size={8}>
                    <Button
                      type="link"
                      shape="circle"
                      icon={<LineChartOutlined style={{ color: "white" }} />}
                      size={"medium"}
                      onClick={openStatistic}
                    />
                    <Button
                      type="link"
                      shape="circle"
                      icon={
                        <EditOutlined
                          style={{ color: "white" }}
                          onClick={editProfile}
                        />
                      }
                      size={"medium"}
                    />
                  </Space>
                </AdminInfoButtonIconsContainer>
              </AdminInfo>

              {/* balance */}
              <BalanceInfo
                isdeposit={deposit}
                address={user?.attributes.ethAddress}
                data={dummyData}
                whiteLabel={whiteLabel}
                value={yltDeposit}
                suffAmount={suffAmount}
                isSuperPage={false}
                onChange={onChange}
              />
            </AdminInfoContainer>
            <InputContainer>
              <Space.Compact block size="large">
                <Input_ANT
                  placeholder="ENTER AMOUNT"
                  onChange={handleInput}
                  value={depositValue}
                  size="large"
                />
                <Button size="large" type="primary" onClick={depositYlt}>
                  Stake YLT
                </Button>
              </Space.Compact>
            </InputContainer>

            {/* create NFT Button */}
            <FunctionBtn
              icon={<CodeOutlined />}
              onClick={toCreateNFTFunc}
              text="create nft"
            />

            {user?.attributes.role == "ylg_adm" &&
              <FunctionBtn
                text="create tournament"
                icon={<ReconciliationOutlined />}
                link="/admin/createTournament"
              />
            }

            {/* {user?.attributes.role == "ylg_adm" &&
              <FunctionBtn
                text="manage tournament"
                icon={<ReconciliationOutlined />}
                link="/admin/manageTournament"
              />
            } */}

            {/* functions */}
            <FunctionsList
              address={user?.attributes.ethAddress}
              data={dummyData}
              role="admin"
            />
          </MainInnerContainer>
          <Chat moralis={moralis} />
        </MainContainer>
      )}
      {isMobile && (
        <MainContainerMobile>
          <MainInnerContainerMobile>
            <AdminInfoContainer>
              <AdminInfo>
                <ProfileImg src={profileImage} alt="profile image" />
                <CameraIcon
                  src={cameraImg}
                  onClick={() => cameraInpRef.current.click()}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={cameraInpRef}
                  accept="image/*"
                  onChange={handleFileInput}
                />
                <AdminInfoInnerContainer>
                  <Role>Admin</Role>
                  <AdminUsername>
                    {user?.attributes?.nickname ||
                      tempProfileName ||
                      profileName}
                  </AdminUsername>
                </AdminInfoInnerContainer>
                <AdminInfoButtonIconsContainer>
                  <Space size={8}>
                    <Button
                      type="link"
                      shape="circle"
                      icon={<LineChartOutlined style={{ color: "white" }} />}
                      size={"medium"}
                      onClick={openStatistic}
                    />
                    <Button
                      type="link"
                      shape="circle"
                      icon={
                        <EditOutlined
                          style={{ color: "white" }}
                          onClick={editProfile}
                        />
                      }
                      size={"medium"}
                    />
                  </Space>
                </AdminInfoButtonIconsContainer>
              </AdminInfo>

              {/* balance */}
              <BalanceInfo
                isdeposit={deposit}
                address={user?.attributes.ethAddress}
                data={dummyData}
                whiteLabel={whiteLabel}
                value={yltDeposit}
                suffAmount={suffAmount}
                onChange={onChange}
              />
            </AdminInfoContainer>
            <InputContainer>
              <Space.Compact block size="large">
                <Input_ANT
                  placeholder="ENTER AMOUNT"
                  onChange={handleInput}
                  value={depositValue}
                  size="large"
                />
                <Button size="large" type="primary" onClick={depositYlt}>
                  Deposit
                </Button>
              </Space.Compact>
            </InputContainer>

            {/* create NFT Button */}
            <FunctionBtnMobile
              icon={<CodeOutlined />}
              onClick={toCreateNFTFunc}
              text="create nft"
            />

            {/* functions */}
            <FunctionsListMobile
              address={user?.attributes.ethAddress}
              data={dummyData}
              role="admin"
            />
          </MainInnerContainerMobile>
        </MainContainerMobile>
      )}
    </React.Fragment>
  )
}

export default AdminScreen
