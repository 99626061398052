import React from "react"
import {
  AdminAddress,
  AdminImage,
  AdminName,
  AthleteContainer,
  CardInnerContainer,
  InnerContainer,
  LeftBtn,
  RightBtn,
} from "screens/Superadmin/styles/ManageAdminsStyling"
import profileImage from "images/avatar.png"
import { useMoralis } from "react-moralis"
import { Modal, Tag } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Loading } from "notiflix"
import { userRoles } from "utils/dummyData"
import { setRoleByUser, setGroupAssign, removeGroupAssign } from "utils/helpers/proxy"

export const AthleteCard = ({ user, mobile }) => {
  const { Moralis } = useMoralis()

  const handleClick = async () => {
    const address = user.ethAddress
    if (address === null) {
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: "Please type wallet address of user!",
      })
    } else {
      Loading.standard()
      try {
        const params = { ethAddress: address }
        const roleIndex = userRoles.map(item => item.value).indexOf(user.role) + 1;
        let transaction = await Moralis.executeFunction(setRoleByUser(address, roleIndex))
        await transaction.wait()
        transaction = await Moralis.executeFunction(setGroupAssign(address))
        await transaction.wait()
        const isSuccess = await Moralis.Cloud.run("setAthlete", params)
        Loading.remove()
        Modal.success({
          icon: <ExclamationCircleOutlined />,
          content: "Set athlete successfully",
        })
        location.reload()
      } catch (error) {
        Loading.remove()
        Modal.error({
          icon: <ExclamationCircleOutlined />,
          content: error.data ? error.data.message : error.message,
        })
      }
    }
  }

  const handleDemoteClick = async () => {
    const params = { ethAddress: user.ethAddress }
    Loading.standard()
    try {
      const transaction = await Moralis.executeFunction(removeGroupAssign(user.ethAddress))
      await transaction.wait()
      const isSuccess = await Moralis.Cloud.run("unsetAthlete", params)
      Loading.remove()
      Modal.success({
        icon: <ExclamationCircleOutlined />,
        content: "Reject athlete successfully",
      })
      location.reload()
    } catch (error) {
      Loading.remove()
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }

  return (
    <AthleteContainer>
      <CardInnerContainer>
        <LeftBtn onClick={handleDemoteClick}>{user?.authStep == 50 ? "REJECT" : "DELETE"}</LeftBtn>
        <AdminImage src={user?.profile_picture || profileImage} />
        {user?.authStep == 50 ?
          <RightBtn onClick={handleClick}>APPROVE</RightBtn>
          :
          <RightBtn></RightBtn>
        }
      </CardInnerContainer>
      <InnerContainer>
        <AdminName>{user.nickname}</AdminName>
        <div style={{ display: "flex", flexWrap: "wrap", margin:"10px 0" }}>
          {user.sports && user.sports.map(item => {
            return <Tag color={"processing"} key={item}> {item}</Tag>
          })}
        </div>
        <AdminAddress>{user.ethAddress}</AdminAddress>
      </InnerContainer>
    </AthleteContainer>
  )
}
