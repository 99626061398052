import { ABI } from "../abi/proxy.js"
const ENV_CONTRACT_ADDRESS = process.env.REACT_APP_YLPROXY_CONTRACT_ADDRESS

export const getOwner = () => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "owner",
    abi: ABI,
    params: {},
  }
}

export const transferOwnerShip = (newAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "transferOwnership",
    abi: ABI,
    params: {
      newOwner: newAddress,
    },
  }
}

export const depositYLT = (amount) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "depositYLT",
    abi: ABI,
    params: {
      _amount: amount,
    },
  }
}

export const changePermissionsFunc = (walletAddress, funcName, checked) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: funcName,
    abi: ABI,
    params: {
      _address: walletAddress,
      _value: checked,
    },
  }
}

export const isMintableAccount = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "isMintableAccount",
    abi: ABI,
    params: {
      _address: walletAddress,
    },
  }
}

export const mintableAccounts = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "mintableAccounts",
    abi: ABI,
    params: {
      _address: walletAddress,
    },
  }
}

export const pausableAccounts = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "pausableAccounts",
    abi: ABI,
    params: {
      _address: walletAddress,
    },
  }
}

export const burnableAccounts = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "burnableAccounts",
    abi: ABI,
    params: {
      _address: walletAddress,
    },
  }
}

export const transferableAccounts = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "transferableAccounts",
    abi: ABI,
    params: {
      _address: walletAddress,
    },
  }
}

export const isBurnAccount = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "isBurnAccount",
    abi: ABI,
    params: {
      _address: walletAddress,
    },
  }
}

export const isTransferAccount = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "isTransferAccount",
    abi: ABI,
    params: {
      _address: walletAddress,
    },
  }
}

export const isPauseAccount = (walletAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "isPauseAccount",
    abi: ABI,
    params: {
      _address: walletAddress,
    },
  }
}

export const getStakedAmount = (walletAddress, yltAddress) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "stakedAmount",
    abi: ABI,
    params: {
      walletAddress: walletAddress,
      yltAddress: yltAddress,
    },
  }
}

export const getSufficientAmounts = () => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getSufficientAmounts",
    abi: ABI,
    params: {},
  }
}

export const getSufficientAmount = (index) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getSufficientAmount",
    abi: ABI,
    params: {
      index
    },
  }
}

export const withdrawYLT = (to, amount) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "withdrawYLT",
    abi: ABI,
    params: {
      _to: to,
      _amount: amount,
    },
  }
}

export const setGroupAssign = (athlete) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "setGroupAssign",
    abi: ABI,
    params: {
      athlete,
    },
  }
}

export const removeGroupAssign = (athlete) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "removeGroupAssign",
    abi: ABI,
    params: {
      athlete,
    },
  }
}

export const getGroupAssign = (athlete) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getGroupAssign",
    abi: ABI,
    params: {
      athlete,
    },
  }
}


export const setRoleByAdmin = (user, role) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "setRoleByAdmin",
    abi: ABI,
    params: {
      user,
      role,
    },
  }
}


export const setRoleByUser = (user, role) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "setRoleByUser",
    abi: ABI,
    params: {
      user,
      role,
    },
  }
}



export const getRole = (user) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getRole",
    abi: ABI,
    params: {
      user,
    },
  }
}



export const setComission = (_address, _super, _admin, _athlete) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "setComission",
    abi: ABI,
    params: {
      _address,
      _super,
      _admin,
      _athlete,
    },
  }
}


export const getComissionByAdmin = (admin) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getComissionByAdmin",
    abi: ABI,
    params: {
      admin,
    },
  }
}


export const getComissionByUser = (user) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "getComissionByUser",
    abi: ABI,
    params: {
      user,
    },
  }
}

export const setSufficientAmount = (amounts) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "setSufficientAmount",
    abi: ABI,
    params: {
      amounts,
    },
  }
}

export const getEscrowAmount = (address) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "escrowAmount",
    abi: ABI,
    params: {
      _address: address
    },
  }
}

export const withdrawEscrow = (amount) => {
  return {
    contractAddress: ENV_CONTRACT_ADDRESS,
    functionName: "escrow",
    abi: ABI,
    params: {
      _amount: amount
    },
  }
}