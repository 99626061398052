import React, { useContext } from "react"
import { EditSchoolClub } from "screens/SchoolClubs/components/EditSchoolClub"
import { Space, Table, Tag, Button, Modal } from 'antd';
import { DappContext } from "context"
import { ClubTypes } from "utils/dummyData"
import { ClubImage, AdminAvatarImage, ClubContainer, } from "screens/Superadmin/styles/ManageAdminsStyling"
import profileImage from "images/avatar.png"
import { Loading } from "notiflix"
import { useMoralis } from "react-moralis"
import { DescContainer } from "./CreateSchoolClubStyling"
import { ExclamationCircleOutlined } from "@ant-design/icons"
const { Column, ColumnGroup } = Table;

export const SchoolClubTable = ({ clubs }) => {
  const { Moralis } = useMoralis()
  const { onCloseSidebar, setSidebarContent, setOpenSidebar } = useContext(DappContext)

  const handleEditClick = async (club) => {
    setSidebarContent(
      <EditSchoolClub club={club} closeSidebar={onCloseSidebar} />,
    )
    setOpenSidebar(true)
  }

  const handleDemoteClick = async (club) => {
    Loading.standard();
    try {
      const res = await Moralis.Cloud.run("inActiveClub", club)
      if (res) {
        location.reload()
      } else {
        Loading.remove();
        Modal.error({
          icon: <ExclamationCircleOutlined />,
          content: "Failed removing school & sport club",
        })
      }
    } catch (error) {
      Loading.remove();
      Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: error.data ? error.data.message : error.message,
      })
    }
  }

  return (
    <Table dataSource={clubs} scroll={{ x: 1300, }} >
      <Column title="ID" key="id" render={(_, record, index) => { ++index; return index; }} />
      <Column title="Logo" key="icon" render={(_, record) => (<ClubImage src={record.attributes.icon || profileImage} />)} />
      <Column title="Name" key="name" render={(_, record) => (<a href={record.attributes.link ?? "#"} target="_blank" rel="noreferrer">{record.attributes.name}</a>)}
        sorter={(a, b) => a.attributes.name.localeCompare(b.attributes.name)} />
      <Column title="Type" key="type" render={(_, record) => {
        let role = record.attributes.type
        let color = role === 'school' ? 'volcano' : 'green';
        return <Tag color={color}>{ClubTypes.filter(item => item.value == record.attributes.type)[0]?.label}</Tag>
      }}
        sorter={(a, b) => a.attributes.type.localeCompare(b.attributes.type)} />
      <Column title="Description" key="description" render={(_, record) => (
        <DescContainer>{record.attributes.description}</DescContainer>
      )} />
      <Column title="Sport type" key="sport" render={(_, record) => {
        return record.attributes.sport?.map(item => {
          return <Tag color={"processing"}> {item}</Tag>
        })
      }} />
      <Column title="CreateAt" key="createAt" sorter={(a, b) => new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt)}
        render={(_, record) => {
          const startDate = new Date(record.attributes.createdAt)
          var res = startDate.toISOString().slice(0, 16).replace(/-/g, "-");
          return <p>{res}</p>
        }} />
      <Column title="UpdateAt" key="updateAt" sorter={(a, b) => new Date(a.attributes.updatedAt) - new Date(b.attributes.updatedAt)}
        render={(_, record) => {
          const startDate = new Date(record.attributes.updatedAt)
          var res = startDate.toISOString().slice(0, 16).replace(/-/g, "-");
          return <p>{res}</p>
        }} />
      <Column
        title="Action"
        key="action"
        render={(_, record) => (
          <Space size="middle">
            <Button type="primary" danger onClick={() => handleDemoteClick(record)}>DEMOTE</Button>
            <Button type="primary" onClick={() => handleEditClick(record)}>EDIT</Button>
          </Space>
        )}
      />
    </Table>
  )
};